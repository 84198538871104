@include exports( "checkbox/layout" ) {

    // Container box
    .k-checkbox {
        @include border-radius( $checkbox-radius );
        margin: 0;
        padding: 0;
        width: $checkbox-size;
        height: $checkbox-size;
        line-height: initial;
        border-width: $checkbox-border-width;
        border-style: solid;
        outline: 0;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        -webkit-appearance: none;
    }

    // Checkmark
    .k-checkbox::before {
        width: $checkbox-icon-size;
        height: $checkbox-icon-size;
        font-size: $checkbox-icon-size;
        line-height: 1;
        transform: scale(0) translate(-50%, -50%);
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;

        @if $checkbox-icon-type == "svg" {
            content: "";
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: 50%;
            background-image: $checkbox-icon-svg;
        } @else {
            content: $checkbox-icon-glyph;
            font-family: "WebComponentsIcons", monospace;
        }
    }

    // Hover state
    .k-checkbox:hover {
        cursor: pointer;
    }


    // Checked state
    .k-checkbox:checked::before {
        @include border-radius( $checkbox-radius );
        transform: scale(1) translate(-50%, -50%);
    }


    // Indeterminate state
    .k-checkbox:indeterminate::before,
    .k-checkbox.k-state-indeterminate::before {
        content: "";
        background-color: currentColor;
        transform: scale(1) translate(-50%, -50%);

        @if $checkbox-indeterminate-style == "dash" {
            width: $checkbox-dash-width;
            height: $checkbox-dash-height;
        } @else {
            width: $checkbox-square-width;
            height: $checkbox-square-height;
        }
    }


    // Disabled state
    .k-checkbox:disabled,
    .k-checkbox:disabled + .k-checkbox-label {
        @include disabled( $disabled-styling );
    }

    .k-checkbox + .k-checkbox-label {
        display: inline;
    }

    // Checkbox label
    .k-checkbox-label {
        margin: 0;
        padding: 0;
        line-height: $checkbox-line-height;
        display: inline-flex;
        align-items: flex-start;
        vertical-align: middle;
        position: relative;
        cursor: pointer;

        .k-label {
            cursor: pointer;
        }

        // Hide empty label
        &:empty {
            display: none;
        }

        // Keep it "visible" in jquery grid
        &.k-no-text {
            min-width: 1px;
        }

        .k-ripple {
            top: $checkbox-size / 2;
            left: $checkbox-size / 2;
            right: auto;
            bottom: auto;
            width: $checkbox-size * 5 / 2;
            height: $checkbox-size * 5 / 2;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            // Hide ripple temporarily
            visibility: hidden !important; // sass-lint:disable-line no-important
        }

        .k-ripple-blob {
            // sass-lint:disable-block no-important
            // use !important until ripple can apply these styles from the script
            top: 50% !important;
            left: 50% !important;
            width: 200% !important;
            height: 200% !important;
        }
    }
    .k-checkbox + .k-checkbox-label,
    .k-checkbox-label + .k-checkbox {
        margin-left: $checkbox-label-margin-x;
    }
    .k-checkbox-label > .k-checkbox {
        margin-right: $checkbox-label-margin-x;
        flex-shrink: 0;
    }
    kendo-label.k-checkbox-label > .k-checkbox:last-child {
        margin-right: 0;
    }
    kendo-label.k-checkbox-label > .k-label:first-child {
        margin-right: $checkbox-label-margin-x;
    }
    kendo-label.k-checkbox-label > .k-label {
        display: inline;
    }


    // Checkbox list
    .k-checkbox-list {
        margin: $checkbox-list-margin;
        padding: $checkbox-list-padding;
        list-style: none;

        .k-checkbox-item {
            padding: $checkbox-list-item-padding-y $checkbox-list-item-padding-x;
        }
    }

    .k-list-horizontal {
        .k-checkbox-item {
            display: inline-block;
            margin: 0 $checkbox-list-horizontal-item-margin-x 0 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }


    // RTL
    .k-rtl,
    [dir="rtl"] {
        .k-checkbox + .k-checkbox-label,
        .k-checkbox-label + .k-checkbox {
            margin-left: 0;
            margin-right: $checkbox-label-margin-x;
        }
        .k-checkbox-label > .k-checkbox {
            margin-right: 0;
            margin-left: $checkbox-label-margin-x;
        }
        kendo-label.k-checkbox-label > .k-checkbox:last-child {
            margin-left: 0;
        }
        kendo-label.k-checkbox-label > .k-label:first-child {
            margin-right: 0;
            margin-left: $checkbox-label-margin-x;
        }

        .k-list-horizontal {
            .k-checkbox-item {
                margin-right: 0;
                margin-left: $checkbox-list-horizontal-item-margin-x;

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    .k-ripple-container {
        .k-checkbox::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: $checkbox-ripple-size;
            height: $checkbox-ripple-size;
            margin-left: $checkbox-ripple-margin;
            margin-top: $checkbox-ripple-margin;
            border-radius: 100%;
            z-index: 1;
            transform: scale(0);
        }

        .k-checkbox:disabled::after {
            display: none;
        }
    }

}




@include exports( "checkbox/layout/edge" ) {

    .k-checkbox::-ms-check {
        border-width: 0;
        border-color: inherit;
        color: inherit;
        background-color: inherit;
    }

    // IE 11 hack
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .k-checkbox::-ms-check {
            border-width: 1px;
        }
    }

}
