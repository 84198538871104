// Input

$input-default-width: 12.4em !default;

$input-border-width: 1px !default;
$input-border-height: ( $input-border-width * 2 ) !default;
$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius !default;
$input-border-radius-lg: $border-radius !default;

$input-padding-x: $padding-x !default;
$input-padding-y: $padding-y !default;
$input-font-family: $font-family !default;
$input-font-size: $font-size !default;
$input-line-height: $line-height !default;
$input-line-height-em: $input-line-height * 1em;

$input-padding-x-sm: ($input-padding-x / 2) !default;
$input-padding-y-sm: ($input-padding-y / 2) !default;
$input-font-size-sm: $input-font-size !default;
$input-line-height-sm: $line-height-sm !default;

$input-padding-x-lg: ($input-padding-x * 1.5) !default;
$input-padding-y-lg: ($input-padding-y * 1.5) !default;
$input-font-size-lg: $input-font-size !default;
$input-line-height-lg: $line-height-lg !default;

$input-calc-height: calc( #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-border-height} ) !default;
$input-calc-height-sm: calc( #{$input-line-height-sm * 1em} + #{$input-padding-y-sm * 2} + #{$input-border-height} ) !default;
$input-calc-height-lg: calc( #{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2} + #{$input-border-height} ) !default;

$input-inner-calc-height: calc( #{$input-line-height * 1em} + #{$input-padding-y * 2} ) !default;
$input-inner-calc-height-sm: calc( #{$input-line-height-sm * 1em} + #{$input-padding-y-sm * 2} ) !default;
$input-inner-calc-height-lg: calc( #{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2} ) !default;

$input-icon-width: calc( #{$icon-size} + #{$input-padding-y * 2} ) !default;
$input-icon-height: calc( #{$input-line-height * $input-font-size} + #{$input-padding-y * 2} ) !default;

$input-adornment-height: calc( #{$input-line-height * $input-font-size} + #{$input-padding-y * 2} ) !default;
$input-adornment-width: $input-adornment-height !default;

$input-bg: $component-bg !default;
$input-text: $component-text !default;
$input-border: $button-border !default;
$input-shadow: null !default;

$input-hovered-text: $input-text !default;
$input-hovered-bg: $input-bg !default;
$input-hovered-border: $button-hovered-border !default;
$input-hovered-shadow: null !default;

$input-focused-text: $input-text !default;
$input-focused-bg: $input-bg !default;
$input-focused-border: rgba(0, 0, 0, .1) !default;
$input-focused-shadow: 0 2px 4px 0 rgba(0, 0, 0, .03), 0 4px 5px 0 rgba(0, 0, 0, .04) !default;

$input-placeholder-text: rgba( $input-text, .5) !default;
$input-placeholder-opacity: 1 !default;

$input-selected-text: $primary-contrast !default;
$input-selected-bg: $primary !default;

$input-separator-color: $clear-button-text !default;
$input-separator-opacity: .5 !default;

$input-clear-button-focused-background: $base-text !default;
$input-clear-button-focused-opacity: .2 !default;


// Picker
$picker-select-border-width: 0px !default;

$picker-select-padding-x: $input-padding-y !default;
$picker-select-padding-y: $input-padding-y !default;

$picker-select-padding-x-sm: $input-padding-y-sm !default;
$picker-select-padding-y-sm: $input-padding-y-sm !default;

$picker-select-padding-x-lg: $input-padding-y-sm !default;
$picker-select-padding-y-lg: $input-padding-y-sm !default;

$picker-select-calc-size: $input-inner-calc-height !default;
$picker-select-calc-size-sm: $input-inner-calc-height-sm !default;
$picker-select-calc-size-lg: $input-inner-calc-height-lg !default;


// Spinner
$spinner-width: $button-inner-calc-size !default;
$spinner-icon-offset: 2px !default;
