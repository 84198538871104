@include exports("combobox/layout") {

    .k-combobox {
        width: $input-default-width;
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        background: none;
        font-family: $input-font-family;
        font-size: $input-font-size;
        line-height: $input-line-height;
        text-align: left;
        white-space: nowrap;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-dropdown-wrap {
            @include use-background-clip();
            @include border-radius( $input-border-radius );
            padding: 0;
            width: 100%;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            transition: all .1s ease; // sass-lint:disable-line no-transition-all
            outline: 0;
            cursor: initial;
        }


        // Input
        .k-input {}


        // Clear value
        .k-clear-value {
            opacity: .5;
            visibility: hidden;

            &:hover {
                opacity: 1;
            }
        }
        &:hover,
        // &:focus-within,
        &.k-state-hover,
        &.k-state-focused,
        .k-dropdown-wrap.k-state-hover,
        .k-dropdown-wrap.k-state-focused {
            .k-clear-value {
                visibility: visible;
            }
        }
        &:focus-within {
            .k-clear-value {
                visibility: visible;
            }
        }

        // Select
        .k-select {
            @include border-right-radius-only( $input-border-radius );
            padding: $combobox-select-padding-y $combobox-select-padding-x;
            width: if($use-picker-select-width, $combobox-select-width, auto);
            border-width: 0 0 0 $combobox-select-border-width;
            box-sizing: border-box;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            text-align: center;
            cursor: pointer;
        }

        &[dir="rtl"],
        .k-rtl & {
            text-align: right;

            .k-select {
                @include border-left-radius-only( $input-border-radius );
                border-width: 0 $combobox-select-border-width 0 0;
            }
        }
    }
}
