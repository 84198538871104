@include exports( "dropdownlist/theme" ) {

    .k-dropdown {
        @extend .k-text-selection !optional;

        .k-dropdown-wrap {
            @include fill(
                $dropdownlist-text,
                $dropdownlist-bg,
                $dropdownlist-border,
                $dropdownlist-gradient
            );

            // Hover state
            &:hover,
            &.k-state-hover {
                @include fill(
                    $dropdownlist-hovered-text,
                    $dropdownlist-hovered-bg,
                    $dropdownlist-hovered-border,
                    $dropdownlist-hovered-gradient
                );
            }

            &.k-state-active {}

            // Focused state
            &.k-state-focused {
                @include fill(
                    $dropdownlist-focused-text,
                    $dropdownlist-focused-bg,
                    $dropdownlist-focused-border,
                    $dropdownlist-focused-gradient
                );
                @include box-shadow($dropdownlist-focused-shadow);
            }

            // Invalid state
            &.k-invalid,
            &.k-invalid:hover,
            &.k-state-invalid {
                border-color: $invalid-border;

                &:focus,
                &.k-state-focused {
                    @include box-shadow($invalid-shadow);
                }
            }
        }

        // Invalid
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            > .k-dropdown-wrap {
                border-color: $invalid-border;

                &:focus,
                &.k-state-focused {
                    @include box-shadow($invalid-shadow);
                }
            }
        }

    }

    // Native select
    select.k-dropdown {
        @include fill(
            $dropdownlist-text,
            $dropdownlist-bg,
            $dropdownlist-border,
            $dropdownlist-gradient
        );

        // Hover state
        &:hover {
            @include fill(
                $dropdownlist-hovered-text,
                $dropdownlist-hovered-bg,
                $dropdownlist-hovered-border,
                $dropdownlist-hovered-gradient
            );
        }

        // Focused state
        &:focus {
            @include fill(
                $dropdownlist-focused-text,
                $dropdownlist-focused-bg,
                $dropdownlist-focused-border,
                $dropdownlist-focused-gradient
            );
            @include box-shadow($dropdownlist-focused-shadow);
        }
    }

}
