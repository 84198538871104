@include exports("dialog/theme") {
    // Titlebar
    .k-dialog-titlebar {
        @include fill(
            $dialog-titlebar-text,
            $dialog-titlebar-bg,
            $dialog-titlebar-border
        );
    }

    .k-dialog-button-layout-stretched {

        .k-button {
            border-color: inherit;
        }

        // Normal state
        .k-button:not(:hover):not(.k-state-hover):not(:active):not(.k-state-active) {
            color: inherit;
            background: none;
        }
        .k-primary:not(:hover):not(.k-state-hover):not(:active):not(.k-state-active) {
            color: $primary;
        }

        // Focused state
        .k-button:focus,
        .k-button.k-state-focused {
            box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .13);
        }
        .k-button.k-primary:focus,
        .k-button.k-primary.k-state-focused {
            box-shadow: inset 0 0 0 2px rgba($primary, .3);
        }

    }

}
