@include exports( "utils/aria" ) {

    .k-sr-only {
        position: absolute;
        left: -1px;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

}
