// Button

/// Width of border around buttons
/// @group buttons
$button-border-width: 1px !default;
/// Border radius of buttons
/// @group buttons
$button-border-radius: $border-radius !default;

/// Horizontal padding of buttons
/// @group buttons
$button-padding-x: $padding-x !default;
/// Vertical padding of buttons
/// @group buttons
$button-padding-y: $padding-y !default;
$button-font-family: $font-family !default;
$button-font-size: $font-size !default;
$button-line-height: $line-height !default;

$button-padding-x-sm: $button-padding-x / 2 !default;
$button-padding-y-sm: $button-padding-y / 2 !default;
$button-line-height-sm: $line-height-sm !default;

$button-calc-size: calc( #{$button-line-height * 1em} + #{$button-padding-y * 2} + #{$button-border-width * 2} ) !default;
$button-inner-calc-size: calc( #{$button-line-height * 1em} + #{$button-padding-y * 2} ) !default;

/// The text color of the buttons.
/// @group buttons
$button-text: $base-text !default;

/// The background of the buttons.
/// @group buttons
$button-bg: $base-bg !default;

/// The border color of the buttons.
/// @group buttons
$button-border: $base-border !default;

/// The background gradient of the buttons.
/// @group buttons
$button-gradient: $base-gradient !default;
$button-shadow: null !default;

/// The text color of hovered buttons.
/// @group buttons
$button-hovered-text: $hovered-text !default;

/// The background of hovered buttons.
/// @group buttons
$button-hovered-bg: $hovered-bg !default;

/// The border color of hovered buttons.
/// @group buttons
$button-hovered-border: $hovered-border !default;

/// The background gradient of hovered buttons.
/// @group buttons
$button-hovered-gradient: $hovered-gradient !default;
$button-hovered-shadow: null !default;

/// The text color of pressed buttons.
/// @group buttons
$button-pressed-text: $button-text !default;

/// The background color of pressed buttons.
/// @group buttons
$button-pressed-bg: $button-bg !default;

/// The border color of pressed buttons.
/// @group buttons
$button-pressed-border: $button-border !default;

/// The background gradient of pressed buttons.
/// @group buttons
$button-pressed-gradient: none !default;
$button-pressed-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06) !default;

/// The shadow of focused buttons.
/// @group buttons
$button-focused-shadow: 0 3px 4px 0 rgba(0, 0, 0, .06) !default;


// Disabled button
$button-disabled-bg: null !default;
$button-disabled-text: null !default;


// Primary button
$primary-button-bg: $primary !default;
$primary-button-text: contrast-wcag( $primary-button-bg ) !default;
$primary-button-border: $primary-button-bg !default;
$primary-button-gradient: rgba( adjust-hue( $primary-button-bg, 14), 0 ), rgba( adjust-hue( $primary-button-bg, 14), .2 ) !default;

$primary-button-hovered-text: $primary-button-text !default;
$primary-button-hovered-bg: adjust-hue( $primary-button-bg, 3) !default;
$primary-button-hovered-border: $primary-button-hovered-bg !default;
$primary-button-hovered-gradient: rgba( adjust-hue( $primary-button-hovered-bg, 14), 0 ), rgba( adjust-hue( $primary-button-hovered-bg, 14), .2 ) !default;

$primary-button-pressed-text: $primary-button-text !default;
$primary-button-pressed-bg: $primary-button-bg !default;
$primary-button-pressed-border: $primary-button-pressed-bg !default;
$primary-button-pressed-gradient: rgba( adjust-hue( $primary-button-pressed-bg, 14), .2 ), rgba( adjust-hue( $primary-button-pressed-bg, 14), 0 ) !default;
$primary-button-pressed-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .06) !default;

$primary-button-focused-shadow: 0 3px 4px 0 rgba($primary, .4) !default;

$flat-button-hover-opacity: .08 !default;
$flat-button-focused-opacity: null !default;
$flat-button-active-opacity: .16 !default;
$flat-button-selected-opacity: .2 !default;

$clear-button-text: $button-text !default;
$clear-button-hover-text: darken($body-text, 13%) !default;
$clear-button-focused-opacity: .1 !default;
