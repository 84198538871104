// Radio Buttons
$radio-size: map-get( $spacing, 4 ) !default;
$radio-radius: 50% !default;
$radio-border-width: 1px !default;
$radio-line-height: calc(#{$radio-size} + #{$radio-border-width}) !default;

$radio-icon-size: ( $radio-size / 2 ) !default;

$radio-label-margin-x: map-get( $spacing, 1 ) !default;

$radio-bg: $checkbox-bg !default;
$radio-text: $checkbox-text !default;
$radio-border: $checkbox-border !default;
$radio-hovered-bg: $checkbox-hovered-bg !default;
$radio-hovered-text: $checkbox-hovered-text !default;
$radio-hovered-border: $checkbox-hovered-border !default;
$radio-checked-bg: $checkbox-checked-bg !default;
$radio-checked-text: $checkbox-checked-text !default;
$radio-checked-border: $checkbox-checked-border !default;
$radio-indeterminate-bg: $radio-checked-bg !default;
$radio-indeterminate-text: $radio-checked-text !default;
$radio-indeterminate-border: $radio-checked-border !default;
$radio-focused-border: $checkbox-hovered-border !default;
$radio-focused-shadow: $checkbox-focused-shadow !default;
$radio-focused-checked-border: $checkbox-checked-border !default;
$radio-focused-checked-shadow: $checkbox-focused-checked-shadow !default;
$radio-disabled-bg: null !default;
$radio-disabled-text: null !default;
$radio-disabled-border: null !default;
$radio-disabled-checked-bg: null !default;
$radio-disabled-checked-text: null !default;
$radio-disabled-checked-border: null !default;
$radio-invalid-bg: null !default;
$radio-invalid-text: $invalid-text !default;
$radio-invalid-border: $invalid-border !default;

$radio-list-margin: 0px !default;
$radio-list-padding: 0px !default;
$radio-list-item-padding-x: 0px !default;
$radio-list-item-padding-y: $list-item-padding-y !default;
$radio-list-horizontal-item-margin-x: 32px !default;

$radio-ripple-size: $radio-size * 3 !default;
$radio-ripple-margin: calc(-1 * (#{$radio-size} + 2 * #{$radio-border-width})) !default;
$radio-ripple-opacity: .3 !default;
