@include exports("switch/theme") {

    .k-switch-container {
        @include box-shadow( $switch-container-shadow );
    }

    .k-switch-handle {
        @include use-background-clip();
    }

    .k-switch-label-on,
    .k-switch-label-off {
        text-shadow: none;
    }

    .k-switch-on {
        .k-switch-container {
            @include fill(
                $switch-on-text,
                $switch-on-bg,
                $switch-on-border,
                $switch-on-gradient
            );
        }

        .k-switch-handle {
            @include fill(
                $switch-on-handle-text,
                $switch-on-handle-bg,
                $switch-on-handle-border,
                $switch-on-handle-gradient
            );
            @include box-shadow( $switch-on-handle-shadow );
        }

        &:focus,
        &.k-state-focused {
            @include box-shadow( $switch-on-focused-shadow );

            .k-switch-container {
                @include fill(
                    $switch-on-focused-text,
                    $switch-on-focused-bg,
                    $switch-on-focused-border,
                    $switch-on-focused-gradient
                );
            }
        }

        &:hover,
        &.k-state-hover {
            .k-switch-container {
                @include fill(
                    $switch-on-hovered-text,
                    $switch-on-hovered-bg,
                    $switch-on-hovered-border,
                    $switch-on-hovered-gradient
                );
            }

            .k-switch-handle {
                @include fill(
                    $switch-on-handle-hovered-text,
                    $switch-on-handle-hovered-bg,
                    $switch-on-handle-hovered-border,
                    $switch-on-handle-hovered-gradient
                );
            }
        }

        .k-switch-label-off {
            color: transparent;
        }
    }

    .k-switch-off {
        .k-switch-container {
            @include fill(
                $switch-off-text,
                $switch-off-bg,
                $switch-off-border,
                $switch-off-gradient
            );
        }

        .k-switch-handle {
            @include fill(
                $switch-off-handle-text,
                $switch-off-handle-bg,
                $switch-off-handle-border,
                $switch-off-handle-gradient
            );
            @include box-shadow( $switch-off-handle-shadow );
        }

        &:focus,
        &.k-state-focused {
            @include box-shadow( $switch-off-focused-shadow );

            .k-switch-container {
                @include fill(
                    $switch-off-focused-text,
                    $switch-off-focused-bg,
                    $switch-off-focused-border,
                    $switch-off-focused-gradient
                );
            }
        }

        &:hover,
        &.k-state-hover {
            .k-switch-container {
                @include fill(
                    $switch-off-hovered-text,
                    $switch-off-hovered-bg,
                    $switch-off-hovered-border,
                    $switch-off-hovered-gradient
                );
            }

            .k-switch-handle {
                @include fill(
                    $switch-off-handle-hovered-text,
                    $switch-off-handle-hovered-bg,
                    $switch-off-handle-hovered-border,
                    $switch-off-handle-hovered-gradient
                );
            }
        }

        .k-switch-label-on {
            color: transparent;
        }
    }

    .k-switch {
        box-shadow: none;

        &:focus,
        &.k-state-focused {

            .k-switch-container {
                outline: none;
                @include box-shadow( $switch-container-focused-shadow );
            }
        }

        &:hover,
        &.k-state-hover {

            .k-switch-container {
                @include box-shadow( $switch-container-hovered-shadow );
            }
        }

        &.k-state-disabled {
            cursor: default;

            &:focus,
            &.k-state-focused {
                @include box-shadow( $switch-container-shadow );
            }
        }

        &[aria-readonly="true"] {
            pointer-events: none;
        }
    }
}
