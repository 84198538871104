@include exports("numerictextbox/layout") {

    // Base
    .k-numerictextbox {
        width: $input-default-width;
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        background: none;
        font-family: $input-font-family;
        font-size: $input-font-size;
        line-height: $input-line-height;
        text-align: left;
        white-space: nowrap;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-numeric-wrap {
            @include use-background-clip();
            @include border-radius( $input-border-radius );
            padding: 0;
            width: 100%;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            position: relative;
            transition: all .1s ease; // sass-lint:disable-line no-transition-all
            cursor: default;
            outline: 0;
            display: flex;
            flex-flow: row nowrap;
        }


        // Input
        .k-input {
            text-overflow: clip;
        }


        // Select
        .k-select {
            @include border-right-radius-only( $input-border-radius );
            padding: 0;
            width: $spinner-width;
            border-width: 0;
            box-sizing: border-box;
            border-style: solid;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            flex: 0 0 auto;
            text-align: center;
            cursor: pointer;
        }

        .k-link {
            flex: 1 1 auto;
            display: block;
            overflow: hidden;
            position: relative;

            .k-icon {
                position: absolute;
                right: 50%;
                transform: translateX(50%);
            }
        }

        .k-link-increase .k-icon {
            bottom: -$spinner-icon-offset;
        }

        .k-link-decrease .k-icon {
            top: -$spinner-icon-offset;
        }

        .k-i-warning {
            align-self: center;
            margin-right: $spacer-x / 2;
        }


        // RTL
        .k-rtl &,
        &[dir="rtl"] {
            text-align: right;

            .k-numeric-wrap .k-i-warning {
                align-self: center;
                margin-right: 0;
                margin-left: $spacer-x / 2;
            }

            .k-select {
                @include border-left-radius-only( $input-border-radius );
            }
        }
    }

}
