@include exports("dialog/layout") {

    .k-dialog-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        outline: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;

        .k-dialog {
            position: relative;
        }
    }

    .k-dialog {
        padding: 0;
        position: fixed;
        box-sizing: border-box;

        // Centered
        &.k-dialog-centered {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

        .k-multiselect {
            width: 100%;
        }
    }


    // Titlebar
    .k-dialog-titlebar {}
    .k-dialog-title {}

    .k-dialog-close {
        align-self: flex-end;
    }


    // Actions
    .k-dialog-actions {}
    .k-dialog-action {}


    // Content
    .k-dialog-content {}


    // Buttons
    .k-dialog-buttongroup {
        @include border-bottom-radius( $window-border-radius );
        padding: $dialog-buttongroup-padding-y $dialog-buttongroup-padding-x;
        border-width: $dialog-buttongroup-border-width 0 0;
        border-style: solid;
        border-color: inherit;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;

        .k-button {
            // Kendo UI for jQuery sets inline width
            width: auto !important; // sass-lint:disable-line no-important
            max-width: 100%;
        }

        .k-button ~ .k-button {
            margin-left: $dialog-button-gap;

            .k-rtl &,
            [dir="rtl"] & {
                margin-left: 0;
                margin-right: $dialog-button-gap;
            }
        }
    }


    // Needed for the jQuery buttonLayout configuration:
    // https://docs.telerik.com/kendo-ui/api/javascript/ui/dialog/configuration/buttonlayout
    .k-dialog-button-layout-stretched {
        padding: 0;
        align-items: stretch;

        .k-button {
            @include border-radius( 0 );
            padding: $dialog-stretched-button-padding-y $dialog-stretched-button-padding-x;
            border-width: 0;
            text-overflow: ellipsis;
            flex: 1 0 0%;
            display: inline-block; // needed for ellipsis overflow
            overflow: hidden;
        }

        .k-button ~ .k-button {
            margin: 0;
            border-left-width: $dialog-stretched-button-border-width;

            .k-rtl &,
            [dir="rtl"] & {
                margin: 0;
                border-left-width: 0;
                border-right-width: $dialog-stretched-button-border-width;
            }
        }
    }

    // Kendo UI for jQuery uses these styles
    .k-window-sm .k-dialog-button-layout-stretched {
        flex-direction: column;

        .k-button {
            flex: 0 0 auto;
            padding: ($dialog-stretched-button-padding-x / 2) ($dialog-stretched-button-padding-x / 2);
        }

        .k-button ~ .k-button {
            border-width: 0;
            border-top-width: $dialog-stretched-button-border-width;
        }
    }
    .k-window-md .k-dialog-button-layout-stretched {

        .k-button {
            padding: ($dialog-stretched-button-padding-x * .75) ($dialog-stretched-button-padding-x * .75);
        }
    }


    // No flexbox variant for older browsers
    .k-no-flexbox {

        .k-dialog-buttongroup {
            display: block;
            text-align: right;
        }

        .k-window-sm .k-dialog-button-layout-stretched {
            .k-button {
                display: block;
            }
        }

        .k-rtl,
        [dir="rtl"] {

            .k-dialog-buttongroup {
                text-align: left;
            }
        }

    }

}
