// Gantt
$gantt-border-width: 1px !default;
$gantt-font-family: $font-family !default;
$gantt-font-size: $font-size !default;
$gantt-line-height: $line-height !default;

$gantt-bg: $component-bg !default;
$gantt-text: $component-text !default;
$gantt-border: $component-border !default;

$gantt-treelist-bg: null !default;
$gantt-treelist-text: null !default;
$gantt-treelist-border: null !default;

$gantt-nonwork-bg: rgba( contrast-wcag( $gantt-bg ), .025 ) !default;
$gantt-nonwork-text: null !default;
$gantt-nonwork-border: null !default;

$gantt-line-size: 2px !default;
$gantt-line-fill: contrast-wcag( $gantt-bg ) !default;
$gantt-line-selected-fill: $primary !default;

$gantt-dot-size: 8px !default;
$gantt-dot-spacing: 4px !default;
$gantt-dot-bg: $gantt-text !default;
$gantt-dot-border: null !default;
$gantt-dot-hover-bg: $gantt-bg !default;
$gantt-dot-hover-border: $gantt-text !default;

$gantt-milestone-bg: $gantt-text !default;
$gantt-milestone-border: $gantt-border !default;
$gantt-milestone-selected-bg: $selected-bg !default;
$gantt-milestone-selected-border: $selected-border !default;

$gantt-summary-bg: try-tint( $gantt-text, 1 ) !default;
$gantt-summary-progress-bg: try-shade( $gantt-text, 5 ) !default;
$gantt-summary-selected-bg: try-tint( $selected-bg, 6 ) !default;
$gantt-summary-progress-selected-bg: $selected-bg !default;

$gantt-task-border-width: 0px !default;
$gantt-task-padding-x: map-get( $spacing, 2 ) !default;
$gantt-task-padding-y: map-get( $spacing, 1 ) !default;
$gantt-task-bg: try-tint( $gantt-text, 2 ) !default;
$gantt-task-text: contrast-wcag( $gantt-text ) !default;
$gantt-task-border: null !default;
$gantt-task-progress-bg: $gantt-text !default;
$gantt-task-selected-bg: try-tint( $selected-bg, 6 ) !default;
$gantt-task-selected-text: $selected-text !default;
$gantt-task-selected-border: null !default;
$gantt-task-progress-selected-bg: $selected-bg !default;

$gantt-planned-margin-y: .35em !default;
$gantt-planned-border-width: 2px !default;
$gantt-planned-line-height: .75em !default;

$gantt-planned-dependency-margin-y: .55em !default;

$gantt-planned-moment-width: 6px !default;
$gantt-planned-moment-height: $gantt-planned-moment-width !default;
$gantt-planned-moment-border-radius: calc( ( #{$gantt-planned-border-width * 2} + #{$gantt-planned-moment-width} ) / 2 ) !default;
$gantt-planned-moment-left-margin-x: calc( #{$gantt-planned-border-width} / 2 - #{$gantt-planned-moment-border-radius} ) !default;
$gantt-planned-milestone-moment-margin-x: calc( #{$gantt-planned-moment-width} / 2) !default;

$gantt-planned-duration-height: $gantt-planned-border-width !default;
$gantt-planned-duration-hover-height: calc( #{$gantt-planned-border-width} + 1px ) !default;

$gantt-planned-single-drag-hint-top: 0 !default;
$gantt-planned-summary-drag-hint-top: .5em !default;
$gantt-planned-milestone-drag-hint-top: .3em !default;

$gantt-rtl-milestone-wrap-margin-x: -2.4em !default;
$gantt-rtl-milestone-planned-moment-margin-x: .2em !default;
$gantt-rtl-milestone-dot-start-margin-x: -.1em !default;

$gantt-planned-offset-resize-handler-margin-x: 1.4em !default;

$gantt-planned-bg: $primary !default;
$gantt-planned-border: $gantt-planned-bg !default;

$gantt-delayed-bg: $error !default;
$gantt-delayed-bg-lighter: tint($gantt-delayed-bg, 5) !default;

$gantt-advanced-bg: $success !default;
$gantt-advanced-bg-lighter: tint($gantt-advanced-bg, 5) !default;

$gantt-action-on-offset-text: #000000 !default;
$gantt-offset-resize-handler-top: 50% !default;
