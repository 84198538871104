@include exports("pager/theme") {

    .k-pager-wrap {
        @extend .k-text-selection !optional;
        @include fill(
            $pager-text,
            $pager-bg,
            $pager-border
        );
    }


    %base-pager-item-theme {
        @include fill(
            $pager-item-text,
            $pager-item-bg,
            $pager-item-border
        );

        &:hover,
        &.k-state-hover {
            @include fill(
                $pager-item-hover-text,
                $pager-item-hover-bg,
                $pager-item-hover-border
            );
        }

        &.k-state-selected {
            @include fill(
                $pager-item-selected-text,
                $pager-item-selected-bg,
                $pager-item-selected-border
            );
        }

        &:focus,
        &.k-state-focus {
            background-color: $pager-item-focus-bg;
            @include box-shadow( $pager-item-focus-shadow );
        }
    }


    // Pager items
    .k-pager-nav.k-link {
        @extend %base-pager-item-theme;
    }


    // Pager numbers
    .k-pager-numbers {
        .k-link {
            @include fill(
                $pager-number-text,
                $pager-number-bg,
                $pager-number-border
            );

            &:hover,
            &.k-state-hover {
                @include fill(
                    $pager-number-hover-text,
                    $pager-number-hover-bg,
                    $pager-number-hover-border
                );
            }

            &:focus,
            &.k-state-focus {
                background-color: $pager-number-focus-bg;
                @include box-shadow( $pager-number-focus-shadow );
            }

            &.k-state-selected {
                @include fill(
                    $pager-number-selected-text,
                    $pager-number-selected-bg,
                    $pager-number-selected-border
                );
            }
        }
    }


    // Pager refresh
    .k-pager-refresh {
        @extend %base-pager-item-theme;
    }




    // Responsive
    .k-pager-md,
    .k-pager-sm {

        .k-pager-numbers {

            .k-current-page .k-link {
                @include fill(
                    $dropdownlist-text,
                    $dropdownlist-bg,
                    $dropdownlist-border
                );

                &:hover,
                &.k-state-hover {
                    @include fill(
                        $dropdownlist-hovered-text,
                        $dropdownlist-hovered-bg,
                        $dropdownlist-hovered-border
                    );
                }
            }

            &.k-state-expanded {
                @include fill(
                    $popup-text,
                    $popup-bg,
                    $popup-border
                );
                @include box-shadow( $popup-shadow );

                .k-link {

                    &:hover,
                    &.k-state-hover {
                        @include fill(
                            $list-item-hovered-text,
                            $list-item-hovered-bg
                        )
                    }

                    &.k-state-selected {
                        @include fill(
                            $list-item-selected-text,
                            $list-item-selected-bg
                        )
                    }

                }
            }

        }

    }

}
