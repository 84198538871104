@include exports("treelist/layout") {

    // Base
    .k-treelist {
        &.k-grid-display-block.k-grid-lockedcolumns {
            display: block;
        }

        .k-status {
            padding: .4em .6em;
            line-height: 1.6em;

            .k-loading {
                vertical-align: baseline;
                margin-right: 5px;
            }
        }

        tr.k-hidden {
            display: none;
        }

        .k-treelist-dragging,
        .k-treelist-dragging .k-state-hover {
            cursor: default;
        }

        .k-drop-hint {
            transform: translate(0, -50%);
        }

        tr.k-footer td,
        tr.k-footer-template td {
            border-width: $treelist-footer-row-border-width 0;
            border-style: solid;
            font-weight: bold;
        }

        tr.k-footer + tr.k-footer td {
            border-top-width: 0;
        }
    }


    // Sticky headers
    .k-treelist-scrollable {
        > table,
        .k-grid-header tr,
        .k-grid-header th {
            background-color: inherit;
        }

        .k-grid-toolbar {
            position: sticky;
            z-index: 3;
            top: 0;
            left: 0;
        }

        .k-grid-header th {
            position: sticky;
            z-index: 1;
        }

        .k-grid-header th.k-grid-header-sticky {
            z-index: 3;
        }

        thead.k-grid-header {
            padding-right: 0;
        }

        tr.k-group-footer + tr.k-group-footer td {
            border-top-width: 0;
        }

        &[dir = "rtl"],
        .k-rtl & {
            thead.k-grid-header {
                padding-left: 0;
            }
        }

    }

    .k-drag-separator {
        display: inline-block;
        border-right: 1px solid;
        height: 1em;
        vertical-align: top;
        margin: 0 .5em;
    }

}
