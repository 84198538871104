.grid-action-button {
    width: 26px;
}

.btn-label.icon {
    background-color: #ffffff;
    border-right: 1px solid lightgray;
}

    .btn-label.icon:hover {
        background-color: #e6e6e6;
        border-color: #adadad;
    }

.icon {
    display: inline-block;
    width: auto;
    height: 16px;
    vertical-align: sub;
}

    .icon.icon-lg {
        height: 24px;
    }

    .icon.icon-4x {
        height: 52px;
    }

.icon-home {
    content: url(/assets/img/icons/24x24/home.png);
}

.icon-gauge {
    content: url(/assets/img/icons/64x64/gauge.png);
}

.icon-pieces {
    content: url(/assets/img/icons/24x24/pieces.png);
}

.icon-factory {
    content: url(/assets/img/icons/24x24/factory.png);
}

.icon-object_cube {
    content: url(/assets/img/icons/64x64/object_cube.png);
}

.icon-cube_molecule2 {
    content: url(/assets/img/icons/24x24/cube_molecule2.png);
}

.icon-briefcase_document {
    content: url(/assets/img/icons/64x64/briefcase_document.png);
}

.icon-briefcase_document2 {
    content: url(/assets/img/icons/24x24/briefcase_document.png);
}

.icon-gearwheels {
    content: url(/assets/img/icons/64x64/gearwheels.png);
}

.icon-window_explorer {
    content: url(/assets/img/icons/24x24/window_explorer.png);
}

.icon-bookmarks {
    content: url(/assets/img/icons/24x24/bookmarks.png);
}

.icon-floppy_disk {
    content: url(/assets/img/icons/24x24/floppy_disk.png);
}

.icon-arrow_left {
    content: url(/assets/img/icons/24x24/arrow_left.png);
}

.icon-navigate_cross {
    content: url(/assets/img/icons/24x24/navigate_cross.png);
}

.icon-garbage_can {
    content: url(/assets/img/icons/24x24/garbage_can.png);
}

.icon-edit {
    content: url(/assets/img/icons/24x24/edit.png);
}

.icon-pencil {
    content: url(/assets/img/icons/24x24/pencil.png);
}

.icon-add {
    content: url(/assets/img/icons/24x24/add.png);
}

.icon-plus {
    content: url(/assets/img/icons/24x24/plus.png);
}

.icon-minus {
    content: url(/assets/img/icons/24x24/minus.png);
}

.icon-magnifying_glass {
    content: url(/assets/img/icons/24x24/magnifying_glass.png);
}

.icon-erase {
    content: url(/assets/img/icons/24x24/erase.png);
}

.icon-selection_delete {
    content: url(/assets/img/icons/24x24/selection_delete.png);
}

.icon-cloud_download {
    content: url(/assets/img/icons/24x24/cloud_download.png);
}

.icon-support {
    content: url(/assets/img/icons/24x24/support.png);
}

.icon-sync {
    content: url(/assets/img/icons/24x24/sync.png);
}

.icon-cloud_upload {
    content: url(/assets/img/icons/24x24/cloud_upload.png);
}

.icon-eye_blind {
    content: url(/assets/img/icons/24x24/eye_blind.png);
}

.icon-notebook2 {
    content: url(/assets/img/icons/24x24/notebook2.png);
}

.icon-notebook3 {
    content: url(/assets/img/icons/24x24/notebook3.png);
}

.icon-folder_cubes {
    content: url(/assets/img/icons/24x24/folder_cubes.png);
}

.icon-folders2 {
    content: url(/assets/img/icons/24x24/folders2.png);
}

.icon-folders {
    content: url(/assets/img/icons/24x24/folders.png);
}

.icon-handshake {
    content: url(/assets/img/icons/24x24/handshake.png);
}

.icon-clipboard {
    content: url(/assets/img/icons/24x24/clipboard.png);
}

.icon-clipboard_empty {
    content: url(/assets/img/icons/24x24/clipboard_empty.png);
}

.icon-toolbox {
    content: url(/assets/img/icons/24x24/toolbox.png);
}

.icon-pin {
    content: url(/assets/img/icons/24x24/pin.png);
}

.icon-industrial_robot {
    content: url(/assets/img/icons/24x24/industrial_robot.png);
}

.icon-office_building2 {
    content: url(/assets/img/icons/24x24/office_building2.png);
}

.icon-paint_brush {
    content: url(/assets/img/icons/24x24/paint_brush.png);
}

.icon-users {
    content: url(/assets/img/icons/64x64/users.png);
}

.icon-user_glasses {
    content: url(/assets/img/icons/24x24/user_glasses.png);
}

.icon-signpost2 {
    content: url(/assets/img/icons/24x24/signpost2.png);
}

.icon-signpost3 {
    content: url(/assets/img/icons/24x24/signpost3.png);
}

.icon-document_chart {
    content: url(/assets/img/icons/24x24/document_chart.png);
}

.icon-box_into {
    content: url(/assets/img/icons/24x24/box_into.png);
}

.icon-address_book2 {
    content: url(/assets/img/icons/24x24/address_book2.png);
}

.icon-safety_glasses {
    content: url(/assets/img/icons/24x24/safety_glasses.png);
}

.icon-documents_exchange {
    content: url(/assets/img/icons/24x24/documents_exchange.png);
}

.icon-client_network {
    content: url(/assets/img/icons/24x24/client_network.png);
}

.icon-clients {
    content: url(/assets/img/icons/24x24/clients.png);
}

.icon-bell {
    content: url(/assets/img/icons/24x24/bell.png);
}

.icon-package {
    content: url(/assets/img/icons/24x24/package.png);
}

.icon-map_location {
    content: url(/assets/img/icons/24x24/map_location.png);
}

.icon-share {
    content: url(/assets/img/icons/24x24/share.png);
}

.icon-inbox_out {
    content: url(/assets/img/icons/24x24/inbox_out.png);
}

.icon-inbox_full {
    content: url(/assets/img/icons/24x24/inbox_full.png);
}

.icon-mail_forward  {
    content: url(/assets/img/icons/24x24/mail_forward.png);
}

.icon-memory {
    content: url(/assets/img/icons/24x24/memory.png);
}

.icon-credit_card {
    content: url(/assets/img/icons/24x24/credit_card.png);
}

.icon-note_pinned {
    content: url(/assets/img/icons/24x24/note_pinned.png);
}

.icon-paperclip {
    content: url(/assets/img/icons/24x24/paperclip.png);
}

.icon-check {
    content: url(/assets/img/icons/24x24/check.png);
}

.icon-checks {
    content: url(/assets/img/icons/24x24/checks.png);
}

.icon-users_family {
    content: url(/assets/img/icons/24x24/users_family.png);
}

.icon-history {
    content: url(/assets/img/icons/24x24/history.png);
}

.icon-history2 {
    content: url(/assets/img/icons/24x24/history2.png);
}

.icon-photo_landscape {
    content: url(/assets/img/icons/24x24/photo_landscape.png);
}

.icon-ok {
    content: url(/assets/img/icons/24x24/ok.png);
}

.icon-ok_blue {
    content: url(/assets/img/icons/24x24/ok_blue.png);
}

.icon-error {
    content: url(/assets/img/icons/24x24/error.png);
}

.icon-question {
    content: url(/assets/img/icons/24x24/question.png);
}

.icon-signal_flag {
    content: url(/assets/img/icons/24x24/signal_flag.png);
}

.icon-presentation2 {
    content: url(/assets/img/icons/24x24/presentation2.png);
}

.icon-line_spacing_paragraph {
    content: url(/assets/img/icons/24x24/line_spacing_paragraph.png);
}

.icon-drop_down_list {
    content: url(/assets/img/icons/24x24/drop_down_list.png);
}

.icon-checkbox {
    content: url(/assets/img/icons/24x24/checkbox.png);
}

.icon-checkbox_selected {
    content: url(/assets/img/icons/24x24/checkbox_selected.png);
}

.icon-chart_line {
    content: url(/assets/img/icons/24x24/chart_line.png);
}

.icon-chart_pie {
    content: url(/assets/img/icons/24x24/chart_pie.png);
}

.icon-chart_hi_lo {
    content: url(/assets/img/icons/24x24/chart_hi_lo.png);
}

.icon-chart_area {
    content: url(/assets/img/icons/24x24/chart_area.png);
}

.icon-podium2 {
    content: url(/assets/img/icons/24x24/podium2.png);
}

.icon-elements_hierarchy {
    content: url(/assets/img/icons/24x24/elements_hierarchy.png);
}

.icon-book_telephone {
    content: url(/assets/img/icons/24x24/book_telephone.png);
}

.icon-phone_receiver2 {
    content: url(/assets/img/icons/24x24/phone_receiver2.png);
}

.icon-maximize {
    content: url(/assets/img/icons/24x24/maximize.png);
}

.icon-minimize {
    content: url(/assets/img/icons/24x24/minimize.png);
}

.icon-navigate_up {
    content: url(/assets/img/icons/24x24/navigate_up.png);
}

.icon-navigate_down {
    content: url(/assets/img/icons/24x24/navigate_down.png);
}

.icon-tags {
    content: url(/assets/img/icons/24x24/tags.png);
}

.icon-smartphone {
    content: url(/assets/img/icons/24x24/smartphone.png);
}

.icon-user_smartphone {
    content: url(/assets/img/icons/24x24/user_smartphone.png);
}

.icon-user {
    content: url(/assets/img/icons/24x24/user.png);
}

.icon-shield {
    content: url(/assets/img/icons/24x24/shield.png);
}

.icon-sign_forbidden {
    content: url(/assets/img/icons/24x24/sign_forbidden.png);
}

.icon-document_center {
    content: url(/assets/img/icons/24x24/document_center.png);
}

.icon-navigate_end {
    content: url(/assets/img/icons/24x24/navigate_end.png);
}

.icon-undo {
    content: url(/assets/img/icons/24x24/undo.png);
}

.icon-contract {
    content: url(/assets/img/icons/24x24/contract.png);
}

.icon-log_in {
    content: url(/assets/img/icons/24x24/log_in.png);
}

.icon-log_out {
    content: url(/assets/img/icons/24x24/log_out.png);
}

.icon-monitor_size {
    content: url(/assets/img/icons/24x24/monitor_size.png);
}

.icon-arrow_spread2 {
    content: url(/assets/img/icons/24x24/arrow_spread2.png);
}

.icon-arrow-left {
    content: url(/assets/img/icons/24x24/arrow-left.png);
}

.icon-arrow-left_all {
    content: url(/assets/img/icons/24x24/arrow_left_all.png);
}

.icon-arrow_right {
    content: url(/assets/img/icons/24x24/arrow_right.png);
}

.icon-arrow_right_all {
    content: url(/assets/img/icons/24x24/arrow_right_all.png);
}

.icon-user_groups {
    content: url(/assets/img/icons/24x24/user_groups.png);
}

.icon-lock_open {
    content: url(/assets/img/icons/24x24/lock_open.png);
}

.icon-door_exit {
    content: url(/assets/img/icons/24x24/door_exit.png);
}

.icon-workstation {
    content: url(/assets/img/icons/24x24/workstation.png);
}

.icon-elements_branch {
    content: url(/assets/img/icons/24x24/elements_branch.png);
}

.icon-window_earth {
    content: url(/assets/img/icons/24x24/window_earth.png);
}

.icon-copy {
    content: url(/assets/img/icons/24x24/copy.png);
}

.icon-clipboard_checks {
    content: url(/assets/img/icons/24x24/clipboard_checks.png);
}

.icon-trafficlight_red {
    content: url(/assets/img/icons/24x24/trafficlight_red.png);
}

.icon-spreadsheed_row {
    content: url(/assets/img/icons/24x24/spreadsheed_row.png);
}

.icon-document_tag {
    content: url(/assets/img/icons/24x24/document_tag.png);
}

.icon-tape_measure {
    content: url(/assets/img/icons/24x24/tape_measure.png);
}

.icon-graph_claw {
    content: url(/assets/img/icons/24x24/graph_claw.png);
}

.icon-fit_to_width {
    content: url(/assets/img/icons/24x24/fit_to_width.png);
}

.icon-office_building {
    content: url(/assets/img/icons/24x24/office_building.png);
}

.icon-data_shared {
    content: url(/assets/img/icons/24x24/data_shared.png);
}

.icon-lock {
    content: url(/assets/img/icons/24x24/lock.png);
}

.icon-arrow_circle2 {
    content: url(/assets/img/icons/24x24/arrow_circle2.png);
}

.icon-print_layout_continous {
    content: url(/assets/img/icons/24x24/print_layout_continous.png);
}

.icon-switch3_off {
    content: url(/assets/img/icons/24x24/switch3_off.png);
}

.icon-switch3_on {
    content: url(/assets/img/icons/24x24/switch3_on.png);
}

.icon-table {
    content: url(/assets/img/icons/24x24/table.png);
}

.icon-funnel {
    content: url(/assets/img/icons/24x24/funnel.png);
}

.icon-cloud_updown {
    content: url(/assets/img/icons/24x24/cloud_updown.png);
}

.icon-data_table {
    content: url(/assets/img/icons/24x24/data_table.png);
}

.icon-sign_warning {
    content: url(/assets/img/icons/24x24/sign_warning.png);
}

.icon-find-text {
    content: url(/assets/img/icons/24x24/find_text.png);
}

.icon-find-text-starts-with {
    content: url(/assets/img/icons/24x24/find_text_starts_with.png);
}

.icon-find-text-contains {
    content: url(/assets/img/icons/24x24/find_text_contains.png);
}

.icon-selection_refresh {
    content: url(/assets/img/icons/24x24/selection_refresh.png);
}

.icon-refresh {
    content: url(/assets/img/icons/24x24/refresh.png);
}

.icon-asset-map {
    content: url(/assets/img/maps/thumbnail_Asset_Green.png);
}

.icon-cert-asset-map {
    content: url(/assets/img/maps/thumbnail_CertCheck_Green.png);
}

.icon-location-map {
    content: url(/assets/img/maps/thumbnail_Location_Green.png);
}

.icon-customer-map {
    content: url(/assets/img/maps/thumbnail_Customer_Green.png);
}

.icon-index {
    content: url(/assets/img/icons/24x24/index.png);
}

.icon-cert-book {
    content: url(/assets/img/icons/70x70/certBookIcon.png);
}

.icon-cert-map {
    content: url(/assets/img/icons/70x70/certMapIcon.png);
}

.icon-job {
    content: url(/assets/img/icons/32x32/job.png);
}

.icon-task {
    content: url(/assets/img/icons/32x32/task.png);
}

.icon-cert-plan {
    content: url(/assets/img/icons/32x32/cert-plan.png);
}

.icon-certnet-report {
    content: url(/assets/img/icons/32x32/report.png);
}

.icon-certnet-checkin {
    content: url(/assets/img/icons/32x32/check-in.png);
}

.icon-certnet-checkout {
    content: url(/assets/img/icons/32x32/check-out.png);
}

.icon-stopwatch2 {
    content: url(/assets/img/icons/24x24/stopwatch2.png);
}

.icon-wooden_pallet {
    content: url(/assets/img/icons/24x24/wooden_pallet.png);
}

.icon-wooden_pallet_box {
    content: url(/assets/img/icons/24x24/wooden_pallet_box.png);
}

.icon-get_tools {
    content: url(/assets/img/icons/24x24/get_tools.png);
}

.icon-audit {
    content: url(/assets/img/icons/24x24/audit.png);
}

.icon-parent_child {
    content: url(/assets/img/icons/24x24/parent_child.png);
}

.icon-back {
    content: url(/assets/img/icons/32x32/back.png);
}

.icon-remove_from_parent {
    content: url(/assets/img/icons/32x32/remove-from-parent.png);
}

.icon-add_parent {
    content: url(/assets/img/icons/32x32/add-parent.png);
}

.icon-text_entry {
    content: url(/assets/img/icons/32x32/text-entry.png);
}

.icon-barcode {
    content: url(/assets/img/icons/32x32/barcode.png);
}

.icon-chip_entry {
    content: url(/assets/img/icons/32x32/chip-entry.png);
}

.icon-remove_selected {
    content: url(/assets/img/icons/32x32/remove-selected.png);
}

.icon-add_selected {
    content: url(/assets/img/icons/32x32/add-selected.png);
}

.icon-configure_rules {
    content: url(/assets/img/icons/32x32/configure-rules.png);
}

.icon-mass_actions {
    content: url(/assets/img/icons/32x32/mass-actions.png);
}

.icon-verify_children {
    content: url(/assets/img/icons/32x32/verify-children.png);
}

.icon-show_children {
    content: url(/assets/img/icons/32x32/show-children.png);
}

.icon-confirm_white {
    content: url(/assets/img/icons/32x32/confirm-white.png);
}

.icon-cancel_white {
    content: url(/assets/img/icons/32x32/cancel-white.png);
}

.icon-goto_parent {
    content: url(/assets/img/icons/32x32/goto-parent.png);
}

.icon-attribute_user_group {
    content: url(/assets/img/icons/24x24/attribute_user_group.png);
}

.icon-certnetone-logo {
    content: url(/assets/img/icons/32x32/certnetone-logo.png);
}

.icon-calendar_clock {
    content: url(/assets/img/icons/24x24/calendar_clock.png);
}

.icon-media_play {
    content: url(/assets/img/icons/24x24/media_play.png);
}

.icon-wlan_antenna {
    content: url(/assets/img/icons/24x24/wlan_antenna.png);
}
