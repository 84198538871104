@include exports("autocomplete/theme") {

    // Remove first selector when https://github.com/telerik/kendo-angular-dropdowns/issues/656 is done.
    .k-autocomplete.k-header,
    .k-autocomplete {
        @include fill(
            $autocomplete-text,
            $autocomplete-bg,
            $autocomplete-border
        );
        @include box-shadow( $autocomplete-shadow );

        // Hover state
        &:hover,
        &.k-state-hover {
            @include fill(
                $autocomplete-hovered-text,
                $autocomplete-hovered-bg,
                $autocomplete-hovered-border
            );
            @include box-shadow( $autocomplete-hovered-shadow );
        }

        // Focus state
        // &:focus-within,
        &.k-state-open,
        &.k-state-focused {
            @include fill(
                $autocomplete-focused-text,
                $autocomplete-focused-bg,
                $autocomplete-focused-border
            );
            @include box-shadow( $autocomplete-focused-shadow );
        }
        &:focus-within {
            @include fill(
                $autocomplete-focused-text,
                $autocomplete-focused-bg,
                $autocomplete-focused-border
            );
            @include box-shadow( $autocomplete-focused-shadow );
        }

        // Disabled state
        &:disabled,
        &.k-state-disabled {
            @include fill(
                $autocomplete-disabled-text,
                $autocomplete-disabled-bg,
                $autocomplete-disabled-border
            );
            @include box-shadow( $autocomplete-disabled-shadow );
        }

        // Invalid state
        &.k-invalid,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            @include fill(
                $autocomplete-invalid-text,
                $autocomplete-invalid-bg,
                $autocomplete-invalid-border
            );

            // &:focus-within,
            &.k-state-focused {
                @include box-shadow( $autocomplete-invalid-shadow );
            }
            &:focus-within {
                @include box-shadow( $autocomplete-invalid-shadow );
            }
        }
    }

}
