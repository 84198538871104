@include exports("upload/layout") {

    .k-upload {
        border-width: $upload-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $upload-font-family;
        font-size: $upload-font-size;
        line-height: $upload-line-height;
        position: relative;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-upload-button {
            min-width: 7em;
        }

        // Drop zone
        .k-dropzone {
            padding: $upload-dropzone-padding-y $upload-dropzone-padding-x;
            border-width: 0;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            background-color: transparent;

            .k-dropzone-hint {
                font-style: normal;
                font-weight: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                flex: 1;
            }

            .k-upload-status {
                font-weight: normal;
                display: flex;
                align-items: center;
                position: relative;

                > .k-icon {
                    margin-right: $upload-icon-spacing;
                }
            }

            .k-upload-status + .k-dropzone-hint {
                display: none;
            }
        }

        .k-dropzone-active {
            .k-upload-status {
                display: none;
            }

            .k-upload-status + .k-dropzone-hint {
                display: block;
            }
        }

        // Upload files list
        .k-upload-files {
            max-height: $upload-max-height;
            overflow-y: auto;
            margin: 0;
            padding: 0;
            border-width: 1px 0 0;
            border-style: solid;
            border-color: inherit;
            list-style: none;

            .k-file-multiple,
            .k-file-single {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            .k-file-single {
                align-items: center;
            }

            .k-file-multiple {
                align-items: flex-start;
            }

            .k-file {
                padding: $upload-item-padding-y $upload-item-padding-x calc(#{$upload-item-padding-y} + #{$upload-progress-thickness});
                border-width: 0 0 1px;
                border-style: solid;
                border-color: inherit;
                outline: none;
                display: flex;
                align-items: flex-start;
                position: relative;
            }
            .k-file:last-child {
                border-width: 0;
            }

            .k-filename {
                margin-left: $spacer-x;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
            }

            .k-upload-status {
                .k-icon {
                    vertical-align: middle;
                }
            }

            .k-upload-pct {
                margin: 0 $upload-icon-spacing;
                font-weight: normal;
                vertical-align: middle;
            }

            ~ .k-clear-selected,
            ~ .k-upload-selected {
                margin-top: -$padding-y;
                border-width: 0;
            }

            ~ .k-upload-selected {
                border-left-width: 1px;
                border-left-style: solid;
                margin-left: -1px;
            }

            .k-file-name,
            .k-file-size,
            .k-file-validation-message,
            .k-file-information {
                display: block;
            }

            .k-file-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
            }

            .k-file-size,
            .k-file-information,
            .k-file-validation-message {
                font-size: $upload-validation-font-size;
                line-height: 1;
            }

            .k-file-extension-wrapper,
            .k-multiple-files-extension-wrapper,
            .k-file-invalid-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                margin: ($upload-item-image-border / 2) 0;
                width: 24px;
                height: 34px;
                border-width: 2px;
                border-style: solid;
                font-size: $font-size * .57;
                text-transform: uppercase;
                box-sizing: content-box;
                position: absolute;
                top: $upload-item-padding-y;
            }

            .k-file-group-wrapper,
            .k-file-invalid-group-wrapper,
            .k-multiple-files-group-wrapper,
            .k-multiple-files-invalid-group-wrapper {

                .k-file-group {
                    font-size: $upload-group-icon-size;
                }

                .k-file-state {
                    position: absolute;
                }
            }

            .k-file-invalid-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                font-size: 1.2em;
            }

            .k-multiple-files-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                margin-top: $padding-y;
            }

            .k-file-state {
                visibility: hidden;
            }

            // Old icon rendering
            .k-file-invalid-extension-wrapper + .k-file-name-size-wrapper,
            .k-file-single > .k-file-extension-wrapper + .k-file-name-size-wrapper {
                // 24px - image width
                margin-left: calc( 24px + #{$spacer-x} );
                //34px - $upload-item-image-height; 2px - $upload-item-image-border;
                min-height: 34px + (2px * 2);
                display: block;
                overflow: hidden;
            }

            // Old icon rendering
            .k-multiple-files-invalid-extension-wrapper + .k-file-name-size-wrapper,
            .k-multiple-files-extension-wrapper + .k-file-name-size-wrapper {
                // 24px - image width
                margin-left: calc( 24px + #{$spacer-x} );
            }

            // Old icon rendering
            .k-file-extension-wrapper + .k-file-name-size-wrapper {
                margin-bottom: -2px;

                .k-file-size,
                .k-file-information,
                .k-file-validation-message {
                    font-size: $font-size-sm * .786;
                }
            }

            // Old icon rendering
            .k-file-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::after,
            .k-file-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::after {
                content: "";
                border-style: solid;
                display: inline-block;
                position: absolute;
            }

            // Old icon rendering
            .k-file-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::before,
            .k-file-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::before {
                top: -1px;
                right: -1px;
                width: 0;
                height: 0;
                border-width: 6px;
                margin-top: -1px;
                margin-right: -1px;
            }

            // Old icon rendering
            .k-multiple-files-extension-wrapper::after,
            .k-multiple-files-invalid-extension-wrapper::after {
                top: -6px;
                left: -6px;
                width: 15px;
                height: 35px;
                border-width: 2px 0 0 2px;
            }

            // Old icon rendering
            .k-file-extension,
            .k-file-invalid-icon {
                position: absolute;
                bottom: 0;
                line-height: normal;
            }

            // Old icon rendering
            .k-file-invalid-icon {
                margin-left: 4px;
            }

            .k-file-extension {
                margin-left: .2em;
                margin-bottom: .3em;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                left: 0;
            }

            .k-upload-action {
                border-width: 0;
                color: inherit;
                background: none;
                box-shadow: none;
            }
        }

        .k-upload-files .k-file-name-size-wrapper {
            margin-left: 0;
            margin-right: 0;
            min-height: $upload-group-icon-size;
            display: block;
            overflow: hidden;
            flex: 1 0 0;
        }

        // old rendering
        .k-file-multiple > .k-file-name-size-wrapper {
            margin-left: $upload-item-padding-x;
        }

        .k-multiple-files-wrapper,
        .k-file-single > .k-file-name-size-wrapper,
        .k-file > .k-file-name-size-wrapper {
            margin-left: $padding-x;
            margin-right: 0;
            min-height: $upload-group-icon-size;
            display: block;
            overflow: hidden;
            flex: 1 0 0;
        }

        .k-multiple-files-wrapper {
            .k-file-name-size-wrapper {
                margin-bottom: $upload-multiple-items-spacing;
                display: block;
            }

            .k-file-information {
                margin-top: -($upload-multiple-items-spacing / 3);
            }
        }

        //old rendering
        .k-progress {
            height: $upload-progress-thickness;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .k-progressbar {
            height: $upload-progress-thickness;
            position: absolute;
            bottom: $upload-item-padding-y / 2;
            // successed
            width: calc(100% - #{$upload-item-padding-x}*2);

            .k-progress {
                position: absolute;
                width: 100%;
                max-width: 100%;
                height: 100%;
            }
        }

        .k-file-invalid > .k-progress {
            width: 100%;
        }


        // Action buttons
        .k-actions,
        .k-action-buttons {
            border-top-width: 1px;
        }

        // RTL styles
        &[dir = "rtl"],
        .k-rtl & {
            .k-dropzone  {
                text-align: left;

                .k-upload-status > .k-icon {
                    margin-left: $padding-y-lg;
                    margin-right: 0;
                }
            }

            .k-multiple-files-wrapper,
            .k-file-single > .k-file-name-size-wrapper,
            .k-file-multiple > .k-file-name-size-wrapper,
            .k-file > .k-file-name-size-wrapper {
                margin-right: $padding-x;
            }

            .k-button+.k-button {
                border-right-width: 0;
            }

            //old rendering
            .k-progress {
                left: auto;
                right: 0;
            }
        }
    }

    .k-upload-button {
        position: relative;
        overflow: hidden;
        direction: ltr;

        input {
            // font should not depend on outside styles, otherwise upload may break
            font: 170px monospace !important; // sass-lint:disable-block no-important
            margin: 0;
            padding: 0;
            filter: alpha(opacity=0);
            opacity: 0;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
        input[disabled],
        input.k-state-disabled {
            visibility: hidden;
        }
    }

    .k-ie9 {
        .k-upload-status-total {
            top: 1.5em;

            > .k-icon {
                margin-top: -3px;
            }
        }
    }

    // No flexbox variant for older browsers
    .k-no-flexbox {

        // Upload files list
        .k-upload-files {
            .k-file-multiple,
            .k-file-single {
                display: block;
                width: 100%;
            }

            .k-upload-status {
                position: absolute;
                right: $upload-item-padding-x;
                top: $upload-item-padding-y;
            }

            .k-file-group-wrapper,
            .k-file-invalid-group-wrapper,
            .k-multiple-files-group-wrapper,
            .k-multiple-files-invalid-group-wrapper {
                position: absolute;
                top: $upload-item-padding-y;
            }

            .k-multiple-files-wrapper,
            .k-file-single > .k-file-name-size-wrapper,
            .k-file > .k-file-name-size-wrapper {
                margin-left: calc( #{$upload-group-icon-size} + #{$padding-x} );
                margin-right: calc( (#{$button-calc-size} + #{$button-padding-x} * 2) + 2em );
            }
        }

        .k-dropzone {
            position: relative;
            text-align: left;

            .k-upload-button {
                display: inline-block;
            }

            .k-dropzone-hint,
            .k-upload-status-total {
                position: absolute;
                top: calc(#{$upload-dropzone-padding-y} + (#{$button-padding-x} / 2));
                right: $upload-dropzone-padding-x;
            }
        }

        // RTL styles
        [dir = "rtl"],
        .k-rtl {
            .k-dropzone-hint,
            .k-upload-status-total,
            .k-upload-files .k-upload-status {
                right: auto;
                left: $upload-dropzone-padding-x;
            }

            .k-multiple-files-wrapper,
            .k-file-single > .k-file-name-size-wrapper,
            .k-file > .k-file-name-size-wrapper {
                margin-left: calc( (#{$button-calc-size} + #{$button-padding-x} * 2) + 2em );
                margin-right: calc( #{$upload-group-icon-size} + #{$padding-x} );

            }
        }
    }

}
