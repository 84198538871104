@include exports("rating/layout") {

    .k-rating {
        margin: 0;
        padding: 0;
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        background: none;
        font-family: $rating-font-family;
        font-size: $rating-font-size;
        line-height: $rating-line-height;
        display: inline-flex;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
    }

    .k-rating-container {
        flex: 0 1 auto;
        position: relative;
        box-sizing: border-box;
        margin: 0 $rating-container-margin-x;

        .k-rating-item {
            display: inline-flex;
            position: relative;
            padding: $rating-item-padding-y $rating-item-padding-x;
            align-items: center;
            vertical-align: middle;
            margin: 0;
            outline: 0;
            overflow: hidden;
            transition: color 200ms ease-out 0s;

            * {
                pointer-events: none;
            }

            .k-icon {
                font-size: $rating-icon-size;
            }

            .k-rating-precision-part,
            .k-rating-precision-complement {
                display: inline-block;
                position: absolute;
                top: $rating-item-padding-y;
                left: $rating-item-padding-x;
                overflow: hidden;
            }

            .k-rating-precision-complement {
                direction: rtl;
            }
        }
    }

    .k-rating-label {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        line-height: $rating-label-line-height;
        margin: $rating-label-margin-y $rating-label-margin-x;
    }

    .k-ie9 {
        .k-rating-label,
        .k-rating-container,
        .k-rating-item {
            display: inline-block;
        }
    }

    .k-rtl,
    [dir="rtl"] {
        .k-rating-precision-part  {
            left: auto;
            right: $rating-item-padding-x;
        }

        .k-rating-item .k-rating-precision-complement {
            direction: ltr;
        }
    }
}
