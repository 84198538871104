@include exports("textarea/theme") {

    // Textarea
    .k-textarea {
        @include fill(
            $textarea-text,
            $textarea-bg,
            $textarea-border
        );

        // Selection
        &::selection,
        .k-input::selection {
            @include fill (
                $color: $textarea-selected-text, // $primary-contrast,
                $bg: $textarea-selected-bg // $primary
            );
        }

        // Hovered
        &:hover,
        &.k-state-hover {
            @include fill(
                $textarea-hover-text,
                $textarea-hover-bg,
                $textarea-hover-border
            );
        }

        // Focused
        &:focus,
        // &:focus-within,
        &.k-state-focus,
        &.k-state-focused {
            @include fill(
                $textarea-focus-text,
                $textarea-focus-bg,
                $textarea-focus-border
            );
            @include box-shadow( $textarea-focus-shadow );
        }
        &:focus-within {
            @include fill(
                $textarea-focus-text,
                $textarea-focus-bg,
                $textarea-focus-border
            );
            @include box-shadow( $textarea-focus-shadow );
        }

        // Invalid
        &.k-invalid,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            border-color: $invalid-border;

            &:focus,
            // &:focus-within,
            &.k-state-focus,
            &.k-state-focused {
                @include box-shadow( $textarea-invalid-focus-shadow );
            }

            &:focus-within {
                @include box-shadow( $textarea-invalid-focus-shadow );
            }
        }
    }

}
