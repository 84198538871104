// Bottom-navigation
$bottom-nav-padding-x: map-get( $spacing, 1 ) !default;
$bottom-nav-padding-y: $bottom-nav-padding-x !default;
$bottom-nav-gap: $bottom-nav-padding-x !default;
$bottom-nav-border-width: 1px 0px 0px 0px !default;

$bottom-nav-font-family: $font-family !default;
$bottom-nav-font-size: $font-size !default;
$bottom-nav-line-height: normal !default;
$bottom-nav-letter-spacing: .2px !default;

$bottom-nav-item-padding-x: map-get( $spacing, 2 ) !default;
$bottom-nav-item-padding-y: 0 !default;
$bottom-nav-item-min-width: 72px !default;
$bottom-nav-item-max-width: null !default;
$bottom-nav-item-min-height: calc( #{$icon-size * 2.5} + #{$padding-x * 2} - #{$bottom-nav-padding-x * 2} ) !default;
$bottom-nav-item-border-radius: $border-radius !default;
$bottom-nav-item-gap: 0 map-get( $spacing, 1 ) !default;

$bottom-nav-item-icon-margin-y: map-get( $spacing, 2 ) !default;
$bottom-nav-item-icon-margin-x: $bottom-nav-item-icon-margin-y !default;
$bottom-nav-item-icon-size: map-get( $spacing, 6 ) !default;
$bottom-nav-item-disabled-opacity: .5 !default;

$bottom-nav-shadow: 0px 0px 5px rgba(0, 0, 0, .12) !default;

$bottom-nav-flat-bg: $component-bg !default;
$bottom-nav-flat-text: $component-text !default;
$bottom-nav-flat-border: $component-border !default;


