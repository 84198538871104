@include exports("editor/theme") {


    .k-editor {
        @include fill(
            $panel-text,
            $panel-bg,
            $panel-border
        );
    }

    kendo-editor {
        &.k-readonly {
            .k-editor-content.k-state-focused {
                outline-color: $text-color;
            }
        }
    }

    // Inline Editor
    .k-editor-inline {

        .k-table {
            &,
            & td {
                border-color: #cccccc;
            }
        }

        .k-table-resize-handle-wrapper {
            background-color: #ffffff;
            border-color: #000000;
        }

        .k-column-resize-handle-wrapper {
            .k-column-resize-handle {
                .k-column-resize-marker {
                    background-color: #00b0ff;
                }
            }
        }

        .k-row-resize-handle-wrapper {
            .k-row-resize-marker {
                background-color: #00b0ff;
            }
        }

        // Hover & Actve state
        &:hover,
        &.k-state-active {
            border-color: $panel-border;
        }
    }


    // Insert table
    .k-ct-popup {
        .k-state-selected {
            @include fill( $selected-text, $selected-bg, $selected-border, none );
        }
    }

    // Content
    .k-editor-content {

        // Selection
        .k-text-selected,
        &::selection {
            @include fill (
                $color: $editor-selected-text,
                $bg: $editor-selected-bg
            );
        }

        .k-text-highlighted {
            @include fill (
                $bg: $editor-highlighted-bg
            );
        }
    }

    // Find and replace dialog
    .k-editor-find-replace {
        .k-matches-container {
            @include fill (
                $bg: $editor-find-line-bg
            );
        }

    }

}
