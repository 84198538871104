@include exports("maskedtextbox/layout") {

    .k-maskedtextbox {
        width: $input-default-width;
        font-family: $input-font-family;
        font-size: $input-font-size;
        line-height: $input-line-height;
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        background: none;
        text-align: left;
        white-space: nowrap;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        > .k-textbox {
            flex: 1 0 0%;
            min-width: 0;

            &.k-state-invalid {
                transition: none;

                + .k-i-warning {
                    display: inline-block;
                }
            }
        }

        .k-i-warning {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: $padding-x;
        }

        &[dir="rtl"],
        .k-rtl & {
            text-align: right;

            .k-i-warning {
                right: auto;
                left: $padding-x;
            }
        }

        ::-ms-clear {
            display: none;
        }
    }
}
