@include exports("adaptive/theme") {

    .k-pane-wrapper,
    .k-view {
        @extend .k-text-selection !optional;
        @include fill(
            $adaptive-text,
            $adaptive-bg,
            $adaptive-border
        );
    }

    .k-pane-wrapper .k-pane {

        > .k-view > .k-content {
            background-position: 50% 50%;
            background-color: $adaptive-content-bg;
        }

        .k-grid-mobile {

            .k-column-active + th.k-header {
                border-left-color: $body-text;
            }

            .k-resize-handle-inner::before {
                background-color: $base-bg;
            }
        }

        .k-scheduler-mobile {

            .k-nav-current {
                color: $adaptive-scheduler-current-text;
            }

            .k-scheduler-times,
            .k-scheduler-header,
            .k-scheduler-groupcolumn,
            .k-mobile-scheduler-agendadate {
                color: $adaptive-scheduler-base-text;
            }

            .k-time-period,
            .k-mobile-scheduler-agendaweekday {
                color: lighten($base-text, 33%);
            }

            .k-scheduler-times,
            .k-scheduler-header,
            .k-scheduler-header-wrap,
            .k-scheduler-datecolumn,
            .k-scheduler-groupcolumn,
            .k-scheduler-group-cell,
            .k-scheduler-header-all-day {
                background-color: $adaptive-content-bg;
            }

            .k-scheduler-toolbar .k-scheduler-tools > li {
                display: inline-block;
                background-color: inherit;
            }
        }

        .k-grid-edit-form,
        .k-grid-column-menu,
        .k-grid-filter-menu,
        .k-scheduler-edit-form {

            .k-header {
                color: $adaptive-menu-text;
                background-color: $adaptive-menu-bg;

                .k-link {
                    color: inherit;
                }
            }

            .k-item,
            .k-link {
                color: $base-text;
            }

            .k-clear,
            .k-select-all,
            .k-scheduler-delete,
            .k-scheduler-resetSeries { // sass-lint:disable-line class-name-format
                color: $adaptive-menu-clear-text;
            }

        }

        .k-column-menu {

            .k-listgroup-item.k-state-selected {
                color: $adaptive-grid-sort-text;
                background: none;
            }
            .k-listgroup-item.k-state-selected .k-link {
                color: inherit;
            }
        }

    }
}
