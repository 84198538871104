@include exports("pdf-viewer/theme") {


    .k-pdf-viewer {
        @extend .k-text-selection !optional;
        @include fill(
            $pdf-viewer-text,
            $pdf-viewer-bg,
            $pdf-viewer-border
        );


        // Toolbar
        .k-toolbar {
            @include fill(
                $pdf-viewer-toolbar-text,
                $pdf-viewer-toolbar-bg,
                $pdf-viewer-toolbar-border,
                $pdf-viewer-toolbar-gradient
            );
        }


        // Canvas
        .k-canvas {
            @include fill(
                $pdf-viewer-canvas-text,
                $pdf-viewer-canvas-bg,
                $pdf-viewer-canvas-border
            );
        }


        // Page
        .k-page {
            @include fill(
                $pdf-viewer-page-text,
                $pdf-viewer-page-bg,
                $pdf-viewer-page-border
            );
            @include box-shadow( $pdf-viewer-page-shadow );
        }


        // Search
        .k-search-highlight {
            background-color: $pdf-viewer-search-highlight-bg;
        }

    }

}
