// Window
$window-border-width: 0px !default;
$window-border-radius: 0px !default;
$window-font-family: $font-family !default;
$window-font-size: $font-size !default;
$window-line-height: 1.25 !default;

$window-titlebar-padding-x: 16px !default;
$window-titlebar-padding-y: 12px !default;
$window-titlebar-border-width: 0 0 1px !default;
$window-titlebar-border-style: solid !default;

$window-title-font-size: $font-size-lg !default;
$window-title-line-height: ( 20 / 18 ) !default;

$window-actions-gap: null !default;

$window-action-opacity: null !default;
$window-action-hover-opacity: null !default;

$window-inner-padding-x: 16px !default;
$window-inner-padding-y: 16px !default;

$window-bg: $component-bg !default;
$window-text: $component-text !default;
$window-border: $component-border !default;

$window-titlebar-bg: $header-bg !default;
$window-titlebar-text: $header-text !default;
$window-titlebar-border: inherit !default;

$window-shadow: 0 3px 3px 0 rgba(0, 0, 0, .06) !default;
$window-focused-shadow: 1px 1px 7px 1px rgba(0, 0, 0, .3) !default;

$window-sizes: () !default;
$window-sizes: map-merge((
        sm: 300px,
        md: 800px,
        lg: 1200px
), $window-sizes);
