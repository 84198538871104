@include exports("window/layout") {

    .k-window {
        @include use-background-clip();
        @include border-radius( $window-border-radius );
        padding: 0;
        border-width: $window-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $window-font-family;
        font-size: $window-font-size;
        line-height: $window-line-height;
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        z-index: 10002;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // overlay during dragging
        //.k-overlay {
        //    position: absolute;
        //    opacity: 0;
        //}
    }
    .k-window.k-window-maximized {
        max-width: 100vw;
        max-height: 100vh;
        box-shadow: none;
    }


    // Window sizes
    @each $size, $width in $window-sizes {
        .k-window-#{$size} { width: $width; }
    }


    // Title bar
    .k-window-titlebar {
        @include use-background-clip();
        @include border-top-radius( $window-border-radius );
        padding: $window-titlebar-padding-y $window-titlebar-padding-x;
        border-width: $window-titlebar-border-width;
        border-style: $window-titlebar-border-style;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;

        .k-window-minimized & {
            border-width: 0;
        }
    }

    // Title bar title text
    .k-window-title {
        margin: 0;
        font-size: $window-title-font-size;
        line-height: $window-title-line-height;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &::before {
            content: "\200b";
        }
    }


    // Actions
    .k-window-actions {
        margin: -5em 0;
        margin-inline-end: calc( #{$window-titlebar-padding-y} - #{$window-titlebar-padding-x} );
        line-height: 1;
        display: flex;
        gap: $window-actions-gap;
        flex-flow: row nowrap;
        flex-shrink: 0;
        align-items: center;
        vertical-align: top;
    }
    .k-window-action {
        flex-shrink: 0;
        opacity: $window-action-opacity;
    }
    .k-window-action:hover {
        opacity: $window-action-hover-opacity;
    }


    // Content
    .k-window-content,
    .k-prompt-container {
        padding: $window-inner-padding-y $window-inner-padding-x;
        border-width: 0;
        border-color: inherit;
        color: inherit;
        background: none;
        outline: 0;
        overflow: auto;
        position: relative;
        flex: 1 1 auto;
    }
    .k-window-content + .k-prompt-container {
        margin-top: -($window-inner-padding-y / 2);
    }
    .k-window-content:first-child {
        padding-top: #{"max( #{$window-inner-padding-y}, #{$window-titlebar-padding-y} )"};
    }
    .k-window-content:last-child {
        padding-bottom: #{"max( #{$window-inner-padding-y}, #{$window-titlebar-padding-y} )"};
    }

    .k-window-iframecontent {
        padding: 0;
        overflow: visible;

        .k-content-frame {
            vertical-align: top;
            border: 0;
            width: 100%;
            height: 100%;
        }
    }


    // Prompt
    .k-prompt-container {

        > .k-textbox,
        > .k-textarea {
            width: 100%;
        }

    }
}
