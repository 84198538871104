@include exports("card/theme") {

    // Card
    .k-card {
        @extend .k-text-selection !optional;
        @include fill(
            $card-text,
            $card-bg,
            $card-border
        );
        @include box-shadow( $card-shadow );

        &:focus,
        &.k-state-focus {
            @include fill(
                $card-focus-text,
                $card-focus-bg,
                $card-focus-border
            );
            @include box-shadow( $card-focus-shadow );
        }
    }


    // Card actions
    .k-card-actions {
        border-color: $card-border;
    }


    // Header
    .k-card-header {
        @include fill( $card-header-text, $card-header-bg, $card-header-border );
    }


    // Footer
    .k-card-footer {
        @include fill( $card-footer-text, $card-footer-bg, $card-footer-border );
    }


    // Separator
    .k-card-separator {
        border-color: $card-border;
    }


    // TODO: refactor
    .k-card-subtitle {
        color: $card-subtitle-text;
    }


    // Card theme colors
    @each $name, $color in $theme-colors {
        .k-card-#{$name} {
            background-color: tint($color, 10);
            color: shade($color, 6);
            border-color: tint($color, 9);

            .k-card-subtitle {
                color: inherit;
            }
        }
    }


    .k-card-wrap {
        &.k-state-focused,
        &.k-state-selected {
            > .k-card {
                @include box-shadow( $card-focus-shadow );
            }
        }
    }


    // Card callout
    .k-card-callout {
        @include fill(
            $bg: $card-bg,
            $border: $card-border
        );
    }

    .k-card-callout.k-callout-n { @include box-shadow( $card-callout-box-shadow-n ); }
    .k-card-callout.k-callout-e { @include box-shadow( $card-callout-box-shadow-e ); }
    .k-card-callout.k-callout-s { @include box-shadow( $card-callout-box-shadow-s ); }
    .k-card-callout.k-callout-w { @include box-shadow( $card-callout-box-shadow-w ); }

    .k-card:focus .k-card-callout,
    .k-card.k-state-focus .k-card-callout {
        @include fill(
            $bg: $card-focus-bg,
            $border: $card-focus-border
        );

        &.k-callout-n { @include box-shadow( $card-focus-callout-box-shadow-n ); }
        &.k-callout-e { @include box-shadow( $card-focus-callout-box-shadow-e ); }
        &.k-callout-s { @include box-shadow( $card-focus-callout-box-shadow-s ); }
        &.k-callout-w { @include box-shadow( $card-focus-callout-box-shadow-w ); }
    }

}
