@include exports( "utils/spacer" ) {

    // flexible space
    .k-spacer {
        flex: 1 0 auto;
    }

    .k-spacer-sized {
        flex-grow: 0;
    }
}
