// Grid
$grid-padding-x: $cell-padding-x !default;
$grid-padding-y: $cell-padding-y !default;

$grid-header-padding-x: $grid-padding-x !default;
$grid-header-padding-y: $grid-padding-y !default;
$grid-header-font-size: null !default;

$grid-footer-padding-x: $grid-header-padding-x !default;
$grid-footer-padding-y: $grid-header-padding-y !default;

$grid-grouping-header-padding-x: $grid-padding-y !default;
$grid-grouping-header-padding-y: $grid-grouping-header-padding-x !default;

$grid-filter-cell-padding-x: $grid-padding-y !default;
$grid-filter-cell-padding-y: $grid-filter-cell-padding-x !default;

$grid-toolbar-padding-x: $toolbar-padding-x !default;
$grid-toolbar-padding-y: $toolbar-padding-y !default;
$grid-toolbar-spacing: $toolbar-spacing !default;

$grid-cell-padding-x: $grid-padding-x !default;
$grid-cell-padding-y: $grid-padding-y !default;
$grid-cell-horizontal-border-width: 0px !default;
$grid-cell-vertical-border-width: 1px !default;

$grid-cell-placeholder-line-width: 80% !default;

$grid-border-width: 1px !default;
$grid-font-family: $font-family !default;
$grid-font-size: $font-size !default;
$grid-line-height: $line-height !default;

/// Background color of the grid component
/// @group grid
$grid-bg: $component-bg !default;
/// Text color of the grid component
/// @group grid
$grid-text: $component-text !default;
/// Border color of the grid component
/// @group grid
$grid-border: $component-border !default;

/// Background color of grid header
/// @group grid
$grid-header-bg: $header-bg !default;
/// Text color of grid header
/// @group grid
$grid-header-text: $header-text !default;
/// Border color of grid header
/// @group grid
$grid-header-border: $grid-border !default;
/// Background gradient of grid header
/// @group grid
$grid-header-gradient: $header-gradient !default;

/// Background color of grid footer
/// @group grid
$grid-footer-bg: $grid-header-bg !default;
/// Text color of grid footer
/// @group grid
$grid-footer-text: $grid-header-text !default;
/// Border color of grid footer
/// @group grid
$grid-footer-border: $grid-header-border !default;

$grid-group-drag-clue-bg: $grid-header-bg !default;
$grid-group-drag-clue-text: $grid-header-text !default;
$grid-group-drag-clue-line-height: $line-height !default;

/// Background color of alternating rows in grid
/// @group grid
$grid-alt-bg: rgba( contrast-wcag( $grid-bg ), .04 ) !default;
/// Text color of alternating rows in grid
/// @group grid
$grid-alt-text: null !default;
/// Border color of alternating rows in grid
/// @group grid
$grid-alt-border: null !default;

/// Background color of hovered rows in grid
/// @group grid
$grid-hovered-bg: darken($grid-bg, 7%) !default;
/// Text color of hovered rows in grid
/// @group grid
$grid-hovered-text: null !default;
/// Border color of hovered rows in grid
/// @group grid
$grid-hovered-border: null !default;

/// Background color of selected rows in grid
/// @group grid
$grid-selected-bg: rgba($selected-bg, .25) !default;
/// Text color of selected rows in grid
/// @group grid
$grid-selected-text: null !default;
/// Border color of selected rows in grid
/// @group grid
$grid-selected-border: null !default;

$grid-selected-alt-bg: shade($grid-selected-bg, .4) !default;
$grid-selected-hovered-bg: shade($grid-selected-bg, .7) !default;

$grid-grouping-row-bg: darken($grid-bg, 7%) !default;
$grid-grouping-row-text: $grid-text !default;

$grid-sorted-bg: rgba( contrast-wcag( $grid-bg ), .02 ) !default;
$grid-sorting-indicator-text: $primary !default;
$grid-sorting-index-font-size: $font-size-sm !default;
$grid-sorting-index-height: $icon-size !default;
$grid-sorting-index-spacing-y: ($icon-spacing / 2) !default;
$grid-sorting-index-spacing-x: -$grid-sorting-index-spacing-y !default;


$grid-focused-shadow: $list-item-focused-shadow !default;

$grid-command-cell-button-spacing: .16em !default;

$grid-search-width: $input-default-width !default;

$grid-sticky-bg: $component-bg !default;
$grid-sticky-text: $grid-text !default;
$grid-sticky-border: rgba( black, .3 ) !default;

$grid-sticky-alt-bg: shade($grid-bg, 3.5%) !default;

$grid-sticky-header-bg: $grid-header-bg !default;
$grid-sticky-header-text: $grid-header-text !default;
$grid-sticky-header-border: $grid-sticky-border !default;

$grid-sticky-footer-bg: $grid-header-bg !default;
$grid-sticky-footer-hovered-bg: $grid-hovered-bg !default;

$grid-sticky-selected-bg: mix($selected-bg, #ffffff, 25%) !default;
$grid-sticky-selected-alt-bg: shade($grid-sticky-selected-bg, .4) !default;

$grid-sticky-hovered-bg: darken($grid-bg, 8%) !default;
$grid-sticky-selected-hovered-bg: shade($grid-sticky-selected-bg, .7) !default;

$grid-filter-menu-item-spacing-x: 0 !default;
$grid-filter-menu-item-spacing-y: map-get( $spacing, 2 ) !default;

$grid-column-menu-popup-padding-x: 0 !default;
$grid-column-menu-popup-padding-y: $padding-y-sm !default;

$grid-column-menu-item-padding-x: $padding-x !default;
$grid-column-menu-item-padding-y: $padding-y !default;

$grid-column-menu-list-item-padding-x: $padding-x !default;
$grid-column-menu-list-item-padding-y: $padding-y-sm !default;

$grid-column-menu-items-wrap-padding-x: 0 !default;
$grid-column-menu-items-wrap-padding-y: $padding-y-lg !default;

$grid-column-menu-filter-container-padding-x: $padding-x !default;
$grid-column-menu-filter-container-padding-y: $padding-y !default;

$grid-column-menu-group-header-padding-x: $menu-popup-item-padding-x !default;
$grid-column-menu-group-header-padding-y: $menu-popup-item-padding-y !default;

$grid-column-menu-group-header-font-size: $font-size-sm !default;
$grid-column-menu-group-header-line-height: $line-height !default;
$grid-column-menu-group-header-text-transform: uppercase !default;
$grid-column-menu-group-header-border-bottom-width: 1px !default;

$grid-column-menu-group-header-bg: null !default;
$grid-column-menu-group-header-text: darken($base-text, 13%) !default;
$grid-column-menu-group-header-border: $grid-column-menu-group-header-text !default;
