@include exports("bottom-navigation/theme") {

    // Solid
    @each $name, $color in $theme-colors {
        .k-bottom-nav-solid.k-bottom-nav-#{$name} {
            @include fill(
                $color: true-mix( $color, contrast-wcag( $color ), 35%),
                $bg: $color
            );

            .k-bottom-nav-item.k-state-focus,
            .k-bottom-nav-item.k-state-focused,
            .k-bottom-nav-item:focus {
                @include fill( $bg: rgba(true-mix( $color, contrast-wcag( $color ), 35%), .2));
            }

            .k-bottom-nav-item.k-state-selected,
            .k-bottom-nav-item.k-state-active,
            .k-bottom-nav-item:active {
                @include fill( $color: contrast-wcag( $color ) );
            }
        }
    }

    // Flat
    .k-bottom-nav-flat {
        @include fill(
            $bottom-nav-flat-text,
            $bottom-nav-flat-bg,
            $bottom-nav-flat-border
        );

        .k-bottom-nav-item.k-state-focus,
        .k-bottom-nav-item.k-state-focused,
        .k-bottom-nav-item:focus {
            @include fill( $bg: rgba($bottom-nav-flat-text, .05) );
        }

        @each $name, $color in $theme-colors {
            &.k-bottom-nav-#{$name} .k-bottom-nav-item.k-state-selected,
            &.k-bottom-nav-#{$name} .k-bottom-nav-item.k-state-active,
            &.k-bottom-nav-#{$name} .k-bottom-nav-item:active {
                @if $name == "secondary" or $name == "light" {
                    @include fill( $color: try-shade($color, 3) );
                } @else {
                    @include fill( $color: $color );
                }
            }
        }
    }

}

@include exports( "bottom-navigation/theme/legacy" ) {

    .k-ie .k-bottom-nav-item {
        background-color: transparent;
    }

}
