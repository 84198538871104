// TileLayout
$tilelayout-border-width: 0px !default;
$tilelayout-card-border-width: $card-border-width !default;
$tilelayout-card-focus-shadow: $card-focus-shadow !default;

$tilelayout-hint-border-width: 1px !default;
$tilelayout-hint-border-radius: $border-radius-lg !default;

$tilelayout-bg: $base-bg !default;

$tilelayout-hint-bg: rgba(255, 255, 255, .2) !default;
$tilelayout-hint-border: $component-border !default;
