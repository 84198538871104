@include exports("dropdownlist/layout") {

    .k-dropdown {
        width: $input-default-width;
        border-width: 0;
        outline: 0;
        background: none;
        box-sizing: border-box;
        font-family: $input-font-family;
        font-size: $input-font-size;
        line-height: $input-line-height;
        text-align: left;
        white-space: nowrap;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-dropdown-wrap {
            @include use-background-clip();
            @include border-radius( $input-border-radius );
            padding: 0;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            position: relative;
            transition: all .1s ease; // sass-lint:disable-line no-transition-all
            cursor: pointer;
            outline: 0;


            // Input
            .k-input {}


            // Select
            .k-select {
                @include border-right-radius-only( $input-border-radius );
                padding: $picker-select-padding-y $picker-select-padding-x;
                width: if($use-picker-select-width, $button-inner-calc-size, null);
                border-width: 0;
                box-sizing: border-box;
                border-style: solid;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                text-align: center;
                cursor: pointer;
            }

            // Clear value
            .k-clear-value {
                opacity: .5;
                visibility: hidden;

                &:hover {
                    opacity: 1;
                }
            }


            // Focused
            &.k-state-focused,
            &.k-state-hover,
            &:hover {
                .k-clear-value {
                    visibility: visible;
                }
            }
        }

    }


    // RTL

    .k-dropdown.k-rtl,
    .k-dropdown[dir="rtl"],
    .k-rtl .k-dropdown,
    [dir="rtl"] .k-dropdown {
        text-align: right;

        .k-select {
            @include border-left-radius-only( $input-border-radius );
        }
    }



    // Dropdown operator
    .k-dropdown-operator {
        width: auto;

        .k-dropdown-wrap {
            .k-input {
                display: none;
            }
            .k-select {
                width: $button-inner-calc-size;
                height: $button-inner-calc-size;
            }
        }

    }


    // Native select
    select.k-dropdown {
        @include border-radius( $input-border-radius );
        padding: $input-padding-y $input-padding-x;
        border-width: 1px;
        border-style: solid;
        outline: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all .1s ease; // sass-lint:disable-block no-transition-all
        cursor: pointer;
    }

}
