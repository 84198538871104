// TODO: modal variables
@include exports("scheduler/layout") {

    // Layout
    .k-scheduler {
        @include use-background-clip();
        border-width: $scheduler-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $scheduler-font-family;
        font-size: $scheduler-font-size;
        line-height: $scheduler-line-height;
        display: flex;
        flex-direction: column;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        table,
        thead,
        tfoot,
        tbody,
        tr,
        th,
        td,
        div,
        > * {
            border-color: inherit;
        }
    }

    kendo-scheduler.k-scheduler {
        overflow: hidden;

        &.k-readonly-scheduler .k-event-delete {
            display: none;
        }
    }

    // Commmon
    .k-scheduler-table {
        width: 100%;
        max-width: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;

        td,
        th {
            padding: $cell-padding;
            height: $scheduler-cell-height;
            overflow: hidden;
            white-space: nowrap;
            border-style: solid;
            border-width: 0 0 1px 1px;
            vertical-align: top;
            box-sizing: content-box;
        }
        td:first-child,
        th:first-child {
            border-left-width: 0;
        }
        .k-middle-row td {
            border-bottom-style: dotted;
        }

        .k-link {
            cursor: pointer;
        }
    }

    .k-scheduler-layout-flex {
        overflow: auto;

        .k-scheduler-head {
            position: sticky;
            top: 0;
            z-index: 3;
        }
        .k-scheduler-body {
            position: relative;
        }

        .k-scheduler-head,
        .k-scheduler-body {
            display: flex;
            flex: 100%;
            flex-wrap: wrap;
        }

        .k-scheduler-row {
            display: flex;
            flex: 1 1 100%;
            width: 100%;
            min-width: 0;
        }

        .k-scheduler-group {
            display: flex;
            flex: 1 1 100%;
            width: 100%;
            min-width: 0;
            flex-wrap: wrap;

            .k-group-cell {
                display: flex;
                flex: 1 1 auto;
                flex-wrap: wrap;
                overflow: auto;
            }

            .k-group-content {
                padding: 0;
                display: flex;
                flex: 1 1 100%;
                width: 100%;
                border-width: 0;
                flex-wrap: wrap;
            }

            &.k-group-horizontal {
                .k-group-cell {
                    flex-basis: 100%;
                    border-width: 0;
                    padding: 0;
                }
            }
        }



        .k-more-events {
            bottom: 2px;
            left: 0;
            width: 100%;
        }

        .k-scheduler-cell {
            display: flex;
            flex: 1 1 100%;
            padding: $cell-padding;
            min-height: 1.5em;
            overflow: hidden;
            white-space: nowrap;
            border-style: solid;
            border-width: 0 1px 1px 0;
            vertical-align: top;
            box-sizing: content-box;

            &.k-slot-cell {
                position: relative;
            }

            &.k-heading-cell {
                justify-content: center;
                font-weight: bold;
            }

            &.k-side-cell {
                justify-content: flex-end;
                flex-grow: 0;
                flex-basis: auto;
                overflow: visible;
            }

            &.k-major-cell {
                border-bottom-width: 0;
            }
        }

        .k-middle-row .k-scheduler-cell {
            border-bottom-style: dotted;
        }

        .k-resource-cell {
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
        }

        .k-resource-content {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            border-width: 0;
        }

        .k-sticky-cell {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 0;
            position: sticky;
            left: 0;
            z-index: 3;
        }
    }


    // Header and footer
    .k-scheduler-toolbar {
        border-width: 0;
        border-bottom-width: $toolbar-border-width;
        white-space: nowrap;
        flex-shrink: 0;
        position: relative;
        z-index: 2;

        .k-widget {
            font-size: inherit;
        }

        // Current date
        .k-nav-current {
            color: inherit;
            text-decoration: none;
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            cursor: pointer;

            .k-icon {
                margin-right: $icon-spacing;
            }

            .k-sm-date-format,
            .k-lg-date-format {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .k-sm-date-format { display: none; }
        }


        // Scheduler search
        .k-scheduler-search {
            display: inline-flex;
            flex-flow: row nowrap;
        }


        // Scheduler views
        .k-scheduler-views-wrapper {
            margin-left: auto;

            .k-views-dropdown {
                width: auto;
                font: inherit;
                display: none;
            }
        }


        // Responsive scheduler toolbar
        @media (max-width: 1400px) {
            .k-nav-current .k-lg-date-format { max-width: 200px; }
        }

        @media (max-width: 1024px) {

            .k-nav-current .k-lg-date-format { display: none; }
            .k-nav-current .k-sm-date-format { display: inline-block; }

            .k-scheduler-views-wrapper .k-scheduler-views { display: none; }
            .k-scheduler-views-wrapper .k-views-dropdown { display: inline-block; }
        }
    }
    kendo-scheduler .k-scheduler-toolbar {
        @include use-background-clip();
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-style: solid;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }


    // Scheduler navigation
    ul.k-scheduler-navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        li {
            @include use-background-clip();
            border-width: $button-border-width;
            border-style: solid;
            position: relative;
            z-index: 1;
        }
        li + li {
            margin-left: -$button-border-width;
        }
        .k-state-hover,
        .k-state-selected {
            z-index: 2;
        }


        .k-link {
            padding: $button-padding-y $button-padding-x;
            box-sizing: border-box;
            color: inherit;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
        }


        // Prev / next
        .k-nav-prev,
        .k-nav-next {
            .k-link {
                padding: $button-padding-y;
                width: $button-inner-calc-size;
                height: $button-inner-calc-size;
            }
        }


        // Exception
        .k-nav-current {
            border: 0;
            margin-left: 0;
        }
        .k-nav-today { @include border-left-radius-only( $border-radius ); }
        .k-nav-next { @include border-right-radius-only( $border-radius ); }
    }


    // View switcher
    ul.k-scheduler-views {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;

        li {
            @include use-background-clip();
            border-width: $button-border-width;
            border-style: solid;
            position: relative;
            z-index: 1;
        }
        li + li {
            margin-left: -$button-border-width;
        }
        .k-state-hover,
        .k-state-selected {
            z-index: 2;
        }


        .k-link {
            padding: $button-padding-y $button-padding-x;
            box-sizing: border-box;
            color: inherit;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        li:first-child { @include border-radius( $border-radius ); }
        li:first-child + li { @include border-left-radius-only( $border-radius ); }
        li:last-child { @include border-right-radius-only( $border-radius ); }

        &.k-state-expanded {
            li {
                @include border-radius( 0 );
                margin: 0;
            }
            li + li { margin-top: -1px; }
            li:first-child { @include border-top-radius-only( $border-radius ); }
            li:first-child + li { @include border-radius( 0 ); }
            li:last-child { @include border-bottom-radius-only( $border-radius ); }
        }

        .k-current-view { display: none; }
        .k-current-view .k-link::after {
            content: "";
            margin: 0 0 0 1ex;
            border: .25em solid transparent;
            border-top: .5em solid currentColor;
            border-bottom-width: 0;
            display: inline-block;
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: stretch;
            position: absolute;
            right: $toolbar-padding-x;
            top: $toolbar-padding-y;
            z-index: 1000;

            li { display: none; }
            .k-current-view { display: block; }


            &.k-state-expanded {
                li { display: block; }
            }

            .k-rtl & {
                left: $toolbar-padding-x;
                right: auto;
            }
        }
    }


    // Scheduler footer
    .k-scheduler-footer {
        @include box-shadow( none );
        border-width: 0;
        border-top-width: $toolbar-border-width;
        white-space: nowrap;
        flex-shrink: 0;
        position: relative;

        .k-widget {
            font-size: inherit;
        }

        li {
            @include use-background-clip();
            border-width: $button-border-width;
            border-style: solid;
            position: relative;
            z-index: 1;
        }
        li + li {
            margin-left: -$button-border-width;
        }
        .k-state-hover,
        .k-state-selected {
            z-index: 2;
        }


        .k-link {
            padding: $button-padding-y $button-padding-x;
            box-sizing: border-box;
            color: inherit;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        li {
            @include border-radius( $border-radius );
        }
    }
    kendo-scheduler .k-scheduler-footer {
        @include use-background-clip();
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-style: solid;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .k-scheduler-fullday {
        .k-icon {
            margin-right: $icon-spacing;
        }
    }


    // Layout
    .k-scheduler-layout {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        flex: 1 1 auto;
        position: relative;
        z-index: 1;

        > tbody > tr > td {
            padding: 0;
            vertical-align: top;
        }

        // Remove bottom border from first cell of hours cells spaning on two rows
        tr + tr,
        .k-scheduler-pane + .k-scheduler-pane {
            .k-scheduler-times tr {
                th {
                    border-bottom-color: transparent;
                }

                .k-slot-cell,
                .k-scheduler-times-all-day,
                &:last-child th {
                    border-bottom-color: inherit;
                }
            }
        }
    }
    .k-scheduler-layout.k-scheduler-flex-layout {
        display: flex;
        flex-direction: column;

        &.k-scheduler-weekview,
        &.k-scheduler-dayview {
            .k-scheduler-pane:first-child .k-scheduler-table {
                table-layout: fixed;
            }
        }
    }

    .k-scheduler-header th {
        text-align: center;
    }

    .k-scheduler-header,
    .k-scheduler-header-wrap,
    .k-scheduler-header-wrap > div {
        border-color: inherit;
        overflow: hidden;
    }
    .k-scheduler-header-wrap {
        border-width:  0;
        border-style: solid;
        position: relative;
    }
    .k-scheduler-times {
        border-color: inherit;
        position: relative;
        overflow: hidden;

        .k-scheduler-table { table-layout: auto; }

        th {
            border-width: 0 1px 1px 0;
            text-align: right;
        }

        .k-slot-cell,
        .k-scheduler-times-all-day {
            border-bottom-color: inherit;
        }

        .k-slot-cell.k-last-resource {
            border-right: 0;
        }
        .k-slot-cell.k-empty-slot {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .k-scheduler-datecolumn {
        width: $scheduler-datecolumn-width;
    }
    .k-scheduler-timecolumn {
        width: $scheduler-timecolumn-width;
        white-space: nowrap;
    }


    // Scheduler content
    .k-scheduler-content {
        border-color: inherit;
        position: relative;
        overflow: auto;
    }


    // Appointments
    kendo-scheduler .k-event,
    .k-event {
        @include border-radius( $border-radius );
        min-height: $scheduler-event-min-height;
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-radius: $scheduler-event-border-radius;
        text-align: left;
        outline: 0;
        cursor: default;
        position: absolute;
        overflow: hidden;
        padding-right: $padding-x;

        > div {
            position: relative;
            z-index: 2;
        }

        // Template
        .k-event-template {
            line-height: $scheduler-event-line-height;
            padding: $padding-y $padding-x;
            // TODO: incompatible units
            // padding-right: $padding-x-sm + $spacer;
        }
        .k-event-time {
            padding-bottom: 0;
            font-size: .875em;
            white-space: nowrap;
            display: none;
        }


        // Actions
        .k-event-actions {
            white-space: nowrap;
            position: absolute;
            top: 0;
            bottom: 0;
            right: $padding-x;
            z-index: 2;

            .k-event-delete {
                opacity: .5;
                visibility: hidden;
            }

            &:hover,
            &.k-state-hover {
                .k-event-delete {
                    opacity: 1;
                }
            }

            .k-icon {
                line-height: normal;
                font-size: inherit;
            }

            a { color: inherit; }
        }


        // Indicators
        .k-event-actions:first-child {
            margin: $padding-y-sm .4ex 0 $padding-x-sm;
            top: 0;
            right: 0;
            float: left;
            position: relative;
            opacity: 1;
            visibility: visible;
            line-height: normal;
        }


        // Resize handles
        .k-resize-handle {
            z-index: 4;
            opacity: .5;
            visibility: hidden;
        }
        .k-resize-handle::before { border-color: currentColor; }
        .k-resize-n {
            height: .5em;
            top: 0;
        }
        .k-resize-s {
            height: .5em;
            bottom: 0;
        }
        .k-resize-n::before,
        .k-resize-s::before {
            width: $spacer * 2;
            border-bottom-width: 1px;
        }
        .k-resize-w {
            width: .5em;
            left: 0;
        }
        .k-resize-e {
            width: .5em;
            right: 0;
        }
        .k-resize-w::before,
        .k-resize-e::before {
            height: $spacer * 2;
            border-left-width: 1px;
        }


        // Hover
        &:hover,
        &.k-state-hover {
            .k-event-actions .k-event-delete,
            .k-resize-handle {
                visibility: visible;
            }
        }

        &.k-event-drag-hint .k-event-time {
            display: block;
        }

        .k-event-top-actions,
        .k-event-bottom-actions {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
        }

        .k-event-bottom-actions {
            top: auto;
            bottom: 0;
        }
    }


    // Tasks
    .k-scheduler-mark {
        width: 1em;
        height: 1em;
        display: inline-block;
        vertical-align: middle;
    }


    // More events indicator
    .k-more-events {
        padding: 0;
        height: 13px;
        border-style: solid;
        border-width: 1px;
        overflow: hidden;
        position: absolute;
        justify-content: center;

        > .k-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    // Current time
    .k-current-time {
        position: absolute;

        &.k-current-time-arrow-left,
        &.k-current-time-arrow-right,
        &.k-current-time-arrow-down {
            width: 0;
            height: 0;
            background: transparent;
            border: 4px solid transparent;
        }
    }

    // Draging hint
    .k-event-drag-hint {
        opacity: .5;
        z-index: 3;

        .k-event-actions,
        .k-event-top-actions,
        .k-event-bottom-actions,
        .k-resize-handle {
            display: none;
        }

        .k-event-time {
            display: block;
        }
    }


    // Resizing
    .k-scheduler-marquee {
        border-width: 0;
        border-style: solid;

        // label
        .k-label-top,
        .k-label-bottom {
            font-size: .75em;
            position: absolute;
        }
        .k-label-top {
            left: $padding-x-sm;
            top: $padding-y-sm;
        }
        .k-label-bottom {
            right: $padding-x-sm;
            bottom: $padding-y-sm;
        }

        // Angle tingie
        &.k-first::before,
        &.k-last::after {
            content: "";
            border-width: 3px;
            border-style: solid;
            position: absolute;
            width: 0;
            height: 0;
        }
        &.k-first::before {
            top: 0;
            left: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
        &.k-last::after {
            bottom: 0;
            right: 0;
            border-left-color: transparent;
            border-top-color: transparent;
        }
    }

    // sass-lint:disable no-important
    // remove scrollbars during PDF export
    .k-pdf-export-shadow .k-scheduler,
    .k-scheduler-pdf-export .k-scheduler-content,
    .k-scheduler-pdf-export .k-scheduler-times {
        height: auto !important;
        overflow: visible !important;
    }

    .k-scheduler-pdf-export {
        overflow: hidden;

        .k-scheduler-header {
            padding: 0 !important;
        }

        .k-scheduler-header-wrap {
            border-width: 0 !important;
        }

        .k-scheduler-header .k-scheduler-table,
        .k-scheduler-content .k-scheduler-table {
            width: 100% !important;
        }
    }
    // sass-lint:enable no-important


    // TODO: add this class to jQuery rendering
    // after refactoring jQuery scheduler layout to be flex
    .k-recurrence-editor {
        display: inline-flex;
        flex-direction: column;
    }
    kendo-scheduler .k-recurrence-editor {
        display: block;
    }
}




@include exports("scheduler-views/layout") {

    // Day view
    .k-scheduler-dayview {}


    // Workweek view
    // sass-lint:disable class-name-format
    .k-scheduler-workWeekview {}
    // sass-lint:enable class-name-format


    // Week view
    .k-scheduler-weekview {}


    // Month view
    .k-scheduler-monthview {

        .k-scheduler-table {
            height: 100%;
        }
        .k-scheduler-table td {
            height: 80px;
            text-align: right;
        }
        .k-scheduler-body .k-scheduler-cell {
            min-height: 80px;
        }

        // sass-lint:disable no-important
        // hidden cells must have no layout
        .k-hidden {
            padding-left: 0 !important;
            padding-right: 0 !important;
            border-right-width: 0 !important;
        }
        // sass-lint:enable no-important

        .k-scheduler-table-auto,
        .k-scheduler-table-auto td,
        .k-scheduler-content .k-scheduler-table-auto {
            height: auto;
        }

        // Force vertical scroll in monthview for jquery
        .k-scheduler-content {
            overflow-y: scroll;
        }
        &.k-scheduler-flex-layout .k-scheduler-content {
            overflow-y: auto;
        }
    }


    // Agenda view
    .k-scheduler-agendaview {

        .k-scheduler-mark {
            margin-right: .5em;
            width: 1em;
            height: 1em;
            display: inline-block;
            vertical-align: middle;
        }

        .k-scheduler-table {
            th:first-child,
            td:first-child {
                border-left-width: 1px;
            }

            td.k-first {
                border-left-width: 0;
            }
        }

        .k-task > .k-event-delete {
            color: inherit;
            position: absolute;
            top: $padding-y-sm;
            right: $padding-y-sm;
            opacity: .5;
            visibility: hidden;
        }
        .k-state-hover .k-task > .k-event-delete,
        .k-scheduler-content tr:hover .k-event-delete,
        .k-scheduler-content .k-scheduler-row:hover .k-event-delete {
            visibility: visible;
        }
    }
    .k-scheduler-agendaday {
        margin: 0 .2em 0 0;
        font-size: 3em;
        line-height: 1;
        font-weight: 400;
        float: left;
    }
    .k-scheduler-agendaweek {
        display: block;
        margin: .4em 0 0;
        line-height: 1;
        font-style: normal;
    }
    .k-scheduler-agendadate {
        font-size: .75em;
    }


    // Timeline view
    .k-scheduler-timelineview {
        .k-slot-cell {
            overflow: hidden;
        }
        .k-scheduler-content {
            overflow: auto;
        }
    }

    .k-scheduler-pane {
        display: flex;
        flex-direction: row;
    }
    .k-scheduler-pane .k-scheduler-times {
        flex: 0 0 auto;
    }
    .k-scheduler-pane .k-scheduler-times .k-scheduler-table {
        height: 100%;
    }
    .k-scheduler-pane .k-scheduler-header,
    .k-scheduler-pane .k-scheduler-content {
        flex: 1 1 auto;
    }
}


@include exports("scheduler-edit-form/layout") {

    .k-scheduler-edit-dialog {
        .k-dialog {
            max-height: 100vh;
        }
    }

    .k-scheduler-edit-form {

        .k-edit-form-container { width: 620px; }
        .k-edit-label { width: 17%; }
        .k-edit-field { width: 77%; }

        .k-scheduler-delete {
            float: left;
        }


        .k-widget.k-recur-interval,
        .k-widget.k-recur-count,
        .k-widget.k-recur-monthday {
            width: 5em;
        }
        .k-widget.k-recur-until,
        .k-widget.k-recur-month,
        .k-widget.k-recur-weekday,
        .k-widget.k-recur-weekday-offset {
            width: 9em;
        }

    }

}




@include exports("scheduler/rtl") {

    .k-rtl {
        .k-scheduler-header th,
        .k-scheduler-table td {
            border-width: 0 1px 1px 0;
        }

        .k-scheduler-table {
            td,
            th {
                &:first-child {
                    border-right-width: 0;
                }
            }
        }

        .k-scheduler-times th {
            border-width: 0 0 1px 1px;
        }

        .k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
            border-right-width: 0;
            border-left-width: 1px;
        }

        .k-event {
            text-align: right;
            padding-right: 0;
            padding-left: $padding-x;

            .k-resize-w {
                left: auto;
                right: 0;
            }

            .k-resize-e {
                right: auto;
                left: 0;
            }

            .k-event-actions {
                right: auto;
                left: 2px;
            }

            .k-event-actions:first-child {
                margin: $padding-y-sm $padding-x-sm 0 .4ex;
                float: right;
            }
        }
        .k-scheduler-agendaview .k-task {

            > .k-event-delete {
                left: 2px;
                right: auto;
            }

            .k-scheduler-mark {
                margin-left: .5em;
                margin-right: 0;
            }

        }

        .k-scheduler-views {
            margin-left: 0;
            margin-right: auto;

            li:first-child + li { @include border-right-radius-only( $border-radius ); }
            li:last-child { @include border-left-radius-only( $border-radius ); }

            .k-current-view .k-link::after {
                margin: 0 1ex 0 0;
            }
        }

        .k-scheduler-navigation  {

            .k-nav-today {
                @include border-right-radius-only( $border-radius );
            }

            .k-nav-next {
                @include border-left-radius-only( $border-radius );
            }

            li + li {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .k-nav-current .k-icon {
            margin-right: 0;
            margin-left: .4ex;
        }


        .k-scheduler-fullday {
            .k-icon {
                margin-right: 0;
                margin-left: $icon-spacing;
            }
        }

        .k-scheduler-marquee .k-label-top {
            left: auto;
            right: 4px;
        }

        .k-scheduler-marquee .k-label-bottom {
            left: 4px;
            right: auto;
        }

        .k-scheduler-edit-form .k-scheduler-delete {
            float: right;
        }

        .k-scheduler,
        &.k-scheduler {
            .k-i-arrow-60-left,
            .k-i-arrow-60-right,
            .k-current-time-arrow-right {
                transform: scaleX(-1);
            }

            .k-scheduler-header-wrap {
                border-right-width: 0;
            }

            .k-scheduler-agendaday {
                float: right;
                margin: 0 0 0 .2em;
            }

            .k-scheduler-agendaview .k-scheduler-table {
                td:first-child,
                th:first-child {
                    border-left-width: 0;
                }

                tr + tr {
                    td:first-child,
                    th:first-child {
                        border-right-width: 1px;
                    }
                }
            }
        }
    }
}

