// Timeline
$timeline-spacing-x: 40px !default;
$timeline-spacing-y: 40px !default;
$timeline-items-padding: 16px !default;

$timeline-font-family: $font-family !default;
$timeline-font-size: $font-size !default;
$timeline-line-height: $line-height !default;

$timeline-mobile-spacing-x: 16px !default;
$timeline-mobile-spacing-y: 16px !default;

$timeline-track-arrow-width: 30px !default;
$timeline-track-arrow-height: 30px !default;

$timeline-track-arrow-disabled-text: true-mix($button-text, $body-bg, 65%) !default;
$timeline-track-arrow-disabled-bg: true-mix($button-bg, $body-bg, 65%) !default;
$timeline-track-arrow-disabled-border: true-mix(#000000, $body-bg, 4.8%) !default;

$timeline-track-size: 6px !default;
$timeline-track-wrap-padding-bottom: ($timeline-track-size / 2) !default;
$timeline-track-border-width: 1px !default;
$timeline-track-margin-bottom: 16px !default;
$timeline-track-bottom-calc: calc((#{$timeline-track-arrow-height} / 2) + #{$timeline-track-wrap-padding-bottom}) !default;
$timeline-track-start-calc: calc(#{$timeline-track-arrow-width} - 2 * #{$timeline-track-border-width}) !default;
$timeline-track-end-calc: calc(#{$timeline-track-arrow-width} - 2 * #{$timeline-track-border-width}) !default;
$timeline-track-bg: $button-bg !default;
$timeline-track-border-color: $button-border !default;

$timeline-track-item-focus-shadow: 0 3px 4px 0 rgba($primary, .4) !default;

$timeline-track-event-offset: 36px !default;

$timeline-flag-padding-x: $button-padding-x !default;
$timeline-flag-padding-y: $button-padding-y !default;
$timeline-flag-line-height: $button-line-height !default;
$timeline-flag-min-width: 80px !default;
$timeline-flag-max-width: calc(#{$timeline-flag-min-width} + 2 * #{$timeline-spacing-x}) !default;
$timeline-mobile-flag-max-width: calc(#{$timeline-flag-min-width} + 2 * #{$timeline-mobile-spacing-x}) !default;
$timeline-horizontal-flag-min-width: 60px !default;
$timeline-flag-bg: $primary !default;
$timeline-flag-text: contrast-wcag( $timeline-flag-bg ) !default;

$timeline-flag-callout-width: 10px !default;
$timeline-flag-callout-height: 10px !default;

$timeline-flag-offset-bottom: 4px !default;
$timeline-flag-margin-bottom-calc: calc(#{$timeline-track-size} + 2 * #{$timeline-track-border-width} + #{$timeline-flag-offset-bottom} + #{$timeline-flag-callout-height}) !default;

$timeline-date-width: 50px !default;
$timeline-date-margin-bottom: 8px !default;

$timeline-vertical-padding-calc: calc(#{$timeline-spacing-x} + (#{$timeline-flag-min-width} - #{$timeline-track-size}) / 2) !default;
$timeline-vertical-padding-with-dates-calc: calc(#{$timeline-vertical-padding-calc} + #{$timeline-date-width}) !default;
$timeline-mobile-vertical-padding-calc: calc(#{$timeline-mobile-spacing-x} + (#{$timeline-flag-min-width} - #{$timeline-track-size}) / 2) !default;
$timeline-mobile-vertical-padding-with-dates-calc: calc(#{$timeline-mobile-vertical-padding-calc} + #{$timeline-date-width}) !default;

$timeline-circle-width: 16px !default;
$timeline-circle-height: 16px !default;
$timeline-circle-bg: $primary !default;

$timeline-collapse-arrow-padding-x: $padding-x !default;

$timeline-event-width: 400px !default;
$timeline-event-height: 600px !default;
$timeline-event-min-height-calc: calc(2 * (#{$timeline-track-event-offset} - #{$card-border-width})) !default;
