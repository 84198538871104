// Listgroup
$listgroup-border-width: 1px !default;
$listgroup-border-radius: $border-radius !default;

$listgroup-font-size: $font-size !default;
$listgroup-line-height: $line-height !default;

$listgroup-bg: $component-bg !default;
$listgroup-text: $component-text !default;
$listgroup-border: $component-border !default;

$listgroup-item-padding-x: $cell-padding-x !default;
$listgroup-item-padding-y: $cell-padding-y !default;
$listgroup-item-border-width: 1px !default;
