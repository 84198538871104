@include exports("toolbar/layout") {

    // Base
    .k-toolbar {
        @include use-background-clip();
        margin: 0;
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-width: $toolbar-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $toolbar-font-family;
        font-size: $toolbar-font-size;
        line-height: $toolbar-line-height;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        gap: $toolbar-spacing;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Remove once we decide to not size empty containers
        &::before {
            content: "";
            height: $button-calc-size;
            margin-inline-start: -$toolbar-spacing;
        }

        &.k-toolbar-resizable {
            flex-wrap: nowrap;
        }

        > * {
            flex-shrink: 0;
            display: inline-flex;
            align-items: stretch;
            align-content: center;
            vertical-align: middle;

            > label {
                margin-inline-end: $toolbar-spacing;
            }
        }

        .k-button,
        .k-button-group,
        .k-separator,
        .k-split-button,
        .k-textbox,
        .k-widget,
        label {
            align-self: center;
        }
        .k-split-button > .k-button {
            align-self: stretch;
        }


        // Button
        .k-button {}


        // Toggle button
        .k-toggle-button {}


        // Button group
        .k-button-group {}


        // Split button
        .k-split-button {}


        // Overflow anchor
        .k-overflow-anchor {
            @include border-radius( 0 );
            margin: 0;
            padding: $toolbar-padding-y;
            width: $toolbar-inner-calc-size;
            height: 100%;
            border-width: 0;
            border-color: inherit;
            box-sizing: border-box;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;

            &::before {
                display: block;
            }
        }


        // Separator
        .k-separator,
        .k-toolbar-separator {
            width: 0;
            height: $line-height-em;
            border-width: 0 0 0 1px;
            border-style: solid;
        }


        // Spacer
        .k-spacer,
        .k-toolbar-spacer {
            height: $line-height-em;
            flex: 1 1 auto;
        }


        // Template
        .k-toolbar-template {
            align-self: center;
            align-items: center;
        }

    }


    // Angular specific
    kendo-toolbar-renderer {
        border-color: inherit;
    }


    // Overflow container
    .k-overflow-container {

        // Group
        .k-overflow-tool-group {
            display: block;
        }

        // Button
        .k-overflow-button {
            width: 100%;
        }

        // Button group
        .k-button-group {
            display: flex;
            flex-direction: column;

            .k-button {
                margin: if( $button-border-width == 0, null, 0);
            }
        }

        // Split button
        .k-split-button {
            display: flex;
            flex-direction: column;
        }

        // Hidden items
        .k-overflow-hidden {
            display: none;
        }
    }




    // RTL
    .k-toolbar {

        &.k-rtl,
        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {
            .k-overflow-anchor {
                margin: 0;
                right: auto;
                left: 0;
            }
        }

    }

}




@include exports( "floating-toolbar/layout" ) {


    // sass-lint:disable class-name-format
    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content {
        padding: $toolbar-padding-y $toolbar-padding-x !important; // sass-lint:disable-line no-important
        border-width: $toolbar-border-width;
        border-style: solid;
        display: flex;
        flex-flow: row nowrap;
        gap: $toolbar-spacing;
        align-items: center;

        .k-toolbar {
            padding: 0;
            border-width: 0;
            color: inherit;
            background: none;
        }
    }
    .k-editortoolbar-dragHandle {
        cursor: move;
        display: flex;
        align-items: center;
        align-self: stretch;
        flex-shrink: 0;
    }
    // sass-lint:enable class-name-format


}
