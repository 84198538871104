@include exports("scrollview/layout") {

    kendo-scrollview.k-scrollview-wrap,
    kendo-scrollview.k-scrollview,
    .k-widget.k-scrollview {
        border-width: $scrollview-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $scrollview-font-family;
        font-size: $scrollview-font-size;
        line-height: $scrollview-line-height;
        position: relative;
        overflow: hidden;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
    }

    .k-scrollview-wrap .k-scrollview,
    .k-scrollview .k-scrollview-wrap {
        list-style-type: none;
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        cursor: default;
        white-space: nowrap;

        img {
            user-select: none;
        }

        & > li {
            display: inline-block;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
        }

        & > [data-role="page"] {
            vertical-align: top;
            display: inline-block;
            min-height: 1px;
        }
    }

    kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button,
    kendo-scrollview.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button,
    .k-widget.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link {

        &:nth-last-of-type(1),
        &:nth-of-type(1) {
            width: ($scrollview-pagebutton-size - 6px);
            height: ($scrollview-pagebutton-size - 6px);
        }

        &:nth-last-of-type(2),
        &:nth-of-type(2) {
            width: ($scrollview-pagebutton-size - 4px);
            height: ($scrollview-pagebutton-size - 4px);
        }

        &:nth-last-of-type(3),
        &:nth-of-type(3) {
            width: ($scrollview-pagebutton-size - 2px);
            height: ($scrollview-pagebutton-size - 2px);
        }
    }

    kendo-scrollview.k-scrollview-wrap kendo-scrollview-pager,
    kendo-scrollview.k-scrollview kendo-scrollview-pager,
    .k-scrollview-nav-wrap {
        padding: 0;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: $scrollview-pager-offset;
        height: $scrollview-pager-height;
        text-align: center;
    }

    kendo-scrollview.k-scrollview-wrap kendo-scrollview-pager,
    kendo-scrollview.k-scrollview kendo-scrollview-pager {
        pointer-events: none;
    }

    kendo-scrollview.k-scrollview-wrap .k-scrollview-nav,
    kendo-scrollview.k-scrollview .k-scrollview-nav {
        pointer-events: initial;
    }

    .k-scrollview-pageable,
    .k-scrollview-nav {
        margin: 0;
        padding:  $scrollview-pager-item-spacing;
        max-width: 100%;
        box-sizing: border-box;
        line-height: 0;
        text-align: center;
        white-space: nowrap;
        list-style: none;
        display: inline-flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        pointer-events: initial;

        &:focus {
            outline: none;
        }
    }

    .k-scrollview-pageable > .k-button,
    .k-scrollview-nav > .k-link {
        margin: 0 ($scrollview-pager-item-spacing / 2);
        padding: 0;
        width: $scrollview-pagebutton-size;
        height: $scrollview-pagebutton-size;
        border-width: $scrollview-pager-item-border-width;
        border-style: solid;
        border-radius: 50%;
        box-sizing: content-box;
        display: inline-block;
        position: relative;
        flex: 0 0 auto;
        cursor: pointer;
        pointer-events: all;
        vertical-align: top;

        &::before {
            content: "";
            width: ($scrollview-pagebutton-size + $scrollview-pager-item-spacing);
            height: ($scrollview-pagebutton-size + $scrollview-pager-item-spacing);
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: initial;
            border-radius: 0;
        }
    }

    .k-scrollview-next,
    .k-scrollview-prev {
        display: table;
        position: absolute;
        padding: 0;
        height: 60%;
        top: 20%;
        text-decoration: none;
        user-select: none;
        cursor: pointer;

        .k-icon {
            display: table-cell;
            overflow: visible;
            margin: 0;
            padding: 0;
            vertical-align: middle;
            font-size: $scrollview-arrow-icon-size;
            font-weight: normal;
        }
    }

    .k-scrollview-prev {
        left: 0;
    }

    .k-scrollview-next {
        right: 0;
    }


    .k-scrollview-animation {
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
    }


    @supports (-webkit-user-select: none) {
        kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *,
        kendo-scrollview.k-scrollview ul.k-scrollview-wrap li > *,
        div.k-scrollview ul.k-scrollview-wrap li > * {
            pointer-events: auto;
        }
    }

    @supports (not (-webkit-user-select: none)) {
        kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *,
        kendo-scrollview.k-scrollview ul.k-scrollview-wrap li > *,
        div.k-scrollview ul.k-scrollview-wrap li > * {
            pointer-events: none;
        }
    }

}
