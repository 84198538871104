@include exports("tilelayout/theme") {

    .k-tilelayout {
        @include fill(
            $bg: $tilelayout-bg
        );
    }

    .k-tilelayout-item:focus,
    .k-tilelayout-item.k-state-focus,
    .k-tilelayout-item.k-state-focused {
        @include box-shadow($tilelayout-card-focus-shadow);
    }

    .k-layout-item-hint {
        @include fill(
            $bg: $tilelayout-hint-bg,
            $border: $tilelayout-hint-border
        );
    }

}
