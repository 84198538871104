@include exports("expansion-panel/layout") {

    // Base
    .k-expander {
        @include use-background-clip();

        border-width: $expander-border-width;
        border-style: solid;
        box-sizing: border-box;
        overflow: hidden;
        outline: 0;
        font-family: $expander-font-family;
        font-size: $expander-font-size;
        line-height: $expander-line-height;
        display: flex;
        flex-direction: column;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        &[dir = "rtl"],
        .k-rtl & {
            // Expand / collapse icon
            .k-expander-indicator {
                margin-left: 0;
                margin-right: $expander-indicator-margin-x;
            }
        }
    }

    // Header
    .k-expander-header {
        padding: $expander-header-padding-y $expander-header-padding-x;
        border-width: 0 0 $expander-header-border-width;
        border-style: solid;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    // Expander spacer
    .k-expander-spacer {
        flex: 1 1 auto;
    }

    // Title
    .k-expander-title {
        font-weight: bold;
        text-transform: uppercase;
    }

    // Expand / collapse icon
    .k-expander-indicator {
        margin-left: $expander-indicator-margin-x;
    }

    // Expander content
    .k-expander-content {
        padding: $expander-content-padding-y $expander-content-padding-x;
        box-sizing: border-box;
    }
}
