@include exports("upload/theme") {

    .k-upload {
        @include fill(
            $upload-text,
            $upload-bg,
            $upload-border
        );

        .k-dropzone {
            @include fill(
                $upload-dropzone-text,
                $upload-dropzone-bg,
                $upload-dropzone-border
            );

            &.k-dropzone-hover,
            &.k-dropzone-hovered {
                background-color: $upload-dropzone-hover-bg;
            }

            .k-upload-status,
            .k-dropzone-hint {
                color: $upload-dropzone-text;
                opacity: $upload-dropzone-text-opacity;
            }

        }

        .k-file {

            &.k-state-focused {
                box-shadow: inset $upload-focused-shadow;
            }

            .k-upload-action.k-state-focused {
                box-shadow: $upload-focused-shadow;
            }

        }

        .k-upload-status {
            color: $base-text;
        }

        // Upload status: uploading
        .k-file-progress {

            .k-file-name {
                color: $upload-progress-text;
            }
            .k-progress {
                background-color: $upload-progress-bg;
            }
        }


        // Upload status: success
        .k-file-success {
            .k-progress {
                background-color: $upload-success-bg;
            }
        }

        // Upload satus: error
        .k-file-error,
        .k-file-invalid {
            .k-file-validation-message {
                color: $upload-error-text;
            }
            .k-progress {
                background-color: $upload-error-bg;
            }
        }

        .k-file-extension-wrapper,
        .k-multiple-files-extension-wrapper,
        .k-file-group-wrapper,
        .k-multiple-files-group-wrapper,
        .k-file-invalid-extension-wrapper,
        .k-multiple-files-invalid-extension-wrapper,
        .k-file-invalid-group-wrapper,
        .k-multiple-files-invalid-group-wrapper {
            color: $upload-icon-color;
            border-color: $upload-icon-color;
        }

        // old rendering
        .k-file-invalid-extension-wrapper,
        .k-multiple-files-invalid-extension-wrapper,
        .k-file-error .k-file-extension-wrapper,
        .k-file-invalid-group-wrapper,
        .k-multiple-files-invalid-group-wrapper,
        .k-file-error .k-file-group-wrapper {
            border-color: $upload-icon-color;
        }

        // old rendering
        .k-file-extension-wrapper::before,
        .k-multiple-files-extension-wrapper::before,
        .k-file-invalid-extension-wrapper::before,
        .k-multiple-files-invalid-extension-wrapper::before,
        .k-file-error .k-file-extension-wrapper::before {
            background-color: $upload-bg;
            border-color: transparent transparent $upload-icon-color $upload-icon-color;
        }

        // old rendering
        .k-multiple-files-extension-wrapper::after,
        .k-multiple-files-invalid-extension-wrapper::after {
            border-top-color: $upload-icon-color;
            border-left-color: $upload-icon-color;
        }

        .k-file-size,
        .k-file-validation-message,
        .k-file-information {
            color: $upload-disabled-text;
        }

        .k-multiple-files-wrapper .k-file-information {
            color: $upload-text;
        }

        // Invalid,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            border-color: $invalid-border;
        }
    }

}
