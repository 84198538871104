@include exports("rating/theme") {

    .k-rating {
        @extend .k-text-selection !optional;
        color: $component-text;

        &:focus,
        &.k-state-focused {

            .k-rating-item {
                > .k-icon {
                    text-shadow: $rating-icon-focused-shadow;
                }

                &.k-state-selected > .k-icon {
                    text-shadow: $rating-icon-focused-selected-shadow;
                }
            }
        }
    }

    .k-rating-item {
        color: $rating-icon-text;

        &.k-state-selected {
            color: $rating-icon-selected-text;

            &:focus,
            &.k-state-focused {
                color: $rating-icon-focused-text;
            }
        }

        &:hover,
        &.k-state-hover {
            color: $rating-icon-hover-text;
            cursor: pointer;
        }
    }
}

