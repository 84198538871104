// Chart
/// The first base series color and its light and dark shades.
/// @group charts
$series-a: #ff6358 !default;
$series-a-dark: mix(black, $series-a, 25%) !default;
$series-a-darker: mix(black, $series-a, 50%) !default;
$series-a-light: mix(white, $series-a, 25%) !default;
$series-a-lighter: mix(white, $series-a, 50%) !default;

/// The second base series color and its light and dark shades.
/// @group charts
$series-b: #ffd246 !default;
$series-b-dark: mix(black, $series-b, 25%) !default;
$series-b-darker: mix(black, $series-b, 50%) !default;
$series-b-light: mix(white, $series-b, 25%) !default;
$series-b-lighter: mix(white, $series-b, 50%) !default;

/// The third base series color and its light and dark shades.
/// @group charts
$series-c: #78d237 !default;
$series-c-dark: mix(black, $series-c, 25%) !default;
$series-c-darker: mix(black, $series-c, 50%) !default;
$series-c-light: mix(white, $series-c, 25%) !default;
$series-c-lighter: mix(white, $series-c, 50%) !default;

/// The fourth base series color and its light and dark shades.
/// @group charts
$series-d: #28b4c8 !default;
$series-d-dark: mix(black, $series-d, 25%) !default;
$series-d-darker: mix(black, $series-d, 50%) !default;
$series-d-light: mix(white, $series-d, 25%) !default;
$series-d-lighter: mix(white, $series-d, 50%) !default;

/// The fifth base series color and its light and dark shades.
/// @group charts
$series-e: #2d73f5 !default;
$series-e-dark: mix(black, $series-e, 25%) !default;
$series-e-darker: mix(black, $series-e, 50%) !default;
$series-e-light: mix(white, $series-e, 25%) !default;
$series-e-lighter: mix(white, $series-e, 50%) !default;

/// The sixth base series color and its light and dark shades.
/// @group charts
$series-f: #aa46be !default;
$series-f-dark: mix(black, $series-f, 25%) !default;
$series-f-darker: mix(black, $series-f, 50%) !default;
$series-f-light: mix(white, $series-f, 25%) !default;
$series-f-lighter: mix(white, $series-f, 50%) !default;

/// The series colors in order:
/// base, light, dark, lighter, darker
/// @group charts
$series-1: $series-a !default;
$series-2: $series-b !default;
$series-3: $series-c !default;
$series-4: $series-d !default;
$series-5: $series-e !default;
$series-6: $series-f !default;
$series-7: $series-a-light !default;
$series-8: $series-b-light !default;
$series-9: $series-c-light !default;
$series-10: $series-d-light !default;
$series-11: $series-e-light !default;
$series-12: $series-f-light !default;
$series-13: $series-a-dark !default;
$series-14: $series-b-dark !default;
$series-15: $series-c-dark !default;
$series-16: $series-d-dark !default;
$series-17: $series-e-dark !default;
$series-18: $series-f-dark !default;
$series-19: $series-a-lighter !default;
$series-20: $series-b-lighter !default;
$series-21: $series-c-lighter !default;
$series-22: $series-d-lighter !default;
$series-23: $series-e-lighter !default;
$series-24: $series-f-lighter !default;
$series-25: $series-a-darker !default;
$series-26: $series-b-darker !default;
$series-27: $series-c-darker !default;
$series-28: $series-d-darker !default;
$series-29: $series-e-darker !default;
$series-30: $series-f-darker !default;

$chart-border-width: 0px !default;
$chart-font-family: $font-family !default;
$chart-font-size: $font-size !default;
$chart-line-height: $line-height !default;
$chart-font-size-sm: 11px !default;
$chart-font-size-md: 12px !default;
$chart-font-size-lg: 16px !default;
$chart-tooltip-font-size: ($font-size * .929) !default;
$chart-label-font-size: .857em !default;
$chart-title-font-size: 1.143em !default;
$chart-pane-title-font-size: $chart-label-font-size !default;
$chart-pane-title-font-weight: $font-weight-normal !default;

$chart-inactive: rgba( $body-text, .5 ) !default;
/// The color of the Chart grid lines (major).
/// @group charts
$chart-major-lines: rgba(0, 0, 0, .08) !default;
/// The color of the Chart grid lines (minor).
/// @group charts
$chart-minor-lines: rgba(0, 0, 0, .04) !default;

$chart-area-opacity: .6 !default;
$chart-area-inactive-opacity: .1 !default;
$chart-line-inactive-opacity: .3 !default;

$chart-bg: $component-bg !default;
$chart-text: $component-text !default;
$chart-border: $component-border !default;

$chart-tap-highlight-color: rgba(0, 0, 0, 0);

$chart-crosshair-background: rgba(0, 0, 0, .5) !default;
$chart-crosshair-shared-tooltip-color: $base-text !default;
$chart-crosshair-shared-tooltip-background: $base-bg !default;
$chart-crosshair-shared-tooltip-border: rgba(0, 0, 0, .08) !default;

$chart-notes-background: rgba(0, 0, 0, .5) !default;
$chart-notes-border: rgba(0, 0, 0, .5) !default;
$chart-notes-lines: rgba(0, 0, 0, .5) !default;

$error-bars-background: rgba(0, 0, 0, .5) !default;

$chart-selection-marque-background: $series-a !default;
$selection-handle-size: 22px !default;
$selection-border-color: rgba(0, 0, 0, .08) !default;
$selection-shadow: inset 0 1px 7px rgba(0, 0, 0, .15) !default;


// TreeMap
$treemap-font-family: $font-family !default;
$treemap-font-size: $font-size !default;
$treemap-line-height: $line-height !default;
