@include exports( "loader/layout" ) {

    .k-loader {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;

        // Prevents the inline elements from being displaced.
        // A good example is the 'pulsing' loader inside a button.
        line-height: 0;
    }

    .k-loader-canvas {
        display: flex;
        position: relative;
    }

    // Loader segment
    .k-loader-segment {
        border-radius: $loader-segment-border-radius;
        background-color: currentColor;
        position: absolute;
    }

    // Loader sizes
    .k-loader-sm {
        padding: $loader-padding-sm;

        .k-loader-segment {
            width: $loader-segment-size-sm;
            height: $loader-segment-size-sm;
        }
    }
    .k-loader-md {
        padding: $loader-padding-md;

        .k-loader-segment {
            width: $loader-segment-size-md;
            height: $loader-segment-size-md;
        }
    }
    .k-loader-lg {
        padding: $loader-padding-lg;

        .k-loader-segment {
            width: $loader-segment-size-lg;
            height: $loader-segment-size-lg;
        }
    }

}




@include exports( "loader/pulsing/2" ) {

    // Animation
    @keyframes pulsing-2-segment {
        0% { transform: scale(1); }
        50% { transform: scale(1.5); }
        100% { transform: scale(1); }
    }


    // Component
    .k-loader-pulsing-2 {

        .k-loader-canvas {
            align-items: center;
        }

        .k-loader-segment:nth-child(1) {
            left: 0;
            transform: scale( 1.5 );
            transform-origin: 0 50%;
            animation: pulsing-2-segment 1s ease infinite;
            animation-delay: -.5s;
        }
        .k-loader-segment:nth-child(2) {
            right: 0;
            transform-origin: 100% 50%;
            animation: pulsing-2-segment 1s ease infinite;
        }

        &.k-loader-sm {
            .k-loader-canvas {
                width: ( $loader-segment-size-sm * 3 );
                height: ( $loader-segment-size-sm * 1.5 );
            }
        }

        &.k-loader-md {
            .k-loader-canvas {
                width: ( $loader-segment-size-md * 3 );
                height: ( $loader-segment-size-md * 1.5 );
            }
        }

        &.k-loader-lg {
            .k-loader-canvas {
                width: ( $loader-segment-size-lg * 3 );
                height: ( $loader-segment-size-lg * 1.5 );
            }
        }
    }

}




@include exports( "loader/spinner/3" ) {

    // Animation
    @keyframes spinner-3-segment {
        0% {
            top: 0;
            left: 50%;
        }

        33.3333% {
            top: 100%;
            left: 0;
        }

        66.6666% {
            top: 100%;
            left: 100%;
        }

        100% {
            top: 0;
            left: 50%;
        }
    }


    // Component
    .k-loader-spinner-3 {

        // Note: Until we can provide a css variables solution, or something better:
        // * the dot center is on the exact point of angle
        // * offset dots by artifically reduce dimentions by 1 dot size
        // * compensate by setting margin on all sides half a dot size

        .k-loader-segment {
            transform: translate( -50%, -50% );
        }
        .k-loader-segment:nth-child(1) {
            top: 0;
            left: 50%;
            animation: spinner-3-segment 2s ease infinite;
        }
        .k-loader-segment:nth-child(2) {
            top: 100%;
            left: 0;
            animation: spinner-3-segment 2s ease infinite;
            animation-delay: calc( -2s / 3 );
        }
        .k-loader-segment:nth-child(3) {
            top: 100%;
            left: 100%;
            animation: spinner-3-segment 2s ease infinite;
            animation-delay: calc( -4s / 3 );
        }


        &.k-loader-sm {
            .k-loader-canvas {
                margin: ( $loader-segment-size-sm / 2 );
                width: calc( #{$loader-spinner-3-width-sm} - #{$loader-segment-size-sm} );
                height: calc( #{$loader-spinner-3-height-sm} - #{$loader-segment-size-sm} );
            }
        }

        &.k-loader-md {
            .k-loader-canvas {
                margin: ( $loader-segment-size-md / 2 );
                width: calc( #{$loader-spinner-3-width-md} - #{$loader-segment-size-md} );
                height: calc( #{$loader-spinner-3-height-md} - #{$loader-segment-size-md} );
            }
        }

        &.k-loader-lg {
            .k-loader-canvas {
                margin: ( $loader-segment-size-lg / 2 );
                width: calc( #{$loader-spinner-3-width-lg} - #{$loader-segment-size-lg} );
                height: calc( #{$loader-spinner-3-height-lg} - #{$loader-segment-size-lg} );
            }
        }
    }

}




@include exports( "loader/spinner/4" ) {

    // Animation
    @keyframes spinner-4-segment-1 {
        0% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        33.3% {
            top: 0;
            left: 0;
            transform: translateX(0) translateY(0);
        }

        66.6% {
            top: 0;
            left: 0;
            transform: translateX(0) translateY(0);
        }

        100% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    @keyframes spinner-4-segment-2 {
        0% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        33.3% {
            top: 0;
            left: 100%;
            transform: translateX(-100%) translateY(0);
        }

        66.6% {
            top: 0;
            left: 100%;
            transform: translateX(-100%) translateY(0);
        }

        100% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    @keyframes spinner-4-segment-3 {
        0% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        33.3% {
            top: 100%;
            left: 100%;
            transform: translateX(-100%) translateY(-100%);
        }

        66.6% {
            top: 100%;
            left: 100%;
            transform: translateX(-100%) translateY(-100%);
        }

        100% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    @keyframes spinner-4-segment-4 {
        0% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        33.3% {
            top: 100%;
            left: 0;
            transform: translateX(0) translateY(-100%);
        }

        66.6% {
            top: 100%;
            left: 0;
            transform: translateX(0) translateY(-100%);
        }

        100% {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    @keyframes spinner-4 {
        0% {
            transform: rotate(0);
        }

        33.3% {
            transform: rotate(0);
        }

        66.6% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(180deg);
        }
    }

    .k-loader-spinner-4 {
        .k-loader-canvas {
            animation: spinner-4 1.833s ease infinite;
        }

        .k-loader-segment {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            &:nth-child(1) {
                animation: spinner-4-segment-1 1.833s ease infinite;
            }

            &:nth-child(2) {
                animation: spinner-4-segment-2 1.833s ease infinite;
            }

            &:nth-child(3) {
                animation: spinner-4-segment-3 1.833s ease infinite;
            }

            &:nth-child(4) {
                animation: spinner-4-segment-4 1.833s ease infinite;
            }
        }

        &.k-loader-sm {
            .k-loader-canvas {
                width: $loader-spinner-4-width-sm;
                height: $loader-spinner-4-height-sm;
            }
        }

        &.k-loader-md {
            .k-loader-canvas {
                width: $loader-spinner-4-width-md;
                height: $loader-spinner-4-height-md;
            }
        }

        &.k-loader-lg {
            .k-loader-canvas {
                width: $loader-spinner-4-width-lg;
                height: $loader-spinner-4-height-lg;
            }
        }
    }
}



@include exports( "loader-container/layout" ) {

    .k-loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2001;

        .k-loader {
            padding: 0;
        }
    }

    .k-loader-container,
    .k-loader-container-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .k-loader-container-inner {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    .k-loader-container-panel {
        border-width: $loader-container-panel-border-width;
        border-style: $loader-container-panel-border-style;
        border-radius: $loader-container-panel-border-radius;
    }

    // Loader Container Sizes
    .k-loader-container-sm {
        .k-loader-container-inner {
            padding: $loader-container-padding-sm;
            gap: $loader-container-gap-sm;
        }

        .k-loader-container-label {
            font-size: $loader-container-font-size-sm;
        }
    }
    .k-loader-container-md {
        .k-loader-container-inner {
            padding: $loader-container-padding-md;
            gap: $loader-container-gap-md;
        }

        .k-loader-container-label {
            font-size: $loader-container-font-size-md;
        }
    }
    .k-loader-container-lg {
        .k-loader-container-inner {
            padding: $loader-container-padding-lg;
            gap: $loader-container-gap-lg;
        }

        .k-loader-container-label {
            font-size: $loader-container-font-size-lg;
        }
    }

    // Loader Position
    .k-loader-top {
        .k-loader-container-inner {
            flex-direction: column;
            text-align: center;
        }
    }
    .k-loader-end {
        .k-loader-container-label {
            order: -1;
        }
    }

}
