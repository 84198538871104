@include exports( "typography/layout" ) {

    .k-typography {
        @include typography(
            // handle html.k-typography with styles already present
            if( get-font-family( $typography-config, body ) == inherit, null, get-font-family( $typography-config, body ) ),
            get-font-size( $typography-config, body ),
            get-line-height( $typography-config, body ),
            get-font-weight( $typography-config, body ),
            get-letter-spacing( $typography-config, body )
        );

        p {
            margin: 0 0 $paragraph-margin-bottom;
        }
    }

    // Headings
    .k-h1 {
        @include typography( map-get($typography-config, h1) );
        margin: 0 0 $h1-margin-bottom;
    }
    .k-h2 {
        @include typography( map-get($typography-config, h2) );
        margin: 0 0 $h2-margin-bottom;
    }
    .k-h3 {
        @include typography( map-get($typography-config, h3) );
        margin: 0 0 $h3-margin-bottom;
    }
    .k-h4 {
        @include typography( map-get($typography-config, h4) );
        margin: 0 0 $h4-margin-bottom;
    }
    .k-h5 {
        @include typography( map-get($typography-config, h5) );
        margin: 0 0 $h5-margin-bottom;
    }
    .k-h6 {
        @include typography( map-get($typography-config, h6) );
        margin: 0 0 $h6-margin-bottom;
    }

    // Display
    .k-display-1 {
        @include typography( map-get($typography-config, display-1) );
    }
    .k-display-2 {
        @include typography( map-get($typography-config, display-2) );
    }
    .k-display-3 {
        @include typography( map-get($typography-config, display-3) );
    }
    .k-display-4 {
        @include typography( map-get($typography-config, display-4) );
    }

}
