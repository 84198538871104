@include exports("pivotgrid/theme") {

    .k-pivot {
        border-color: $pivotgrid-border;
    }

    .k-alt,
    .k-pivot-toolbar,
    .k-pivot-layout > tbody > tr:first-child > td:first-child {
        background-color: $pivotgrid-alt-bg;
    }

    .k-fieldselector {
        color: $pivotgrid-configurator-text;
    }

    .k-fieldselector .k-list-container {
        background-color: $pivotgrid-container-bg;
    }

    .k-pivot-toolbar,
    .k-pivot-table,
    .k-fieldselector,
    .k-fieldselector .k-list-container,
    .k-fieldselector .k-columns > div,
    .k-pivot-rowheaders > .k-grid td:first-child,
    .k-pivot-table .k-grid-header .k-header.k-first {
        border-color: $pivotgrid-chrome-border;
    }

    .k-pivot-rowheaders .k-alt .k-alt,
    .k-header.k-alt {
        background-color: $pivotgrid-row-headers-bg;
    }

    .k-pivot-toolbar .k-button,
    .k-fieldselector .k-list li.k-item {
        @include fill(
            $button-text,
            $button-bg,
            $button-border,
            $button-gradient
        );

        &:hover,
        &.k-state-hover {
            @include fill(
                $button-hovered-text,
                $button-hovered-bg,
                $button-hovered-border,
                $button-hovered-gradient
            );
        }

        // Pressed state
        &:active,
        &.k-state-active {
            @include fill( $button-text, $button-bg, $button-border, #{to top, $button-gradient} );
            box-shadow: $button-pressed-shadow;
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            box-shadow: $button-focused-shadow;
        }

        &.k-empty {
            color: $pivotgrid-setting-delete-color;
        }

        .k-setting-delete {
            color: $pivotgrid-setting-delete-color;
        }
    }

    .k-pivot-toolbar {
        .k-empty {
            color: $pivotgrid-setting-delete-color;
        }
    }

    .k-pivot-layout .k-grid-footer,
    .k-pivot-layout .k-grid .k-alt {
        color: $pivotgrid-alt-color;
    }

    // Loading indicator
    .k-fieldselector {
        .k-i-loading {
            border-color: $header-bg;
        }

        .k-i-loading::before,
        .k-i-loading::after {
            background-color: $header-bg;
        }
    }

    // KPI icons
    .k-i-kpi-trend-increase,
    .k-i-kpi-trend-decrease,
    .k-i-kpi-trend-equal {
        color: inherit;
    }

    .k-i-kpi-status-hold {
        color: $warning;
    }

    .k-i-kpi-status-deny {
        color: $error;
    }

    .k-i-kpi-status-open {
        color: $success;
    }
}
