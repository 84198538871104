@include exports( "utils/overflow" ) {


    // Overflow utility classes
    $utils-overflow: (
        auto,
        hidden,
        visible,
        scroll,
        clip
    ) !default;

    // sass-lint:disable no-important
    @if $utils-overflow {
        @each $overflow in $utils-overflow {
            .k-overflow-#{$overflow} { overflow: $overflow !important; }
            .k-overflow-x-#{$overflow} { overflow-x: $overflow !important; }
            .k-overflow-y-#{$overflow} { overflow-y: $overflow !important; }
        }


        @each $overflow-x in $utils-overflow {
            @each $overflow-y in $utils-overflow {
                @if $overflow-x != $overflow-y {
                    .k-overflow-#{$overflow-x}-#{$overflow-y} { overflow: $overflow-x $overflow-y !important; }
                }
            }
        }
    }
    // sass-lint:enable no-important

}
