@include exports("panelbar/theme") {

    .k-panelbar {
        @extend .k-text-selection !optional;
        @include fill(
            $panelbar-text,
            $panelbar-bg,
            $panelbar-border
        );


        // Root
        > .k-item {

            > .k-link {
                @include fill(
                    $panelbar-header-text,
                    $panelbar-header-bg,
                    $panelbar-header-border,
                    $panelbar-header-gradient
                );

                .k-icon {
                    color: $panelbar-text;
                }
            }

            &.k-state-active > .k-link,
            &.k-state-expanded > .k-link {
                color: inherit;
                background-color: transparent;
                font-weight: 500;
            }

            > .k-link:hover,
            > .k-link.k-state-hover {
                @include fill(
                    $panelbar-header-hovered-text,
                    $panelbar-header-hovered-bg,
                    $panelbar-header-hovered-border,
                    $panelbar-header-hovered-gradient
                );
            }

            > .k-link.k-state-selected {
                @include fill(
                    $panelbar-header-selected-text,
                    $panelbar-header-selected-bg,
                    $panelbar-header-selected-border,
                    $panelbar-header-selected-gradient
                );

                .k-icon {
                    color: inherit;
                }

                &:hover,
                &.k-state-hover {
                    background-color: shade( $panelbar-header-selected-bg );
                }
            }

            > .k-link:focus,
            > .k-link.k-state-focus,
            > .k-link.k-state-focused {
                box-shadow: $panelbar-header-focused-shadow;
            }
        }


        // Sub
        .k-group {

            > .k-item > .k-link:hover,
            > .k-item > .k-link.k-state-hover {
                @include fill(
                    $panelbar-item-hovered-text,
                    $panelbar-item-hovered-bg,
                    $panelbar-item-hovered-border,
                    $panelbar-item-hovered-gradient
                );
            }

            > .k-item > .k-link.k-state-selected {
                @include fill(
                    $panelbar-item-selected-text,
                    $panelbar-item-selected-bg,
                    $panelbar-item-selected-border,
                    $panelbar-item-selected-gradient
                );

                &:hover,
                &.k-state-hover {
                    background-color: shade( $panelbar-item-selected-bg );
                }
            }

            > .k-item > .k-link:focus,
            > .k-item > .k-link.k-state-focus,
            > .k-item > .k-link.k-state-focused {
                box-shadow: $panelbar-item-focused-shadow;
            }

        }

    }

}
