/* You can add global styles to this file, and also import other style files */

/* #GOOGLE FONT 
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");*/
@import "google-open-sans.css";

 app-root, app-main-layout, app-auth-layout, app-locked, #extr-page {
    min-height: 94vh !important;
} 


#main {
    padding-bottom: 0px !important;
}

.login-form-control {
    box-sizing: border-box;
    padding: 6px 12px;
}

.jarviswidget .widget-body {
    min-height: 0px !important;
}

.widget-toolbar.widget-toolbar-label{
    cursor: default;
}

.k-grid th {
    padding: .1em .3em;
}

 .k-grid td {
    padding: .1em .3em;
 }

 .k-grid tr:hover {
    background: #ededed;
  }
   
  .k-grid tr.k-alt:hover {
    background: #ededed;
  }

 .k-grid-pager {
    padding: .1em .3em;
 }

 .k-grid-filter {
    height: 20px !important;
    width: 20px !important;
    bottom: 0 !important;
 }

 .grid-action-button {
     width: 24px;
 }

 .k-grid .k-grid-aria-root {
    overflow-x: auto !important;
}

 .k-icon.k-i-loading, .k-loading-image {
    color: #3276b1;
}


.control-label {
     font-weight: bold;
     font-size: 12px;
 }

 @media (min-width: 768px){
    .form-horizontal .control-label {
        text-align: left !important;
    }
}
 
textarea {
    height: 100px;
    min-height: 100px;
    resize: vertical;
}

bs-dropdown-container {
    z-index: 1060 !important;    
  }

.dropdown-menu{
    max-height: 300px; /*Provide height in pixels or in other units as per your layout*/
    overflow-y: auto; /*Provide an auto overflow to diaply scroll*/
}

.dropdown-menu li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.open>.dropdown-menu {
    -webkit-animation-name: none;
    -moz-animation-name: none;
    -o-animation-name: none;
    animation-name: none;  
    -webkit-animation-fill-mode: none;
    -moz-animation-fill-mode: none;
    -o-animation-fill-mode: none;
    animation-fill-mode: none;
}

.widget-toolbar .dropdown-menu {
    left: initial;
    right: 0;
}

.k-dropdown .k-dropdown-wrap.k-state-default, 
.k-picker-wrap .k-state-default, 
.k-multiselect-wrap, .k-numerictextbox .k-numeric-wrap, 
.k-multiselect .k-multiselect-wrap, .k-dropdowntree .k-multiselect-wrap,
.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
    background: white;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;    
}

.input-group .k-widget.form-control{
    width: 100%;
}

.input-group .k-dateinput-wrap, .input-group .k-dropdown-wrap, .input-group .k-multiselect-wrap, .input-group .k-numeric-wrap, 
.input-group .k-picker-wrap, .input-group .k-maskedtextbox .k-textbox{
    border: 1px solid #ccc;
}

.k-datepicker .k-picker-wrap, .k-datetimepicker .k-picker-wrap{
    border: 1px solid #ccc;
}

.k-datetime-container .k-datetime-wrap {
    width: 22em;
}

.k-list .k-item:focus, .k-list .k-item.k-state-focused{
    box-shadow: initial;
}

.k-widget.form-control .k-input, .form-control.k-block .k-input,
.k-widget.form-control.k-maskedtextbox .k-textbox{
    height: 32px;    
}

.k-widget.form-control.input-sm .k-input{
    height: 28px;
}

.k-multiselect-wrap{
    min-height: 32px;
}

.k-multiselect-wrap .k-button {
    font-size: 75%;
    font-weight: 700;
    line-height: 2;
    color: #fff;
    background-image: none;
    background-color: #999;
    border-radius: .25em;
    border-style: none;
    padding: .2em .6em .3em;
}

.k-multiselect-wrap .k-button:hover {
  opacity: 0.8;
}

.k-multiselect-wrap .k-button .k-icon.k-i-close{
    opacity: 1;
    font-weight: bolder;
    font-size: 12px;
}

.k-dropdown .k-dropdown-wrap, 
.k-combobox .k-dropdown-wrap{
    border: 1px solid #ccc;
}


.k-state-invalid > .k-picker-wrap .k-dateinput-wrap, .k-state-invalid > .k-dateinput-wrap, .k-state-invalid > .k-dropdown-wrap, 
.k-state-invalid > .k-numeric-wrap, .k-state-invalid > .k-picker-wrap, .ng-invalid.ng-touched > .k-picker-wrap .k-dateinput-wrap, 
.ng-invalid.ng-touched > .k-dateinput-wrap, .ng-invalid.ng-touched > .k-dropdown-wrap, .ng-invalid.ng-touched > .k-numeric-wrap, 
.ng-invalid.ng-touched > .k-picker-wrap, .ng-invalid.ng-dirty > .k-picker-wrap .k-dateinput-wrap, 
.ng-invalid.ng-dirty > .k-dateinput-wrap, .ng-invalid.ng-dirty > .k-dropdown-wrap, .ng-invalid.ng-dirty > .k-numeric-wrap, 
.k-state-invalid.k-autocomplete, .k-state-invalid.k-maskedtextbox > .k-textbox, .ng-invalid.ng-touched.k-autocomplete, 
.ng-invalid.ng-touched.k-maskedtextbox > .k-textbox, .ng-invalid.ng-dirty.k-autocomplete, .ng-invalid.ng-dirty.k-maskedtextbox > .k-textbox,
.ng-invalid.ng-dirty > .k-picker-wrap,
.ng-invalid.ng-touched > .k-multiselect-wrap,
.k-upload .k-file-invalid .k-file-name-invalid,
.k-upload .k-file-invalid-extension-wrapper,
.has-error .k-dropdown .k-dropdown-wrap.k-state-default,
.has-error .k-multiselect-wrap,
.has-error .k-upload,
.has-error .k-numeric-wrap,
.has-error button,
.has-error button:hover,
.has-error button:focus {
    color:#b94a48 !important;
    border-color:#b94a48 !important;
}

.has-highlight .form-control, 
.has-highlight .checkbox, 
.has-highlight .checkbox-inline, 
.has-highlight .control-label, 
.has-highlight .help-block, 
.has-highlight .radio, 
.has-highlight .radio-inline, 
.has-highlight.checkbox label, 
.has-highlight.checkbox-inline label, 
.has-highlight.radio label, 
.has-highlight.radio-inline label.has-highlight .form-control,
.has-highlight .k-dropdown .k-dropdown-wrap.k-state-default,
.has-highlight .k-datepicker .k-picker-wrap.k-state-default,
.has-highlight .k-multiselect-wrap,
.has-highlight .k-upload,
.has-highlight .k-numeric-wrap,
.has-highlight .k-widget.form-control .k-input, 
.has-highlight .form-control.k-block .k-input, 
.has-highlight .k-widget.form-control.k-maskedtextbox .k-textbox,
.has-highlight button,
.has-highlight button:hover,
.has-highlight button:focus {
    color:#c09853;    
}

.k-dropdown .k-state-disabled .k-input, .k-dropdown .k-state-disabled .k-select,
.k-numeric-wrap.k-state-disabled, .k-state-disabled .k-multiselect-wrap.k-floatwrap,
.k-combobox .k-dropdown-wrap.k-state-disabled,
.k-widget.k-maskedtextbox.k-state-disabled .k-textbox{
    background-color: #eee;
    background-image: none;
    opacity: 1 ;
    color: #555;
    cursor: not-allowed;
    border-radius: 0;
}

.input-group .k-dateinput-wrap{
    border: 0 !important;
}

.form-control.input-sm.k-widget {
    font-size: 12px;
    line-height: 1.5;
}


.form-group .k-disabled, .form-group .k-state-disabled {   
     opacity: 1; 
     -webkit-filter: none; 
     filter: none;    
}


.k-widget.form-control.k-state-disabled .k-input{
    background-color: #eee;
    background-image: none;
    opacity: 1;
    color: #555;
    cursor: not-allowed;
    border-radius: 0;
}

td > .label {
    min-width: 100%;
    display: inline-block;
}

.tag {
    min-width: initial;
    margin-right: 2px;
    line-height: 2;
}

.tag:hover{
    opacity: 0.8;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.modal-xl {
    width: 80%;
}

.modal-ts {
    width: 95%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.dropdown-toggle:hover {
        border-color: rgba(0, 0, 0, 0.15);
    color: #656565;
    background-color: #ededed;
    background-image: linear-gradient(#ededed, #e8e8e8);
}

.dropdown-menu, .tooltip{
    z-index: 1051;
}

.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover { 
    background-color: #f6f6f6;
    color: #333;
}

.widget-toolbar .btn-group-xs>.btn, .btn-xs {
    line-height: 1.42857143;
}

.btn-xs .badge{ 
    font-size: 8px; 
    top: -5px; 
    left: -8px
}

.popover-footer {
    padding: 10px 0 0;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-header, .modal-footer{
    background-color: #fafafa;
}

.bg-color-primary {
    background-color: #3276B1!important;
}

.form-group-sm .input-group-btn .btn{
    line-height: 1 !important;
}

.form-control {
    font-size: 12px;
}

.widget-toolbar .btn-tall {    
    padding: 5px 8px 5px 8px!important;
}

.breadcrumb {
    padding: 15px 15px;
    margin-bottom: 0; 
    background-color: transparent;    
}

#resultWidget>header>.widget-toolbar>.btn {
    padding: 2px 2px !important;
    height: 29px;
}

header .popover.left {      
    top: -50px !important; 
}

.label-uti {
    background-color: #000;
}

.txt-color-uti {
    color: #000;
}

.btn-uti {
    background-color: #000;
    color: #fff;
}

.not-found-row { 
    height: 37px; 
}

.has-error input[type=email], .has-error input[type=email]+.input-group-addon, 
.has-error input[type=password], .has-error input[type=password]+.input-group-addon {
    border-color: #b94a48;
}

nav ul .active>a{
    color: #3276b1 !important;;
}

#mobile-profile-img{
    display: block!important;
}

#logo {
    margin-top: 5px;
}

.action-bar + #content{
    padding: 40px 0 0 0;
}

.action-bar + #content.no-padding{
    padding: 31px 0 0 0!important;
}

.login-info{
    border: none!important;
    box-shadow:none;
    display: flex;
}

.login-info>span{
    border: none;
    display: inline;
    color: black;
} 

.login-info img{
    border-radius: 20px;
    border-left: 0;
}

.custom-menu-dropdown{
    background-color: #f5f5f5;
    font-size: 14px;
    color: #333;
    max-height: 400px;
}

.menu-item-title {    
    font-weight: 400;        
    margin: 10px;
}

.action-bar{
    background-color: #3a3633;
    position: fixed;
    width: 100%;
    z-index: 20;   
    padding-right: 45px; 
}

.action-bar .btn{        
    border-top: none;
    border-bottom: none;
    border-right: 1px solid #525151;
    border-left: 1px solid #1A1817;
    float: right;
}

.action-bar .btn.btn-primary{        
    border-right: 1px solid #367fbe;
    border-left: 1px solid #2c699d;
}

.action-bar .btn-group.dropdown, .action-bar .btn-group{
    float: right;
}

.action-bar>span{    
    color: #f5f5f5;
    float: right;
    height: 30px;
    padding-top: 5px;
    margin: 0 20px;
}

.action-bar .btn:active{
    top: auto;
    left: auto;
}

.action-bar .btn-default:hover, 
.action-bar .btn-default:active,
.action-bar .btn-default:focus{
    background-color: #525151 !important;
    background-image: none !important;
    color: #f5f5f5 !important;
}

.action-bar .btn-default{
    background-color: transparent; 
    color: #f5f5f5;
}

.login-popup{
    width: 400px;
    height: 200px;
    border-radius: 25px;
    position: fixed;
    background-color: transparent;
    top: 50%;
    left: 15%;
    padding-top: 15px;
    margin-top: -100px;
    margin-left: -100px;
    border: 1px solid gray
}

.smart-form.login-popup fieldset, .smart-form.login-popup footer{
    background-color: transparent;
}

.smart-form.login-popup footer .btn{
    float: initial;
}

aside {
    background: #3a3633!important;
}

.fixed-navigation nav>ul {
    padding-top: 32px;
}

.minified nav>ul:first-child li {
    border-top: none;
}

.minified nav>ul>li>span>a.disabled, .minified nav>ul>li>a.disabled{
    color: #555;
    cursor: not-allowed;
}

.header-search.pull-right {
    margin-right: 25px;
}

@media (max-width: 767px) {
    .header-search.pull-right {
        display: none!important;
    }

    .login-info>.mobile-hidden{
        display: none!important;;
    }
}

/* .header-btns{
    margin: 5px;
}

.header-btns li{
    border-right: 1px solid lightgray;
    border-left: 1px solid white;
    float: left;
    margin-top: 15px;
}

.header-btns li:first-child, .header-btns li:last-child {
    border-right: 0;
    border-left: 0;
  }

.header-btns a{
    padding-left: 10px;
    padding-right: 10px;
}

.header-btns i{
    color: #4E4E4E;
} */

.k-grid.selectable{
    line-height: 3;
    cursor: pointer
}

tr.selected,
tr.k-alt.selected {
  background-color: #dff0d8;
}    

tr.selected:hover,
tr.k-alt.selected:hover {
  background-color: #d0e9c6;
}    

.overflow-hidden {
    overflow-y: hidden;
}

.tips-container, .tips-container .modal-body, .tips-container .modal-footer{
    background-color: #367fbe;
    color: white;
}

.tips-container.popover.right>.arrow:after{
    border-right-color: #367fbe!important;
}

.tips-container .popover-title, .tips-container .modal-header{
    background-color: white;
    color: black;
}

.k-pdf-export colgroup > col:nth-child(2){
    width: 0px !important;
}

.k-pdf-export .k-grid-column-menu, .k-pdf-export .k-grid-filter{
    display: none;
}

.k-pdf-export a{
    display:none;
}

.SmallBox {
    top: 100px!important;
}