// Editor
$editor-border-width: 1px !default;
$editor-font-family: $font-family !default;
$editor-font-size: $font-size !default;
$editor-line-height: $line-height !default;

$editor-placeholder-text: $input-placeholder-text !default;
$editor-placeholder-opacity: $input-placeholder-opacity !default;

$editor-selected-text: $primary-contrast !default;
$editor-selected-bg: $primary !default;

$editor-highlighted-bg: mix($primary, #ffffff, 20%) !default;

$editor-find-line-bg: $secondary;

$editor-export-tool-icon-margin-x: .25em;
