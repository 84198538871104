@include exports("input/theme") {
    .k-input-separator {
        border-color: $input-separator-color;
        opacity: $input-separator-opacity;
    }

    .k-input-prefix,
    .k-input-suffix {
        > .k-clear-value {
            &.k-state-focused::after,
            &:focus::after {
                background-color: $input-clear-button-focused-background;
                opacity: $input-clear-button-focused-opacity;
            }
        }
    }
}
