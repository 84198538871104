@include exports("pdf-viewer/layout") {

    .k-pdf-viewer {
        border-width: $pdf-viewer-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $pdf-viewer-font-family;
        font-size: $pdf-viewer-font-size;
        line-height: $pdf-viewer-line-height;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Toolbar
        .k-toolbar {
            border-top-width: 0;
            border-right-width: 0;
            border-left-width: 0;
            border-color: inherit;
            flex: 0 0 auto;
            z-index: 2;
        }
        .k-toolbar .k-pager-wrap {
            padding: 0;
            border-width: 0;
            color: inherit;
            background: none;
            overflow: visible;
        }


        // Canvas
        .k-list-scroller {
            flex: 1 1 auto;
            outline: none;

            .k-enable-text-select {
                -webkit-user-select: text;
                -moz-user-select: text;
                -ms-user-select: text;
                user-select: text;
                cursor: text;
            }

            .k-enable-panning {
                cursor: grab;
            }
            .k-enable-panning span::selection {
                background-color: transparent;
            }
        }


        // Page
        .k-page {
            position: relative;
            margin: $pdf-viewer-page-spacing auto;

            canvas {
                direction: ltr;
            }

            .k-text-layer {
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                overflow: hidden;

                > span {
                    position: absolute;
                    line-height: $pdf-viewer-selection-line-height;
                    transform-origin: 0% 0%;
                    color: transparent;
                }

                mark {
                    color: transparent;
                }
            }
        }
    }

    // Search
    .k-pdf-viewer-search-dialog {
        padding: $pdf-viewer-search-dialog-spacing;

        .k-search-container {
            display: flex;
            flex: 0 0 auto;
            justify-content: flex-start;
            align-items: center;

            .k-textbox {
                display: inline-flex;
                padding-right: $pdf-viewer-search-dialog-textbox-padding-x;
                margin: 0 $pdf-viewer-search-dialog-spacing;
                align-items: center;
                background: none;
            }
            .k-textbox .k-icon {
                position: absolute;
                font-size: inherit;

                .k-ie & {
                    position: relative;
                }
            }

            .k-match-case-button {
                display: inline-flex;
                height: $pdf-viewer-search-dialog-input-button-height;
                justify-content: center;
            }

            .k-search-matches {
                display: inline-block;
                min-width: $pdf-viewer-search-dialog-matches-width;
                margin: 0 $pdf-viewer-search-dialog-spacing 0 $pdf-viewer-search-dialog-button-spacing;
                vertical-align: middle;
            }

            > .k-button {
                margin-left: $pdf-viewer-search-dialog-button-spacing;
            }

            .k-search-dialog-draghandle  {
                cursor: move;
                margin-left: 0;
            }
        }
    }

}
