@include exports("imageeditor/layout") {

    // Image Editor
    .k-imageeditor {
        @include use-background-clip();
        margin: $imageeditor-margin-y $imageeditor-margin-x;
        padding: $imageeditor-padding-y $imageeditor-padding-x;
        border-width: $imageeditor-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $imageeditor-font-family;
        font-size: $imageeditor-font-size;
        line-height: $imageeditor-line-height;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
    }

    // Content
    .k-imageeditor-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    // Canvas
    .k-imageeditor-canvas-container {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
    }

    .k-imageeditor-canvas {
        margin: auto;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    // Action Pane
    .k-imageeditor-action-pane {
        border-width: $imageeditor-content-border-width;
        border-top-width: 0;
        border-style: solid;
        flex-shrink: 0;
        overflow-y: auto;
    }
    .k-imageeditor-action-pane > .k-form {
        padding: $imageeditor-action-pane-padding-y $imageeditor-action-pane-padding-x;
    }

    // Crop Tool
    .k-imageeditor-crop-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .k-imageeditor-crop {
        width: 100%;
        height: 100%;
        border-width: $imageeditor-crop-border-width;
        border-style: $imageeditor-crop-border-style;
        box-sizing: border-box;
        position: absolute;

        .k-resize-handle {
            margin: $imageeditor-resize-handle-margin-y $imageeditor-resize-handle-margin-x;
            border-style: solid;
            border-width: $imageeditor-resize-handle-border-width;
        }

        .k-resize-sw,
        .k-resize-se,
        .k-resize-nw,
        .k-resize-ne {
            width: $imageeditor-resize-handle-size;
            height: $imageeditor-resize-handle-size;
        }

        .k-resize-n,
        .k-resize-s {
            left: calc( 50% - (#{$imageeditor-resize-handle-size / 2}) );
            width: $imageeditor-resize-handle-size;
            height: 0;
        }

        .k-resize-w,
        .k-resize-e {
            top: calc( 50% - (#{$imageeditor-resize-handle-size / 2}) );
            width: 0;
            height: $imageeditor-resize-handle-size;
        }

        .k-resize-ne {
            transform: rotate(-90deg);
        }

        .k-resize-nw {
            transform: rotate(-180deg);
        }


        .k-resize-sw {
            transform: rotate(90deg);
        }

        .k-resize-n {
            top: 0;
        }

        .k-resize-s {
            bottom: 0;
        }

        .k-resize-w {
            left: 0;
        }

        .k-resize-e {
            right: 0;
        }
    }

}

@include exports("imageeditor/layout/IE") {
    .k-ie {
        .k-imageeditor-canvas {
            flex-direction: row;
            align-self: flex-start;
        }

        .k-imageeditor-action-pane {
            flex: 0 0 auto;
        }
    }
}

