@include exports("dataviz/barcode/layout") {

    .k-barcode {
        display: inline-block;
    }

}


@include exports("dataviz/charts/layout") {

    // Exported variables
    .k-var--chart-font {
        font-size: $chart-font-size;
    }

    .k-var--chart-title-font {
        font-size: $chart-title-font-size;
    }

    .k-var--chart-pane-title-font {
        font-size: $chart-pane-title-font-size;
        font-weight: $chart-pane-title-font-weight;
    }

    .k-var--chart-label-font {
        font-size: $chart-label-font-size;
    }

    // Elements
    .k-chart,
    .k-sparkline,
    .k-stockchart {
        border-width: $chart-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $chart-font-family;
        font-size: $chart-font-size;
        line-height: $chart-line-height;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $chart-tap-highlight-color;
    }

    .k-chart,
    .k-stockchart {
        display: block;
        height: 400px;
    }

    .k-chart-surface {
        height: 100%;
    }

    .k-chart .k-popup {
        border-width: 0;
    }

    .k-chart-tooltip-wrapper .k-animation-container-shown,
    .k-chart-tooltip-wrapper.k-animation-container-shown {
        transition: left ease-in 80ms, top ease-in 80ms;
    }

    .k-sparkline-tooltip-wrapper,
    .k-chart-tooltip-wrapper {
        z-index: 12000;

        > .k-popup {
            padding: 0;
            border-width: 0;
            background: transparent;
        }
    }

    .k-chart-tooltip table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    .k-chart-tooltip {
        @include border-radius( $border-radius );
        font-size: $chart-tooltip-font-size;
        line-height: $line-height;
        padding: $tooltip-padding-y $tooltip-padding-x;
    }

    .k-chart-tooltip th {
        width: auto;
        text-align: center;
        padding: 1px;
    }

    .k-chart-tooltip td {
        width: auto;
        text-align: left;
        padding: $padding-y-sm $padding-x-sm;
        line-height: $line-height;
        vertical-align: middle;
    }

    .k-chart-crosshair-tooltip,
    .k-chart-shared-tooltip {
        border-width: 1px;
        border-style: solid;
    }

    .k-chart-shared-tooltip .k-chart-shared-tooltip-marker {
        display: block;
        width: 15px;
        height: 3px;
        vertical-align: middle;
    }

    // Selection
    .k-selector {
        position: absolute;
        transform: translateZ(0);
    }

    .k-selection {
        position: absolute;
        height: 100%;
        border-width: 1px;
        border-style: solid;
        border-bottom: 0;
    }

    .k-selection-bg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .k-handle {
        @include border-radius( 50% );
        width: $selection-handle-size;
        height: $selection-handle-size;
        border-width: 1px;
        border-style: solid;
        z-index: 1;
        position: absolute;
        box-sizing: content-box;
    }

    .k-handle div {
        width: 100%;
        height: 100%;
    }

    .k-left-handle {
        left: -($selection-handle-size / 2);
    }

    .k-right-handle {
        right: -($selection-handle-size / 2);
    }

    .k-left-handle div {
        margin: -($selection-handle-size) 0 0 (-($selection-handle-size / 1.5));
        padding: ($selection-handle-size * 2) (($selection-handle-size / 1.5) * 2) 0 0;
    }

    .k-right-handle div {
        margin: -($selection-handle-size) 0 0 (-($selection-handle-size / 1.5));
        padding: ($selection-handle-size * 2) 0 0 (($selection-handle-size / 1.5) * 2);
    }

    .k-left-handle.k-handle-active div {
        margin-left: -($selection-handle-size * 2);
        padding-left: ($selection-handle-size * 2) + ($selection-handle-size / 1.5);
    }

    .k-right-handle.k-handle-active div {
        margin-left: -($selection-handle-size * 2);
        padding-right: ($selection-handle-size * 2) + ($selection-handle-size / 1.5);
    }

    .k-mask {
        position: absolute;
        height: 100%;
    }

    .k-border {
        width: 1px;
        height: 100%;
        position: absolute;
    }

    .k-marquee {
        position: absolute;
        z-index: 100000;
    }

    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // Navigator hint
    .k-navigator-hint div {
        position: absolute;
    }

    .k-navigator-hint .k-scroll {
        @include border-radius( $border-radius );
        position: absolute;
        height: 4px;
    }

    .k-navigator-hint .k-tooltip {
        margin-top: 20px;
        min-width: 160px;
        opacity: 1;
        text-align: center;
    }

    // Sparklines
    .k-sparkline,
    .k-sparkline span {
        display: inline-block;
        vertical-align: top;
    }

    .k-sparkline span {
        height: 100%;
        width: 100%;
    }

    .k-chart-dragging {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .k-chart-donut-center {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        text-align: center;

        border: 4px solid transparent;
        box-sizing: border-box;
    }

    .k-pdf-export {
        .k-chart,
        .k-sparkline,
        .k-stockchart {
            .k-animation-container {
                display: none;
            }
        }
    }
}


@include exports("dataviz/diagram/layout") {

    .k-diagram {
        height: 600px;
    }

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .k-canvas-container {
        width: 100%;
        height: 100%;
    }

    // IE8-
    .k-diagram img {
        box-sizing: content-box;
    }
}


@include exports("dataviz/treemap/layout") {

    // Base
    .k-treemap {
        height: 400px;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        outline: 0;
        font-family: $treemap-font-family;
        font-size: $treemap-font-size;
        line-height: $treemap-line-height;
        display: block;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Tile
        .k-treemap-tile {
            margin: -1px 0 0 -1px;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid;
            border-color: inherit;
            color: inherit;
            background-color: inherit;
            overflow: hidden;
            position: absolute;
        }
        > .k-treemap-tile {
            position: relative;
        }

        // Title
        .k-treemap-title {
            padding: $padding-y-sm $padding-x-sm;
            border-width: 0 0 1px;
            border-style: solid;
            border-color: inherit;
            font-size: $font-size;
            background-position: 0 0;
            background-repeat: repeat-x;
        }
        .k-treemap-title-vertical {
            padding: $padding-x-sm $padding-y-sm;
            width: $line-height-em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            top: 0;
            bottom: 0;

            > div {
                transform-origin: right;
                transform: rotate(-90deg);
                position: absolute;
                top: 0;
                right: 1em;
            }
        }


        // Wrap
        .k-treemap-wrap {
            border-color: inherit;
            color: inherit;
            background-color: inherit;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .k-treemap-title + .k-treemap-wrap {
            top: calc( #{$padding-y-sm * 2} + (#{$line-height} * #{$font-size}) + 1px);
        }
        .k-treemap-title-vertical + .k-treemap-wrap {
            left: calc( #{$padding-y-sm * 2} + (#{$line-height} * #{$font-size}) + 1px);
        }


        // Leaf
        .k-leaf {
            padding: $padding-x-sm;
        }
    }
}


@include exports("dataviz/gauge/layout") {

    .k-arcgauge {
        display: inline-block;
    }

    .k-arcgauge-label {
        position: absolute;
        text-align: center;
        padding: 0;
        margin: 0;
    }

}




@include exports("dataviz/qrcode/layout") {

    .k-qrcode {
        display: inline-block;
    }

}




@include exports("chart/variables") {

    // export variables to allow use in scripts
    $exported: (
        accent: $primary,
        accent-contrast: $primary-contrast,
        primary: $primary,
        primary-contrast: $primary-contrast,
        base: $base-bg,
        background: $chart-bg,

        border-radius: $border-radius,

        normal-background: $base-bg,
        normal-text-color: $base-text,
        hover-background: $hovered-bg,
        hover-text-color: $hovered-text,
        selected-background: $selected-bg,
        selected-text-color: $selected-text,

        success: $success,
        info: $info,
        warning: $warning,
        error: $error,

        series-a: $series-a,
        series-b: $series-b,
        series-c: $series-c,
        series-d: $series-d,
        series-e: $series-e,
        series-f: $series-f,

        series-1: $series-1,
        series-2: $series-2,
        series-3: $series-3,
        series-4: $series-4,
        series-5: $series-5,
        series-6: $series-6,
        series-7: $series-7,
        series-8: $series-8,
        series-9: $series-9,
        series-10: $series-10,
        series-11: $series-11,
        series-12: $series-12,
        series-13: $series-13,
        series-14: $series-14,
        series-15: $series-15,
        series-16: $series-16,
        series-17: $series-17,
        series-18: $series-18,
        series-19: $series-19,
        series-20: $series-20,
        series-21: $series-21,
        series-22: $series-22,
        series-23: $series-23,
        series-24: $series-24,
        series-25: $series-25,
        series-26: $series-26,
        series-27: $series-27,
        series-28: $series-28,
        series-29: $series-29,
        series-30: $series-30,

        gauge-pointer: $primary,
        gauge-track: darken($chart-bg, 10%)
    );

    @each $name, $value in $exported {
        $type: type-of($value);

        .k-var--#{$name} {
            @if $type == "color" {
                // background-color can store any color
                background-color: $value;
            } @else if $type == "number" {
                // margin-top can store positive & negative values
                margin-top: $value;
            }
        }
    }
}
