@include exports("radio/theme") {

    // Radio
    .k-radio {
        @include fill(
            $radio-text,
            $radio-bg,
            $radio-border
        );
    }


    // Hover state
    .k-radio:hover {
        @include fill(
            $radio-hovered-text,
            $radio-hovered-bg,
            $radio-hovered-border
        );
    }


    // Focus state
    .k-radio:focus {
        @include fill( $border: $radio-focused-border );
        @include box-shadow( $radio-focused-shadow );
    }


    // Invalid state
    .k-radio.k-invalid,
    .k-radio.k-state-invalid,
    .k-radio.ng-invalid.ng-touched,
    .k-radio.ng-invalid.ng-dirty {
        @include fill( $border: $radio-invalid-border );
    }
    .k-radio.k-invalid + .k-radio-label,
    .k-radio.k-state-invalid + .k-radio-label,
    .k-radio.ng-invalid.ng-touched + .k-radio-label,
    .k-radio.ng-invalid.ng-dirty + .k-radio-label {
        @include fill( $color: $radio-invalid-text );
    }


    // Checked
    .k-radio:checked {
        @include fill(
            $radio-checked-text,
            $radio-checked-bg,
            $radio-checked-border
        );
    }

    .k-radio:checked:focus {
        @include fill( $border: $radio-focused-checked-border );
        @include box-shadow( $radio-focused-checked-shadow );
    }


    // Disabled
    .k-radio:disabled {
        @include fill(
            $radio-disabled-text,
            $radio-disabled-bg,
            $radio-disabled-border
        );
    }
    .k-radio:checked:disabled {
        @include fill(
            $radio-disabled-checked-text,
            $radio-disabled-checked-bg,
            $radio-disabled-checked-border
        );
    }

    // Ripple
    .k-ripple-container {
        .k-radio::after {
            background: $checkbox-checked-bg;
            opacity: $checkbox-ripple-opacity;
        }
    }

}
