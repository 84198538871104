@include exports( "input/layout" ) {

    // Generic input
    .k-input {
        margin: 0;
        padding: $input-padding-y $input-padding-x;
        width: 100%;
        min-width: 0;
        height: $input-inner-calc-height;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        // Targets https://github.com/telerik/kendo-react/issues/638.
        box-shadow: none;
        color: inherit;
        background: none;
        font: inherit;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        // Hide clear icon
        &::-ms-clear { display: none; }

        // Placeholder text
        &::placeholder {
            color: $input-placeholder-text;
            opacity: $input-placeholder-opacity;
            user-select: none;
        }
        &:-ms-input-placeholder {
            color: $input-placeholder-text;
        }

        // Invalid
        &:invalid {
            box-shadow: none;
        }
    }


    // TODO: Deprecate
    // Input spacing
    .k-space-left,
    .k-space-right {
        > input {
            flex-grow: 1;
        }

        > .k-icon {
            left: 3px;
            margin: 3px 6px;
        }
    }

    .k-textbox.k-space-left {
        flex-direction: row-reverse;
    }


    // Input prefix and suffix
    .k-input-prefix,
    .k-input-suffix {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: $button-inner-calc-size;

        > * {
            flex-shrink: 0;
        }

        > .k-icon {
            width: $input-adornment-width;
            height: $input-adornment-height;
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
        > .k-clear-value {
            width: $input-adornment-width;
            height: $input-adornment-height;
            position: relative;
            opacity: .5;

            &:hover {
                opacity: 1;
            }

            &::after {
                content: "";
                opacity: 0;
                display: none;
                pointer-events: none;
                position: absolute;
                left: -1px;
                right: -1px;
                top: -1px;
                bottom: -1px;
            }

            &.k-state-focused::after,
            &:focus::after {
                display: block;
            }
        }
    }

    .k-input-separator {
        margin: 0;
        width: 0;
        height: $icon-size;
        border-width: 0 0 0 1px;
        border-style: solid;
    }

    // Input with icon styles
    .k-input-icon {
        width: $input-icon-width;
        height: $input-icon-height;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .k-ie9 & {
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
        }

        .k-rtl &,
        [dir="rtl"] & {
            .k-ie9 & {
                left: 0;
                right: auto;
            }
        }
    }


    // Clear value
    .k-clear-value {
        width: $input-icon-width;
        height: $input-icon-height;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: 0;
        cursor: pointer;
    }


    // Searchbar
    .k-searchbar {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
    }

    .k-text-disabled {
        @include disabled( $disabled-styling );
    }

}




@include exports("input/layout/jQuery-label") {

}




@include exports("input/layout/autofill-fix") {

    // This is needed for the textbox container to capture browser autofill
    @keyframes autoFillStart {
        from {}
        to {}
    }
    @keyframes autoFillEnd {
        from {}
        to {}
    }

    // Generic class for autofill animations
    .k-autofill:-webkit-autofill {
        animation-name: autoFillStart;
    }
    .k-autofill:not(:-webkit-autofill) {
        animation-name: autoFillEnd;
    }


    .k-textbox:-webkit-autofill {
        animation-name: autoFillStart;
    }
    .k-textbox:not(:-webkit-autofill) {
        animation-name: autoFillEnd;
    }

}
