// Switch
$switch-size: 6em !default;
$switch-border-radius: $switch-size !default;

$switch-font-family: $font-family !default;
$switch-font-size: $font-size-xs !default;
$switch-line-height: $line-height !default;

$switch-track-size: null !default;
$switch-container-border-width: 0px !default;
$switch-container-padding-x: 0px !default;
$switch-container-padding-y: $switch-container-padding-x !default;

$switch-handle-size: (.5 * $switch-size) !default;
$switch-handle-border-radius: $switch-size !default;
$switch-handle-border-width: 1px !default;

$switch-label-display: inline !default;
$switch-label-outer-spacing: 8px !default;
$switch-label-inner-spacing: 2px !default;
$switch-label-spacing: calc( #{$switch-label-outer-spacing} + #{$switch-label-inner-spacing} ) !default;
$switch-label-occupied-space: calc( -1 * ( #{$switch-handle-size} + #{$switch-label-spacing} + #{$switch-container-padding-x} ) ) !default;
$switch-label-text-transform: uppercase !default;

$switch-label-position: calc( #{$switch-label-outer-spacing} - #{$switch-container-border-width} ) !default;
$switch-label-width: calc( 100% + #{$switch-label-occupied-space} ) !default;
$switch-on-left-x: calc( 100% - #{$switch-handle-size} ) !default;

$switch-container-shadow: inset 0 0 0 1px $base-border !default;
$switch-container-hovered-shadow: inset 0 0 0 1px $hovered-border !default;
$switch-container-focused-shadow: inset 0 0 0 3px rgba( black, .06) !default;

$switch-on-bg: $primary !default;
$switch-on-text: contrast-wcag( $switch-on-bg ) !default;
$switch-on-border: null !default;
$switch-on-gradient: null !default;
$switch-on-shadow: null !default;

$switch-on-focused-bg: null !default;
$switch-on-focused-text: null !default;
$switch-on-focused-border: null !default;
$switch-on-focused-gradient: null !default;
$switch-on-focused-shadow: null !default;

$switch-on-hovered-bg: shade( $switch-on-bg ) !default;
$switch-on-hovered-text: $switch-on-text !default;
$switch-on-hovered-border: null !default;
$switch-on-hovered-gradient: null !default;

$switch-on-handle-bg: darken( white, 5 ) !default;
$switch-on-handle-text: $switch-on-text !default;
$switch-on-handle-border: $base-border !default;
$switch-on-handle-gradient: null !default;
$switch-on-handle-shadow: null !default;

$switch-on-handle-hovered-bg: darken( white, 7 ) !default;
$switch-on-handle-hovered-text: $switch-on-text !default;
$switch-on-handle-hovered-border: $hovered-border !default;
$switch-on-handle-hovered-gradient: null !default;

$switch-off-bg: $component-bg !default;
$switch-off-text: $component-text !default;
$switch-off-border: null !default;
$switch-off-gradient: null !default;
$switch-off-shadow: null !default;

$switch-off-focused-bg: null !default;
$switch-off-focused-text: null !default;
$switch-off-focused-border: null !default;
$switch-off-focused-gradient: null !default;
$switch-off-focused-shadow: null !default;

$switch-off-hovered-bg: $switch-off-bg !default;
$switch-off-hovered-text: $switch-off-text !default;
$switch-off-hovered-border: null !default;
$switch-off-hovered-gradient: null !default;

$switch-off-handle-bg: $switch-on-handle-bg !default;
$switch-off-handle-text: $switch-off-text !default;
$switch-off-handle-border: $switch-on-handle-border !default;
$switch-off-handle-gradient: null !default;
$switch-off-handle-shadow: null !default;

$switch-off-handle-hovered-bg: $switch-on-handle-hovered-bg !default;
$switch-off-handle-hovered-text: $switch-off-text !default;
$switch-off-handle-hovered-border: $switch-on-handle-hovered-border !default;
$switch-off-handle-hovered-gradient: null !default;
