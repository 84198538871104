// Calendar
$calendar-border-width: 1px !default;
$calendar-font-family: $font-family !default;
$calendar-font-size: $font-size !default;
$calendar-line-height: $line-height !default;

$calendar-header-padding-x: map-get( $spacing, 1 ) !default;
$calendar-header-padding-y: map-get( $spacing, 1 ) !default;
$calendar-header-border-width: 1px !default;

$calendar-header-min-width: 280px !default;

$calendar-infinite-header-padding-x: map-get( $spacing, 4 ) !default;
$calendar-infinite-header-padding-y: map-get( $spacing, 2 ) !default;

$calendar-infinite-content-padding-x: map-get( $spacing, 4 ) !default;
$calendar-infinite-content-padding-y: map-get( $spacing, 2 ) !default;

$calendar-footer-padding-x: map-get( $spacing, 4 ) !default;
$calendar-footer-padding-y: map-get( $spacing, 2 ) !default;

$calendar-cell-padding-x: .5em !default;
$calendar-cell-padding-y: $calendar-cell-padding-x !default;
$calendar-cell-line-height: $calendar-line-height !default;
$calendar-cell-size-px: 34px !default;
$calendar-cell-size: ($calendar-cell-size-px / $font-size) * 1em !default;
$calendar-cell-border-radius: $border-radius !default;

$calendar-header-cell-padding-x: 0px !default;
$calendar-header-cell-padding-y: ($calendar-cell-padding-y / 2) !default;
$calendar-header-cell-font-size: $font-size-sm !default;
$calendar-header-cell-line-height: 2 !default;

$calendar-header-height: 2em !default;

$calendar-navigation-width: 5em !default;
$calendar-navigation-item-height: 2em !default;

$calendar-view-width: 7 * $calendar-cell-size !default;
$calendar-view-height: calc( #{$calendar-cell-size * 8} + #{$calendar-header-height} ) !default;
$calendar-view-gap: map-get( $spacing, 4 ) !default;

$calendar-bg: $component-bg !default;
$calendar-text: $component-text !default;
$calendar-border: $component-border !default;

$calendar-header-bg: $header-bg !default;
$calendar-header-text: $header-text !default;
$calendar-header-border: $header-border !default;
$calendar-header-gradient: $header-gradient !default;
$calendar-header-shadow: 0 1px 3px 1px rgba(black, .1) !default;

$calendar-header-button-hovered-bg: $button-hovered-bg !default;
$calendar-header-button-hovered-text: $button-hovered-text !default;
$calendar-header-button-hovered-border: $button-hovered-border !default;
$calendar-header-button-hovered-gradient: $button-hovered-gradient !default;

$calendar-header-button-pressed-bg: $button-pressed-bg !default;
$calendar-header-button-pressed-text: $button-pressed-text !default;
$calendar-header-button-pressed-border: $button-pressed-border !default;
$calendar-header-button-pressed-gradient: $button-pressed-gradient !default;
$calendar-header-button-pressed-shadow: $button-pressed-shadow !default;

$calendar-header-cell-text: null !default;
$calendar-header-cell-opacity: $disabled-opacity !default;

$calendar-navigation-bg: $calendar-header-bg !default;
$calendar-navigation-text: $calendar-header-text !default;
$calendar-navigation-border: $calendar-header-border !default;

$calendar-nav-gap: map-get( $spacing, 1 ) !default;
$calendar-today-nav-text: $link-text !default;
$calendar-today-nav-hovered-text: $link-hover-text !default;

$calendar-weekend-text: inherit !default;
$calendar-weekend-bg: transparent !default;
$calendar-weekend-opacity: 1 !default;

$calendar-today-style: color !default;
$calendar-today-color: $primary !default;

$calendar-week-number-bg: transparent !default;
$calendar-week-number-text: $calendar-header-cell-text !default;
$calendar-week-number-opacity: $calendar-header-cell-opacity !default;

$calendar-other-month-opacity: $calendar-header-cell-opacity !default;

$calendar-cell-hovered-bg: $node-hovered-bg !default;
$calendar-cell-hovered-text: $node-hovered-text !default;
$calendar-cell-hovered-border: $node-hovered-border !default;

$calendar-cell-selected-bg: $node-selected-bg !default;
$calendar-cell-selected-text: $node-selected-text !default;
$calendar-cell-selected-border: $node-selected-border !default;

$calendar-cell-focused-shadow: $list-item-focused-shadow !default;
$calendar-cell-selected-focus-shadow: $calendar-cell-focused-shadow !default;


// Multiview Calendar
$multiviewcalendar-button-style: stretched !default;
$multiviewcalendar-button-size: 44px !default;
$multiviewcalendar-gap: ($calendar-infinite-content-padding-x * 2) !default;
$multiviewcalendar-content-padding-y: 0px !default;
$multiviewcalendar-navigation-margin-x: 0px !default;
$multiviewcalendar-navigation-margin-y: calc( #{$calendar-header-padding-y * 2} + #{$calendar-header-height} + #{$button-border-width * 2} ) !default;
