@include exports("datetime/theme") {

    // Common
    .k-dateinput,
    .k-datetimepicker {

        .k-link {

            &:hover,
            &.k-state-hover {
                @include fill(
                    $datetime-select-hovered-text,
                    $datetime-select-hovered-bg,
                    $datetime-select-hovered-border,
                    $datetime-select-hovered-gradient
                );
            }
        }
    }

    .k-datepicker,
    .k-timepicker {

        .k-select {

            &:hover,
            &.k-state-hover {
                @include fill(
                    $datetime-select-hovered-text,
                    $datetime-select-hovered-bg,
                    $datetime-select-hovered-border,
                    $datetime-select-hovered-gradient
                );
            }
        }
    }

    // Shared
    .k-datepicker,
    .k-timepicker,
    .k-datetimepicker,
    .k-dateinput {
        @extend .k-text-selection !optional;
        // Normal state
        .k-picker-wrap,
        .k-dateinput-wrap {
            @include fill(
                $datetime-text,
                $datetime-bg,
                $datetime-border
            );

            // Hovered state
            &:hover,
            &.k-state-hover {
                @include fill(
                    $datetime-hovered-text,
                    $datetime-hovered-bg,
                    $datetime-hovered-border
                );

            }

            // Selected state
            &.k-state-focused,
            &.k-state-active {
                @include fill(
                    $datetime-focused-text,
                    $datetime-focused-bg,
                    $datetime-focused-border
                );
                @include box-shadow($datetime-focused-shadow);

                .k-select {}
            }
        }

        .k-select {
            @include fill(
                $datetime-select-text,
                $datetime-select-bg,
                $datetime-select-border,
                $datetime-select-gradient
            );

            > .k-state-selected,
            > .k-state-active {
                @include fill(
                    $datetime-select-hovered-text,
                    $datetime-select-hovered-bg,
                    $datetime-select-hovered-border,
                    $datetime-select-hovered-gradient
                );
                color: darken($body-text, 25%);
                box-shadow: inset $datetime-select-focused-shadow;
            }
        }

        .k-i-warning {
            color: $error;
        }
    }

    // Timepicker
    .k-timepicker {}


    // Timepicker header
    .k-time-header {

        .k-time-now {
            color: $primary;
            background: transparent;

            &:hover,
            &:focus {
                color: $link-hover-text;
            }
        }
    }

    // Timepicker content
    .k-time-list-wrapper {
        background-color: $popup-bg;

        .k-title {
            @include fill(
                $header-text,
                $header-bg,
                $header-border,
                $header-gradient
            );
            background: $popup-bg;
            opacity: $disabled-opacity;
        }

        &.k-state-focused {
            .k-title {
                color: $time-list-title-focus;
                opacity: 1;
            }

            &::before,
            &::after {
                background-color: $time-list-focused-bg;
            }
        }
    }

    .k-time-list {
        &::before,
        &::after {
            $shadow-size: 3em;
            box-shadow: 0 0 $shadow-size ($shadow-size / 2) $popup-bg;
        }

        .k-item:hover {
            color: $primary;
        }
    }

    .k-time-container {
        background: transparent;
    }

    .k-time-highlight {
        background-color: $component-bg;
        border-color: $time-list-highlight-color;
    }

    .k-datetime-container {
        .k-date-tab {

            .k-datetime-buttongroup,
            .k-datetime-selector {
                background-color: $component-bg;
            }
        }
    }

    // Dateinput
    .k-dateinput {

        // Normal state
        .k-dateinput-wrap {
            @include fill(
                $datetime-text,
                $datetime-bg,
                $datetime-border
            );
        }

        // Hovered state
        &:hover,
        &.k-state-hover {
            @include fill(
                $datetime-hovered-text,
                $datetime-hovered-bg,
                $datetime-hovered-border
            );

        }

        // Focused state
        &:focus,
        &.k-state-focused {
            .k-picker-wrap > & {
                box-shadow: none;
            }

        }
    }

    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-timepicker {
        .k-dateinput-wrap,
        .k-picker-wrap {
            // Invalid
            &.k-invalid,
            &.k-invalid:hover,
            &.k-state-invalid {
                border-color: $invalid-border;

                &:focus,
                &.k-state-focused {
                    @include box-shadow($invalid-shadow);
                }
            }
        }
        // Invalid
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            .k-dateinput-wrap,
            .k-picker-wrap {
                border-color: $invalid-border;

                &:focus,
                &.k-state-focused {
                    @include box-shadow($invalid-shadow);
                }
            }
        }
    }
}
