@include exports("treelist/theme") {

    // Theme
    .k-treelist {
        tr.k-footer,
        tr.k-footer-template {
            background-color: $treelist-footer-row-bg;
        }
    }

}
