@include exports("grid/layout") {

    $filter-rows-span-size: calc( #{$line-height-em} + #{$input-padding-y * 2} + #{$input-border-width * 2}) !default;

    $grid-group-dropclue-size: 6px;
    $grid-group-dropclue-line-size: $grid-group-dropclue-size / 3;
    $grid-group-dropclue-height: $button-calc-size !default;
    $grid-group-dropclue-top: 3px !default;
    $grid-group-drag-clue-opacity: 1 !default;

    $edit-cell-input-space: calc(-#{$cell-padding} - #{$input-border-width}) !default;
    $edit-cell-textbox-width: calc(100% + ((#{$cell-padding} + #{$input-border-width}) * 2)) !default;

    $grid-row-inner-height: calc( #{$line-height-em} + #{$cell-padding-y * 2} );
    // $grid-row-half-inner-height: calc( #{$line-height-em / 2} + #{$cell-padding-y} );

    // be cautious when changing the next line; see https://github.com/MoOx/reduce-css-calc/issues/38
    $grid-command-cell-padding-y: calc( #{$cell-padding-y} - (#{$button-calc-size} - #{$line-height-em}) / 2 ) !default;

    $grid-form-component-vertical-align: middle !default;
    $grid-hierarchy-col-width: ($icon-size * 2) !default;

    $grid-group-indicator-border-radius: $border-radius !default;
    $grid-group-indicator-gap: $cell-padding-y !default;

    $grid-grouping-row-border-top: 1px !default;
    $grid-group-footer-border-y: 1px !default;
    $grid-group-footer-second-cell-border: 1px !default;
    $grid-header-first-border: 1px !default;
    $grid-header-menu-icon-spacing: $icon-spacing !default;
    $grid-sorted-icon-spacing: calc( #{$padding-x} - 1px ) !default;

    // helper variables
    $grid-filterable-icon-spacing: calc( #{$button-calc-size} + #{$grid-header-menu-icon-spacing} );
    $default-scrollbar-width: 17px;

    .k-grid {
        @include use-background-clip();
        border-width: $grid-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $grid-font-family;
        font-size: $grid-font-size;
        line-height: $grid-line-height;
        display: flex;
        flex-direction: column;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        &.k-grid-display-block {
            display: block;
        }

        .k-grid-container {
            display: flex;
            flex: 1 1 auto;
            overflow: hidden;
            position: relative;
        }

        .k-grid-aria-root {
            border-color: inherit;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow: hidden;
        }

        table {
            margin: 0;
            width: 100%;
            max-width: none;
            border-width: 0;
            border-color: inherit;
            border-collapse: separate;
            border-spacing: 0;
            empty-cells: show;
            outline: 0;
        }
        thead,
        tbody,
        tfoot {
            text-align: left;
            border-color: inherit;
        }
        tr {
            border-color: inherit;
        }
        th,
        td {
            padding: $grid-cell-padding-y $grid-cell-padding-x;
            border-style: solid;
            border-color: inherit;
            outline: 0;
            font-weight: inherit;
            text-align: inherit;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                border-left-width: 0;
            }

            &:focus {
                outline: none;
            }

            .k-placeholder-line {
                width: $grid-cell-placeholder-line-width;
            }
        }
        th {
            padding: $grid-header-padding-y $grid-header-padding-x;
            border-width: 0 0 1px $grid-cell-vertical-border-width;
            white-space: nowrap;
        }
        td {
            border-width: 0 0 $grid-cell-horizontal-border-width $grid-cell-vertical-border-width;
            vertical-align: middle;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
        a:hover {
            text-decoration: none;
        }

        table,
        .k-grid-header-wrap {
            box-sizing: content-box;
        }

        // Grouping and hierarcy
        .k-group-col,
        .k-hierarchy-col {
            padding: 0;
            width: $grid-hierarchy-col-width;
        }

        .k-grouping-row p {
            margin: 0;
            display: flex;
            align-items: center;
            align-content: center;
        }
        .k-grouping-row td {
            overflow: visible;
        }
        .k-grouping-row + tr td {
            border-top-width: $grid-grouping-row-border-top;
        }
        .k-grouping-row .k-group-cell,
        .k-grouping-row + tr .k-group-cell {
            border-top-width: 0;
            text-overflow: clip;
        }

        .k-grouping-row .k-icon {
            margin-left: calc( #{$icon-size / 2} - #{$cell-padding-x} );
            margin-right: ($icon-size / 2);
            text-decoration: none;
        }

        .k-group-footer td {
            border-style: solid;
            border-width: $grid-group-footer-border-y 0;
        }
        .k-group-footer .k-group-cell + td {
            border-left-width: $grid-group-footer-second-cell-border;
        }

        .k-hierarchy-cell {
            text-align: center;
            padding: 0;
            overflow: visible;

            > .k-icon {
                padding: $cell-padding-y 0;
                width: 100%;
                height: 100%;
                // That causes an issue with grid material. Commenting it for now
                // -- joneff
                // line-height: $line-height;
                display: inline-block;
                outline: 0;
            }
        }

        .k-hierarchy-cell + td {
            border-left-width: 0;
        }

        .k-hierarchy-cell + .k-grid-content-sticky {
            border-left-width: $grid-cell-vertical-border-width;
        }
        .k-detail-cell {}
        .k-master-row {}
        .k-detail-row {}

        &[dir = "rtl"],
        .k-rtl & {
            thead,
            tbody,
            tfoot {
                text-align: right;
            }

            th {
                white-space: nowrap;
            }

            .k-grid-header-wrap {
                border-width: 0 0 0 $grid-cell-vertical-border-width;
            }

            .k-group-indicator,
            .k-drag-clue {
                .k-button.k-bare {
                    margin-left: -( $icon-spacing / 2 );
                    margin-right: ( 2 * $icon-spacing );
                }

                .k-link .k-icon {
                    margin-left: $icon-spacing;
                    margin-right: -( $icon-spacing / 2);
                }
            }
            .k-group-indicator {
                margin-right: 0;
                margin-left: ( $grid-group-indicator-gap / 2 );
            }

            .k-group-indicator + .k-group-indicator {
                margin-right: ( $grid-group-indicator-gap / 2 );
            }

            .k-grid-content-locked,
            .k-grid-footer-locked,
            .k-grid-header-locked {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-grid-header-sticky,
            .k-grid-content-sticky,
            .k-grid-footer-sticky {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-header-sticky:not([style*="display: none"]) + td,
            .k-grid-header-sticky:not([style*="display: none"]) + th,
            .k-grid-content-sticky:not([style*="display: none"]) + td,
            .k-grid-content-sticky:not([style*="display: none"]) + th {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-grid-header-sticky.k-header:first-child {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-row-sticky {
                border-bottom-width: $grid-border-width;
                border-top-width: $grid-border-width;
            }

            th {
                border-width: 0 $grid-cell-vertical-border-width 1px 0;

                &:first-child {
                    border-right-width: 0;
                }
            }

            td {
                border-width: 0 $grid-cell-vertical-border-width 0 0;

                &:first-child {
                    border-right-width: 0;
                }
            }

            td.k-hierarchy-cell {
                border-right-width: 0;
            }

            .k-hierarchy-cell + td {
                border-right-width: 0;
            }

            .k-grid-header,
            .k-grid-footer {
                padding-left: $default-scrollbar-width;
                padding-right: 0;
            }

            .k-grid-header {
                .k-header {
                    &.k-first {
                        border-left-width: 0;
                        border-right-width: $grid-cell-vertical-border-width;
                    }
                }

                .k-with-icon,
                .k-filterable {
                    padding-left: $grid-filterable-icon-spacing;
                    padding-right: $grid-cell-padding-x;
                }

                .k-grid-filter,
                .k-header-column-menu {
                    right: auto;
                    left: $grid-header-menu-icon-spacing;
                }
            }

            .k-grid-header-sticky.k-header.k-first {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-filtercell-operator {
                margin-left: 0;
                margin-right: $cell-padding-y / 2;
            }

            .k-dirty {
                border-color: currentColor currentColor transparent transparent;
                left: auto;
                right: 0;
            }

            .k-edit-cell,
            .k-grid-edit-row > td {
                >.k-textbox,
                >.k-widget {
                    margin-right: $edit-cell-input-space;
                }
            }

            .k-grid-header-wrap.k-auto-scrollable {
                margin-left: -1px;
                margin-right: 0;
            }

            .k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
                margin-left: 0;
            }

            .k-group-footer td {
                border-width: $grid-group-footer-border-y 0;
            }
            .k-group-footer .k-group-cell + td {
                border-right-width: $grid-group-footer-second-cell-border;
            }

            .k-grouping-row .k-icon {
                margin-left: #{$icon-size / 2};
                margin-right: calc( #{$icon-size / 2} - #{$cell-padding-x} );
            }

            .k-column-resizer {
                left: 0;
                right: auto;
            }
        }

        .k-dirty-cell {
            position: relative;

            &.k-grid-content-sticky {
                position: sticky;
            }
        }

        .k-dirty {
            border-width: 5px;
            border-color: currentColor transparent transparent currentColor;
            left: 0;
            right: auto;
        }

        .k-grid-content-locked + .k-grid-content {
            box-sizing: content-box;
        }

        .k-grid-content-expander {
            position: absolute;
            visibility: hidden;
            height: 1px;
            bottom: 1px; // fix for vertical scrollbar appearing when no records template is displayed
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-hierarchy-cell .k-icon {
            border-width: 0;
        }
    }

    // Toolbar
    .k-grid-toolbar {
        border-width: 0 0 1px;
        flex-shrink: 0;
    }


    // Grid search
    .k-grid-search {
        width: $grid-search-width;
    }


    // Grouping header
    .k-grouping-header {
        display: block;
        position: relative;
        white-space: normal;
        padding: $grid-grouping-header-padding-y $grid-grouping-header-padding-x;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: inherit;
        line-height: $button-calc-size;
    }

    .k-grouping-header-flex {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        // use item padding instead of margin, in order to position the reorder clue correctly
        $header-half-padding-y: $grid-grouping-header-padding-y / 2;
        padding: $header-half-padding-y 0;

        > .k-indicator-container {
            display: inline-flex;
            margin: 0;
            padding: $header-half-padding-y 0 $header-half-padding-y $grid-grouping-header-padding-x;

            &:last-child {
                display: block;
                flex-grow: 1;
                line-height: $button-calc-size;
            }
        }
    }

    .k-group-indicator,
    .k-drag-clue {
        @include border-radius( $grid-group-indicator-border-radius );
        margin: 0;
        padding: $button-padding-y $button-padding-x;
        border-width: 1px;
        border-style: solid;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        vertical-align: top;
        opacity: $grid-group-drag-clue-opacity;
        box-sizing: border-box;

        .k-link,
        .k-button.k-bare {
            padding: 0;
            border-width: 0;
            display: inline-flex;
            align-items: center;
        }

        .k-link .k-icon {
            margin-left: -( $icon-spacing / 2);
            margin-right: $icon-spacing;
        }

        .k-button.k-bare {
            margin-left: ( 2 * $icon-spacing );
            margin-right: -( $icon-spacing / 2 );
            padding: 0;
            width: auto;
            height: auto;
            opacity: .5;

            &::before,
            &::after {
                display: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .k-group-indicator {
        margin-right: ( $grid-group-indicator-gap / 2 );
        line-height: $grid-group-drag-clue-line-height;
    }

    .k-group-indicator + .k-group-indicator {
        margin-left: ( $grid-group-indicator-gap / 2 );
    }

    .k-drag-clue {
        line-height: $line-height-lg;
    }

    .k-grouping-dropclue {
        width: ($grid-group-dropclue-size * 2);
        height: $grid-group-dropclue-height;
        position: absolute;
        top: $grid-group-dropclue-top;
        box-sizing: content-box;

        &::before,
        &::after {
            display: inline-block;
            content: "";
            position: absolute;
        }

        &::before {
            border-width: $grid-group-dropclue-size;
            border-style: solid;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            top: 0;
        }

        &::after {
            width: $grid-group-dropclue-line-size;
            height: calc(100% - #{$grid-group-dropclue-size});
            top: $grid-group-dropclue-size;
            left: calc( #{$grid-group-dropclue-size} - #{$grid-group-dropclue-line-size / 2} );
        }
    }


    // Grid header / footer
    .k-grid-header-wrap,
    .k-grid-footer-wrap {
        width: 100%;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: inherit;
        position: relative;
        overflow: hidden;
    }

    .k-grid-header-wrap.k-auto-scrollable,
    .k-grid-footer-wrap {
        margin-right: -1px;
    }

    .k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
        margin-right: 0;

        .k-ie & {
            display: inline-block;
        }
    }

    .k-grid-header,
    .k-grid-footer {
        flex: 0 0 auto;
        padding-right: $default-scrollbar-width;
        border-width: 0;
        border-style: solid;
        border-color: inherit;

        table {
            table-layout: fixed;
        }
    }

    div.k-grid-header,
    div.k-grid-footer {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .k-ie div.k-grid-header {
        display: block;
    }

    .k-grid-header {
        border-bottom-width: 1px;
        font-size: $grid-header-font-size;

        table {
            margin-bottom: -1px;
        }

        &.k-grid-draggable-header {
            user-select: none;
            touch-action: none;
        }

        .k-header {
            position: relative;
            vertical-align: bottom;
            cursor: default;

            &:first-child {
                border-left-width: 0;
            }

            &.k-first {
                border-left-width: $grid-header-first-border;
            }
        }

        .k-header > .k-link {
            margin: (-$grid-header-padding-y) (-$grid-header-padding-x);
            padding: $grid-header-padding-y $grid-header-padding-x;
            line-height: inherit;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            outline: 0;

            .k-ie &,
            .k-edge & {
                margin: 0;
                padding: 0;
            }

        }

        .k-with-icon,
        .k-filterable {
            padding-right: $grid-filterable-icon-spacing;

            .k-ie &,
            .k-edge & {
                padding-right: calc( #{ $button-calc-size } + #{ $grid-header-menu-icon-spacing });
            }
        }

        .k-header > .k-link:focus {
            text-decoration: none;
        }

        .k-grid-filter,
        .k-header-column-menu {
            padding: $button-padding-y;
            width: $button-calc-size;
            height: $button-calc-size;
            box-sizing: border-box;
            outline: 0;
            line-height: $form-line-height;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            position: absolute;
            right: $grid-header-menu-icon-spacing;
            bottom: calc( #{ $grid-header-padding-y } + #{ $line-height-em / 2 } - #{ $button-inner-calc-size / 2 } );
            z-index: 1;

            .k-ie &,
            .k-edge & {
                position: static;
                float: right;
                margin-top: calc( (#{ $button-calc-size } - #{ $form-line-height * 1em}) / -2);
                margin-bottom: calc( (#{ $button-calc-size } - #{ $form-line-height * 1em}) / -2);
                margin-right: calc( -1 * #{ $button-calc-size });
            }

            &:hover {
                cursor: pointer;
            }
        }

        .k-header > .k-link > .k-icon {
            &.k-i-sort-desc-sm,
            &.k-i-sort-asc-sm {
                vertical-align: text-top;
                margin-left: $grid-sorted-icon-spacing;
            }
        }

        .k-sort-order {
            display: inline-block;
            vertical-align: top;
            height: $icon-size;
            font-size: $grid-sorting-index-font-size;
            margin-top: $grid-sorting-index-spacing-y;
            margin-left: $grid-sorting-index-spacing-x;
        }
    }

    .k-column-resizer {
        width: .5em;
        height: 1000%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        cursor: col-resize;
    }

    .k-grid-footer {
        border-width: 1px 0 0;

        td {
            height: $line-height-em;
        }
    }

    .k-grid-footer td,
    .k-group-footer td,
    .k-grouping-row td {
        font-weight: bold;
    }

    .k-grid-filter-popup {
        min-width: 200px;
    }

    // Standalone column menu
    .k-grid-column-menu-standalone a.k-grid-filter {
        position: initial;
        display: inline-block;
        margin: -4px 0;
        padding: 4px 8px;
    }

    .k-grid-columnmenu-popup {
        width: 230px;

        &.k-popup {
            padding: $grid-column-menu-popup-padding-y $grid-column-menu-popup-padding-x;
        }
    }

    // Filter row
    .k-filter-row {
        line-height: $form-line-height;

        td,
        th {
            border-width: 0 0 1px $grid-cell-vertical-border-width;
            white-space: nowrap;
            padding: $grid-filter-cell-padding-y $grid-filter-cell-padding-x;
        }

        td:first-child {
            border-left-width: 0;
        }

        .k-multiselect {
            height: auto;
        }

        .k-filtercell .k-widget.k-sized-input {
            flex: 0 1 auto;
        }
    }

    .k-filtercell {
        width: auto;
        display: flex;

        > span,
        .k-filtercell-wrapper {
            display: flex;
            flex: 1 1 auto;

            > label {
                vertical-align: middle;
            }
        }

        .k-filtercell-wrapper > .k-widget {
            width: 100%;
        }

        > span {
            .k-button {
                visibility: visible;
                pointer-events: all;
            }

            .k-button,
            .k-dropdown-operator {
                margin-left: $grid-cell-padding-y / 2;
            }

            .k-widget {
                width: auto;
            }
        }

        .k-filtercell-operator {
            > .k-button.k-clear-button-visible {
                visibility: visible;
                height: $button-calc-size;
            }

            > .k-button:not(.k-clear-button-visible) {
                visibility: hidden;
                pointer-events: none;
            }
        }

        .k-filtercell-operator {
            margin-left: $grid-cell-padding-y / 2;
        }

        .k-widget:not(.k-dropdown-operator),
        .k-filtercell-wrapper > .k-textbox {
            display: flex;
            flex: 1 1 auto;
        }

        .k-filtercell-wrapper > .k-textbox {
            width: 100%;
            min-width: 0;
        }

    }

    // Grid content
    .k-grid-content,
    .k-grid-content-locked {
        border-color: inherit;
        outline: 0;

        table {
            table-layout: fixed;
        }
        tr:last-child > td {
            border-bottom-width: 0;
        }
    }
    .k-grid-content {
        width: 100%;
        min-height: 0;
        overflow: auto;
        overflow-x: auto;
        overflow-y: scroll;
        position: relative;
        flex: 1;
    }

    // Virtualization
    .k-virtual-scrollable-wrap {
        height: 100%;
        overflow-y: hidden;
        position: relative;
    }


    // Edit row
    .k-grid-edit-row {

        td {
            text-overflow: clip;
        }

        .k-widget,
        .k-button,
        .k-textbox {
            height: auto;
            // Commented out pending a better approach
            //     margin-top: -$input-padding-y;
            //     margin-bottom: -$input-padding-y;
        }

        .k-edit-cell {
            padding-top: 0;
            padding-bottom: 0;
        }

        .k-dirty-cell {
            overflow: visible;
        }
    }

    .k-edit-cell,
    .k-grid-edit-row > td {
        > .k-textbox,
        > .k-widget:not(.k-switch) {
            margin-left: $edit-cell-input-space;
        }
    }

    .k-grid-edit-row td,
    .k-edit-cell {
        > .k-textbox,
        > .k-widget:not(.k-switch) {
            width: $edit-cell-textbox-width;
        }
    }

    .k-grid-edit-row td > .k-textbox,
    .k-grid-edit-row td > .k-widget,
    .k-command-cell > .k-button,
    .k-edit-cell > .k-textbox,
    .k-edit-cell > .k-widget {
        vertical-align: $grid-form-component-vertical-align;
    }

    .k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
    .k-grid .k-command-cell,
    .k-grid .k-edit-cell {
        padding: $grid-command-cell-padding-y $grid-cell-padding-x;
    }

    .k-command-cell > .k-button {
        margin-left: $grid-command-cell-button-spacing;
        margin-right: $grid-command-cell-button-spacing;
    }

    // Resize handle
    .k-grid > .k-resize-handle,
    .k-grid-header .k-resize-handle {
        height: 25px;
        cursor: col-resize;
        position: absolute;
        z-index: 2;
    }


    // Pager
    .k-grid-pager {
        border-width: 1px 0 0;
        border-color: inherit;
        font-size: inherit;
    }
    .k-grid-pager-top {
        border-width: 0 0 1px;
    }

    .k-pager-input {
        .k-numerictextbox {
            margin: 0 $font-size / 2;
            width: 3em;
        }
    }

    .k-grid-virtual .k-grid-content {
        .k-grid-table-wrap {
            float: left;
            width: 100%;
        }

        .k-grid-table {
            position: relative;
            float: left;
            z-index: 1;
        }

        > .k-height-container {
            position: relative;
            float: left;
        }

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .k-width-container {
        position: absolute;
        visibility: hidden;
    }

    .k-width-container  div {
        height: 1px;
    }

    .k-grid-virtual[dir="rtl"],
    .k-grid-virtual.k-rtl {
        .k-grid-content .k-grid-table {
            float: right;
        }
    }

    .k-grid-add-row td {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }


    // Locked columns
    .k-grid-lockedcolumns {
        white-space: nowrap;
    }

    .k-grid-content,
    .k-grid-content-locked,
    .k-pager-wrap {
        white-space: normal;
    }

    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked {
        flex: 0 0 auto;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        position: relative;
        border-style: solid;
        border-width: 0 1px 0 0;
        box-sizing: content-box;

        & + .k-grid-content.k-auto-scrollable {
            display: inline-block;
        }
    }

    .k-grid-content,
    .k-grid-footer-wrap,
    .k-grid-header-wrap {
        flex: 1 1 auto;
        display: inline-block;
        vertical-align: top;

        &.k-auto-scrollable {
            display: block;
        }
    }

    .k-grid-header-locked>table,
    .k-grid-header-wrap>table {
        margin-bottom: -1px;
    }

    .k-grid-header .k-header.k-grid-header-sticky,
    .k-grid-header .k-filter-row .k-grid-header-sticky,
    .k-grid-content-sticky,
    .k-grid-row-sticky,
    .k-grid-footer-sticky {
        position: sticky;
        z-index: 2;

        &.k-edit-cell {
            overflow: visible;
            z-index: 3;
        }
    }

    .k-grid-content-sticky.k-grid-row-sticky {
        z-index: 3;
    }

    .k-grid .k-grid-header-sticky,
    .k-grid .k-grid-content-sticky,
    .k-grid .k-grid-footer-sticky {
        border-right-width: $grid-cell-vertical-border-width;

        &:not([style*="display: none"]) + td,
        &:not([style*="display: none"]) + th {
            border-left-width: 0;
        }
    }

    .k-grid .k-grid-row-sticky {
        border-bottom-width: $grid-border-width;
        border-top-width: $grid-border-width;
    }

    .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
        border-left-width: 0;
    }

    .k-header.k-drag-clue {
        overflow: hidden;
    }

    .k-grid.k-grid-no-scrollbar {
        .k-grid-header {
            padding: 0;
        }

        .k-grid-header-wrap {
            border-width: 0;
        }

        .k-grid-content {
            overflow-y: auto;
        }
    }

    .k-grid-norecords {
        text-align: center;
    }

    div.k-grid-norecords {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .k-grid-norecords-template {
        width: 20em;
        height: 4em;
        line-height: 4em;
        margin: 0 auto;
        border: 1px solid;
    }

    .k-header > .k-cell-inner {
        margin: (-1 * $grid-header-padding-y) calc(-1 * calc( #{$button-calc-size} - #{$grid-cell-padding-x})) (-1 * $grid-header-padding-y) (-1 * $grid-cell-padding-x);
    }

    .k-filterable {
        > .k-cell-inner {
            margin: (-1 * $grid-header-padding-y) calc(-1 * #{$button-calc-size}) (-1 * $grid-header-padding-y) (-1 * $grid-cell-padding-x);

            .k-link {
                padding-right: 0;
            }
        }
    }

    .k-cell-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        overflow: hidden;

        > .k-link {
            padding: $grid-header-padding-y $grid-header-padding-x;
            width: auto;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 1;
            overflow: hidden;

            > .k-icon.k-i-sort-desc-sm,
            > .k-icon.k-i-sort-asc-sm {
                margin-left: 0;
                display: inline-block;
                flex-shrink: 0;
            }

        }

        .k-sort-order {
            flex-shrink: 0;
            line-height: normal;
        }

        .k-grid-filter,
        .k-header-column-menu {
            position: static;
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-hierarchy-cell .k-icon {
            text-align: center;
            display: inline-block;
            flex-shrink: 0;
        }
    }

    .k-column-title {
        min-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
        overflow: hidden;
    }
}

@include exports( "grid/pdf-export" ) {
    // PDF export
    .k-loading-pdf-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;

        > .k-i-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 64px;
        }

        .k-loading-pdf-progress {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

    }

    .k-pdf-export .k-loading-pdf-mask {
        display: none;
    }

    .k-grid-pdf-export-element {
        position: absolute;
        left: -10000px;
        top: -10000px;

        .k-filter-row {
            display: none;
        }
    }

    // Remove Grid scrollbar during built-in export
    .k-pdf-export-shadow {
        .k-grid {
            float: left;
            width: auto !important; // sass-lint:disable-line no-important
        }

        // Remove all sizes and scrolling
        .k-grid,
        .k-grid-content,
        .k-grid-content-locked {
            height: auto !important; // sass-lint:disable-line no-important
            overflow: visible;
        }

        .k-grid-header-locked {
            & + .k-grid-header-wrap,
            & + .k-grid-content,
            & + .k-grid-footer-wrap {
                width: auto !important; // sass-lint:disable-line no-important
            }
        }

        // Remove empty space reserved above the scrollbar
        .k-grid-header,
        .k-grid[data-role="grid"] .k-grid-footer {
            padding: 0 !important; // sass-lint:disable-line no-important
        }
    }
}

@include exports( "filtermenu/layout" ) {

    .k-filter-menu {
        $item-spacing: $spacer-y / 2;
        $form-padding: $spacer-y;
        $form-padding-y: $form-padding - $item-spacing;

        .k-filter-menu-container {
            padding: $form-padding-y $form-padding;
        }

        .k-widget,
        .k-radio-list,
        .k-textbox {
            margin: $grid-filter-menu-item-spacing-y $grid-filter-menu-item-spacing-x;
            width: 100%;
            display: block;
        }

        .k-searchbox {
            margin: $grid-filter-menu-item-spacing-y $grid-filter-menu-item-spacing-x;
            width: 100%;
        }

        .k-widget.k-filter-and {
            width: 6em;
        }

        .k-widget.k-button-group {
            width: auto;
            display: inline-flex;
        }

        .k-switch {
            width: $switch-size;
            display: inline-flex;
        }

        .k-actions,
        .k-action-buttons,
        .k-columnmenu-actions {
            margin-left: -$form-padding;
            margin-right: -$form-padding;
            margin-bottom: -$form-padding;
            padding: $grid-column-menu-item-padding-y $form-padding $form-padding;

            .k-button {
                flex: 1 1 50%;
            }
        }

        .k-actions {
            &.k-actions-start,
            &.k-actions-center,
            &.k-actions-end {
                .k-button {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .k-multicheck-wrap {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
        white-space: nowrap;
    }
    .k-filter-selected-items {
        font-weight: bold;
        line-height: normal;
        white-space: nowrap;
        margin: $spacer-y 0 ($spacer / 2);
    }

    // autofitting requires automatic table layout to measure sizes
    .k-autofitting {
        // sass-lint:disable-block no-important

        width: auto !important;
        table-layout: auto !important;

        th.k-header,
        td {
            white-space: nowrap !important;
        }

        .k-detail-row {
            display: none !important;
        }
    }

}

@include exports("columnmenu/layout") {
    .k-column-list {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .k-column-chooser-title,
    .k-column-list-item {
        padding: $grid-column-menu-list-item-padding-y $grid-column-menu-list-item-padding-x;
    }

    .k-columnmenu-actions {
        padding: $grid-filter-menu-item-spacing-y $grid-column-menu-list-item-padding-x;
    }

    .k-column-list-item {
        position: relative;
        display: block;
        margin: 0;
        cursor: pointer;
    }

    .k-columns-items-wrap {
        padding: $grid-column-menu-items-wrap-padding-y $grid-column-menu-items-wrap-padding-x;
    }

    .k-columnmenu-item {
        padding: $grid-column-menu-item-padding-y $grid-column-menu-item-padding-x;
        outline: 0;
        cursor: pointer;

        > .k-icon {
            margin-right: $icon-spacing;
        }
    }

    .k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
        border-top: 1px solid;
        border-top-color: $popup-border;
    }

    .k-columnmenu-item-content {
        overflow: hidden;

        .k-filter-menu .k-filter-menu-container {
            padding: $grid-column-menu-filter-container-padding-y $grid-column-menu-filter-container-padding-x;
        }
    }

    .k-column-list-wrapper,
    .k-columnmenu-item-content {

        .k-actions,
        .k-action-buttons,
        .k-columnmenu-actions {
            .k-button {
                flex: 1 1 50%;
            }
        }

        .k-actions {
            &.k-actions-start,
            &.k-actions-center,
            &.k-actions-end {
                .k-button {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .k-column-menu-group-header {
        padding: $grid-column-menu-group-header-padding-y $grid-column-menu-group-header-padding-x;
        display: flex;
        flex: 0 0 auto;
        position: relative;
        user-select: none;
    }

    .k-column-menu-group-header-text {
        border-bottom-width: $grid-column-menu-group-header-border-bottom-width;
        border-bottom-style: solid;
        font-size: $grid-column-menu-group-header-font-size;
        line-height: $grid-column-menu-group-header-line-height;
        font-weight: bold;
        text-transform: $grid-column-menu-group-header-text-transform;
        flex: 1 1 auto;
    }

    .k-column-menu {
        .k-menu:not(.k-context-menu) {
            font-weight: 400;
        }

        .k-expander {
            background: inherit;

            .k-columnmenu-item {
                display: flex;
                align-items: center;
            }
        }
    }

    [dir="rtl"],
    .k-rtl {
        .k-columnmenu-item  > .k-icon {
            margin-left: $icon-spacing;
            margin-right: 0;
        }

        .k-header > .k-cell-inner {
            margin-right: (-1 * $grid-cell-padding-x);
            margin-left: calc(-1 * calc( #{$button-calc-size} - #{$grid-cell-padding-x}));

            .k-sort-order {
                margin-right: $grid-sorting-index-spacing-x;
                margin-left: 0;
            }
        }

        .k-filterable {
            > .k-cell-inner {
                margin-right: (-1 * $grid-cell-padding-x);
                margin-left: calc(-1 * #{$button-calc-size});

                .k-link {
                    padding-right: $grid-header-padding-x;
                    padding-left: 0;
                }
            }
        }
    }
}
