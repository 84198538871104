@include exports("timeline/theme") {

    .k-timeline {
        .k-timeline-flag {
            color: $timeline-flag-text;
            background-color: $timeline-flag-bg;
        }

        .k-timeline-circle {
            background-color: $timeline-circle-bg;
        }

        .k-timeline-card {
            .k-card-header {
                background-color: $card-bg;
                color: $card-text;
            }

            .k-card-body {

                // Scrollbar styles for Mozilla
                scrollbar-color: $card-border $card-bg;

                // Scrollbar styles for Chrome, Safari and Opera
                &::-webkit-scrollbar-track {
                    background: $card-bg;
                }

                &::-webkit-scrollbar-thumb {
                    background: $card-border;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $hovered-border;
                }
            }
        }

        .k-timeline-date {
            color: $body-text;
        }

        .k-timeline-arrow.k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
            opacity: 1; // The arrow button in disabled mode should have a solid background
            color: $timeline-track-arrow-disabled-text;
            background-color: $timeline-track-arrow-disabled-bg;
            border-color: $timeline-track-arrow-disabled-border;
        }
    }

    .k-timeline-vertical,
    .k-timeline-horizontal .k-timeline-track-wrap {
        &::after {
            background-color: $timeline-track-bg;
            border-color: $timeline-track-border-color;
        }
    }

    .k-timeline-horizontal .k-timeline-flag::after {
        background-color: $timeline-flag-bg;
    }

    .k-timeline-track-item.k-state-focus .k-timeline-circle {
        @include box-shadow( $timeline-track-item-focus-shadow );
    }
}
