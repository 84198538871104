@include exports("toolbar/theme") {

    // Theme
    .k-toolbar {
        @include fill(
            $toolbar-text,
            $toolbar-bg,
            $toolbar-border,
            $toolbar-gradient
        );
        @include box-shadow( $toolbar-shadow );


        // Overflow anchor
        .k-overflow-anchor {
            color: inherit;
            background: transparent;
        }


        // Separator
        .k-separator {
            border-color: $toolbar-separator-border;
        }


        // Button disabled state
        .k-button.k-state-disabled {
            &::after {
                opacity: 0;
            }
        }


        // Button group
        .k-button-group {}

    }

}




@include exports("floating-toolbar/theme") {

    // sass-lint:disable-block class-name-format
    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content {
        @include fill(
            $toolbar-text,
            $toolbar-bg,
            $toolbar-border,
            $toolbar-gradient
        );
    }

}
