
@include exports("popup/layout") {

    .k-popup {
        @include use-background-clip();
        @include border-radius( $popup-border-radius );
        margin: 0;
        padding: $popup-padding-y $popup-padding-x;
        border-width: $popup-border-width;
        border-style: solid;
        box-sizing: border-box;
        font-size: $popup-font-size;
        line-height: $popup-line-height;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        > .k-widget {
            border-width: 0;
        }

        &.k-colorpicker-popup {
            display: flex;
            align-items: center;
        }

        .k-item {
            outline: none;
        }

        .k-rtl,
        [dir="rtl"] & {
            .k-list-optionlabel {
                text-align: right;
            }
        }
    }
    .k-animation-container {
        @include border-bottom-radius-only( $border-radius );

        .k-popup {}
    }

    .k-animation-container-shown {
        overflow: visible;
    }

    .k-popup > kendo-list > .k-first {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    // Virtual list -> K1 - virtuallist.less
    .k-popup .k-item.k-first {
        position: relative;
    }

    .k-popup > .k-group-header,
    .k-popup > .k-virtual-wrap > .k-group-header {
        padding: $popup-header-padding;
        min-height: $line-height-em;
        font-size: $popup-header-font-size;
        white-space: normal;
        transition: $transition;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
    .k-popup > .k-group-header {
        margin-top: -$popup-padding-y;
    }

    .k-popup .k-outer-group-header {
        padding: $popup-header-padding;
        min-height: $line-height-em;
        font-size: $popup-header-font-size;
        white-space: normal;

        &.k-virtual-item {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .k-popup > .k-colorpalette {
        padding: $popup-content-padding-y $popup-content-padding-x;
    }

    .k-popup .k-list .k-item > .k-group {
        padding: 0 .5em;
        font-size: ($font-size * .714);
        line-height: $font-size;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        right: 0;
    }
    .k-popup .k-list .k-item > .k-group::before {
        content: "";
        width: $font-size;
        height: $font-size;
        border-width: ($font-size / 2);
        border-style: solid;
        box-sizing: border-box;
        display: block;
        position: absolute;
        left: -$font-size;
        bottom: 0;
    }
    .k-rtl.k-popup .k-list .k-item > .k-group,
    [dir="rtl"] .k-popup .k-list .k-item > .k-group {
        right: auto;
        left: 0;

        &::before {
            right: -14px;
            left: auto;
        }
    }
    .k-group-header+div>.k-list>.k-item.k-first::before {
        content: " ";
        display: block;
        border-top-width: 1px;
        border-top-style: solid;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
    }

    .k-popup .k-list > .k-outer-group-header {
        border-top-width: 1px;
        border-top-style: solid;
    }
    // Transparent popup
    .k-popup.k-popup-transparent {
        border-width: 0;
        background-color: transparent;
    }

    // Flush popup
    .k-popup.k-popup-flush {
        padding: 0;
    }
}


@include exports( "list/layout" ) {

    // Layout
    .k-list-scroller {
        position: relative;
        overflow: auto;
    }
    .k-list {
        height: auto;

        > .k-item {
            display: flex;
            align-items: center;
            align-content: center;
            cursor: pointer;

            > .k-icon {
                align-self: center;
                margin-right: $padding-x;
            }
        }

        > .k-custom-item {
            font-style: italic;

            > .k-i-plus {
                margin-left: auto;
                margin-right: 0;
            }
        }

        [dir="rtl"] & {
            > .k-item {
                > .k-icon {
                    margin-right: 0;
                    margin-left: $padding-x;
                }
            }
        }
    }

    .k-list .k-item,
    .k-list-optionlabel {
        padding: $list-item-padding-y $list-item-padding-x;
        min-height: $line-height-em;
        line-height: $line-height-em;
        white-space: normal;
        transition-property: color, background-color, outline-color, box-shadow;
        transition-duration: 200ms;
        transition-timing-function: ease;
    }
    .k-list-optionlabel {
        cursor: pointer;
        outline: none;
    }


    // Filter
    .k-list-filter {
        display: block;
        position: relative;
        padding: $padding-x;
        box-sizing: border-box;
        flex: 1 0 auto;

        > .k-textbox {
            // remove the !important declaration when https://github.com/telerik/kendo-ui-core/issues/2617 is fixed
            width: 100% !important; // sass-lint:disable-block no-important
            box-sizing: border-box;
            padding-left: $popup-filter-padding-x;
            padding-right: calc( #{$icon-size} + #{$padding-x} );
        }

        > .k-icon {
            position: absolute;
            right: calc( #{$input-padding-x} + #{$padding-x} );
            top: 50%;
            transform: translateY(-50%);
        }


        .k-rtl &,
        [dir="rtl"] & {

            > .k-textbox {
                padding-right: $popup-filter-padding-x;
                padding-left: calc( #{$icon-size} + #{$padding-x} );
            }
            > .k-icon {
                left: calc( #{$input-padding-x} + #{$padding-x} );
                right: auto;
            }

        }
    }

    .k-dropdown-button .k-popup {
        .k-item {
            cursor: pointer;
        }
    }

    //SplitButton
    //TODO: Wait for final design + revise
    .k-split-button,
    .k-dropdown-button {
        .k-list .k-item:focus {
            outline: none;
        }
    }

}




@include exports( "listcontainer/layout" ) {

    .k-list-container {

        .k-button {
            @include border-radius( 0 );
            padding: $list-item-padding-y $list-item-padding-x;
            border-width: 0;
            color: inherit;
            background-color: transparent;
            background-image: none;
            line-height: inherit;
            display: flex;
            justify-content: flex-start;

            .k-icon {
                margin: 0 $icon-spacing 0 0;
            }

            .k-ie11 & {
                display: flex;
            }
        }

        // Button disabled state
        .k-button.k-state-disabled {
            pointer-events: none;
        }

        .k-nodata {
            min-height: 138px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-weight: lighter;
            text-align: center;
            white-space: normal;

            .k-button {
                display: inline-flex;
                border-width: 1px;
            }
        }

        .k-separator {
            height: 0;
        }
    }

    .k-virtual-content {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;

        > .k-virtual-list > .k-virtual-item {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
        }
    }

}



@include exports( "common/decoration/shadow/legacy" ) {

    .k-shadow {
        box-shadow: $popup-shadow;
    }

}
