@include exports("scrollview/theme") {

    kendo-scrollview.k-scrollview-wrap,
    kendo-scrollview.k-scrollview,
    div.k-scrollview {
        @extend .k-text-selection !optional;
        @include fill(
            $scrollview-text,
            $scrollview-bg,
            $scrollview-border
        );

        &.k-scrollview-dark {
            kendo-scrollview-pager,
            .k-scrollview-nav-wrap {
                background-color: $scrollview-dark-bg;
            }
            .k-scrollview-next,
            .k-scrollview-prev {
                color: $scrollview-dark-bg;

            }
        }

        &.k-scrollview-light {
            kendo-scrollview-pager,
            .k-scrollview-nav-wrap {
                background-color: $scrollview-light-bg;
            }

        }
    }

    .k-scrollview:focus,
    .k-scrollview-wrap:focus  {
        outline: none;

    }
    .k-scrollview-elements {
        color: $scrollview-navigation-color;
    }

    .k-scrollview-next,
    .k-scrollview-prev {
        color: $scrollview-navigation-color;
        background-color: $scrollview-navigation-bg;
        text-shadow: $scrollview-navigation-icon-shadow;
        opacity: $scrollview-navigation-default-opacity;
        outline-width: 0;
        -webkit-tap-highlight-color: $scrollview-arrow-tap-highlight-color;

        &:focus,
        &.k-state-focus {
            color: $scrollview-navigation-color;
            opacity: $scrollview-navigation-hover-opacity;

            .k-icon::before {
                box-shadow: $scrollview-pagebutton-shadow;
            }
        }

        &:hover,
        &.k-state-hover {
            color: $scrollview-navigation-color;
            opacity: $scrollview-navigation-hover-opacity;

        }
    }

    // refactor when https://github.com/telerik/kendo-angular-scrollview/issues/130 is done
    .k-scrollview-pageable > .k-button,
    .k-scrollview-nav > .k-link {
        background: $scrollview-pagebutton-bg;
        border: $scrollview-pagebutton-border;
        background-clip: padding-box;

        &.k-primary {
            background: $scrollview-pagebutton-primary-bg;
            border: $scrollview-pagebutton-primary-border;
        }

        &:focus,
        &.k-state-focused {
            box-shadow: $scrollview-pagebutton-shadow;
        }
    }

    .k-scrollview-nav > .k-link:hover,
    .k-scrollview-nav > .k-link.k-state-hover {
        box-shadow: $scrollview-pagebutton-shadow;
    }

    .k-scrollview-pageable > .k-button {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, .08);
    }

}
