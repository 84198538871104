@include exports("progressbar/layout") {

    // Base
    .k-progressbar {
        @include border-radius( $border-radius );
        --kendo-progressbar-progress: 0;
        border-width: $progressbar-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $progressbar-font-family;
        font-size: $progressbar-font-size;
        line-height: $progressbar-line-height;
        display: inline-grid;
        vertical-align: middle;
        position: relative;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;


        // Selection
        > .k-state-selected {
            border-width: 0;
            border-style: solid;
            display: flex;
            overflow: hidden;
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }


        // Chunks
        > ul {
            border-width: inherit;
            border-style: inherit;
            white-space: nowrap;
            display: flex;
            align-items: stretch;
            flex: 1;
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }
        .k-item {
            display: block;
            border-width: 0;
            border-style: solid;
            border-color: transparent;
        }

    }


    // Status
    .k-progress-status-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        grid-column: 1 / -1;
        grid-row: 1 / -1;

        &.k-progress-start { justify-content: flex-start; }
        &.k-progress-center { justify-content: center; }
        &.k-progress-end { justify-content: flex-end; }
    }
    .k-progress-status {
        padding: 0 ($spacer-x / 2);
        padding-block-start: 0;
        padding-block-end: 0;
        padding-inline-start: ($spacer-x / 2);
        padding-inline-end: ($spacer-x / 2);
        min-width: 10px;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
    }


    // Horizontal
    .k-progressbar-horizontal {
        width: 27em;
        height: $progressbar-height;
        grid-template-columns: 1fr;
        grid-template-rows: 100%;


        .k-progress-status-wrap {
            flex-direction: row;
        }

        > .k-state-selected {
            width: 0;
            width: calc( var( --kendo-progressbar-progress, 0 ) * 1% ); // sass-lint:disable-line no-duplicate-properties
            flex-direction: row;

            > .k-progress-status-wrap {
                width: calc( 100% * ( 100 / var( --kendo-progressbar-progress, 1 ) ) );
            }
        }


        // Chunks
        > ul {
            flex-direction: row;
        }
        .k-item {}
        .k-item + .k-item {
            border-left-width: 1px;
            border-inline-start-width: 1px;
            border-inline-end-width: 0;
        }


        // Horizontal reverse
        &.k-progressbar-reverse {

            > .k-state-selected {
                flex-direction: row-reverse;
                justify-self: flex-end;
            }

        }
    }


    // Vertical
    .k-progressbar-vertical {
        width: $progressbar-height;
        height: 27em;
        grid-template-columns: 100%;
        grid-template-rows: 1fr;


        .k-progress-status-wrap {
            flex-direction: column-reverse;
        }
        .k-progress-status {
            writing-mode: vertical-lr;
        }

        > .k-state-selected {
            height: 0;
            height: calc( var( --kendo-progressbar-progress, 0 ) * 1% ); // sass-lint:disable-line no-duplicate-properties
            flex-direction: column-reverse;
            align-self: flex-end;
            align-items: flex-end;

            > .k-progress-status-wrap {
                height: calc( 100% * ( 100 / var( --kendo-progressbar-progress, 1 ) ) ); // sass-lint:disable-line no-duplicate-properties
            }
        }


        // Chunk
        > ul {
            flex-direction: column;
        }
        .k-item {}
        .k-item + .k-item {
            border-top-width: 1px;
            border-block-start-width: 1px;
            border-block-end-width: 0;
        }


        // Vertical reverse
        &.k-progressbar-reverse {
            flex-direction: column;

            > .k-state-selected {
                flex-direction: column;
                align-self: flex-start;
            }
        }
    }


    // Indeterminate
    .k-progressbar-indeterminate {
        .k-progress-status-wrap,
        .k-state-selected,
        .k-item {
            display: none;
        }
    }


    // Blazor specific
    .telerik-blazor.k-progressbar-horizontal {

        > .k-state-selected,
        > .k-state-selected > .k-progress-status-wrap {
            transition: width .1s ease-in-out;
        }
    }

}




@include exports("progressbar/layout/rtl") {

    .k-rtl .k-progressbar,
    .k-progressbar[dir="rtl"] {
        direction: rtl;
    }

}




@include exports("progressbar/layout/ie11") {

    .k-progressbar {
        display: -ms-inline-grid;

        > .k-progress-status-wrap,
        > .k-state-selected {
            -ms-grid-column: 1;
            -ms-grid-row: 1;
        }
    }
    .k-progressbar-horizontal {
        -ms-grid-columns: 1fr;
        -ms-grid-rows: $progressbar-height;

        &.k-progressbar-reverse > .k-state-selected {
            -ms-grid-column-align: end;
        }
    }
    .k-progressbar-vertical {
        -ms-grid-columns: $progressbar-height;
        -ms-grid-rows: 1fr;

        > .k-state-selected {
            -ms-grid-row-align: end;
        }

        &.k-progressbar-reverse > .k-state-selected {
            -ms-grid-row-align: start;
        }
    }

}
