@include exports("drawer/layout") {

    .k-drawer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
    kendo-drawer.k-drawer,
    kendo-drawer .k-drawer-wrapper {
        transition: none;
    }
    .k-drawer {
        height: 100%;
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $drawer-font-family;
        font-size: $drawer-font-size;
        line-height: $drawer-line-height;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        transition: all 300ms ease-in-out; // sass-lint:disable-line no-transition-all
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Borders
        .k-drawer-mini &.k-drawer-start,
        .k-drawer-expanded &.k-drawer-start,
        .k-drawer-left.k-drawer-mini &,
        .k-drawer-left.k-drawer-expanded & {
            border-right-width: $drawer-border-width;
        }
        .k-drawer-mini &.k-drawer-end,
        .k-drawer-expanded &.k-drawer-end,
        .k-drawer-right.k-drawer-mini &,
        .k-drawer-right.k-drawer-expanded & {
            border-left-width: $drawer-border-width;
        }

        // Position
        &.k-drawer-start,
        .k-drawer-left & {
            top: 0;
            left: 0;
        }
        &.k-drawer-end,
        .k-drawer-right & {
            top: 0;
            right: 0;
        }
    }
    .k-drawer-content {
        flex: 1 1 auto;
    }


    // Overlay drawer
    .k-drawer-overlay {

        .k-drawer {
            max-width: 80vw; // limit width
            position: fixed;
            z-index: 10002;
            border: 0;
        }

        .k-overlay {
            display: none;
        }
        &.k-drawer-expanded .k-overlay {
            display: block;
        }

    }


    // Push drawer
    .k-drawer-push {
        display: flex;
        flex-direction: row;

        .k-drawer {
            height: auto;
            position: relative;
            align-self: stretch;
        }


        // right drawer
        .k-drawer.k-drawer-end,
        &.k-drawer-right .k-drawer {
            order: 1;
        }

    }


    // Drawer items
    .k-drawer-wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin; // Scrollbar styles for Mozilla
        transition: all 300ms ease-in-out; // sass-lint:disable-line no-transition-all

        // Scrollbar styles for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            width: $drawer-scrollbar-width;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: $drawer-scrollbar-radius;
        }
    }
    .k-drawer-items,
    .k-drawer-items ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .k-drawer-item {
        padding: $drawer-item-padding-y $drawer-item-padding-x;
        outline: 0;
        color: inherit;
        font-size: $drawer-item-font-size;
        line-height: $drawer-item-line-height;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        cursor: pointer;

        > .k-drawer-link {
            margin: (-$drawer-item-padding-y) (-$drawer-item-padding-x);
            padding: $drawer-item-padding-y $drawer-item-padding-x;
            color: inherit;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            flex: 1 0 auto;
        }

        .k-icon {
            margin: 0 $drawer-item-padding-x;
            height: auto;
            font-size: $drawer-icon-size;
            line-height: $drawer-item-line-height;
            flex-shrink: 0;
        }

        .k-item-text {
            padding: 0 $drawer-item-padding-x;
            display: inline-block;
            white-space: nowrap;
        }
    }
    .k-drawer-separator {
        padding: 0;
        height: 1px;
    }



    // Mini mode
    .k-drawer-mini {

        .k-drawer-wrapper {
            width: $drawer-mini-initial-width;
        }

    }


    .k-rtl,
    [dir="rtl"] {

        // Borders
        &.k-drawer-mini .k-drawer-start,
        .k-drawer-mini .k-drawer-start,
        .k-drawer-expanded .k-drawer-start,
        &.k-drawer-expanded .k-drawer-start {
            border-left-width: $drawer-border-width;
            border-right-width: 0;
        }
        &.k-drawer-mini .k-drawer-end,
        .k-drawer-mini .k-drawer-end,
        &.k-drawer-expanded .k-drawer-end
        .k-drawer-expanded .k-drawer-end {
            border-left-width: 0;
            border-right-width: $drawer-border-width;
        }

        // Position
        &.k-drawer-overlay .k-drawer-start,
        .k-drawer-overlay .k-drawer-start {
            left: auto;
            right: 0;
        }
        &.k-drawer-overlay .k-drawer-end,
        .k-drawer-overlay .k-drawer-end {
            left: 0;
            right: auto;
        }

        // Order
        .k-drawer-left.k-drawer-push .k-drawer {
            order: 1;
        }
        .k-drawer-right.k-drawer-push .k-drawer {
            order: 0;
        }
    }
}
