@include exports("mediaplayer/theme") {

    // Theme
    .k-mediaplayer {
        @extend .k-text-selection !optional;
        @include fill( $mediaplayer-text, $mediaplayer-bg, $mediaplayer-border );
    }


    // Title bar
    .k-mediaplayer-titlebar {
        color: $mediaplayer-titlebar-text;
        background-image: linear-gradient( $mediaplayer-titlebar-gradient );
        text-shadow: 0 0 2px rgba( $mediaplayer-text, .5 );
    }


    .k-mediaplayer-toolbar {
        @include fill( $mediaplayer-toolbar-text, $mediaplayer-toolbar-bg );
    }

}
