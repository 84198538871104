// Treeview
$treeview-padding-x: 0px !default;
$treeview-padding-y: 0px !default;
$treeview-font-family: $font-family !default;
$treeview-font-size: $font-size !default;
$treeview-line-height: $line-height !default;

$treeview-indent: 16px !default;

$treeview-item-padding-x: map-get( $spacing, 2 ) !default;
$treeview-item-padding-y: map-get( $spacing, 1 ) !default;
$treeview-item-border-width: 0px !default;
$treeview-item-border-radius: null !default;

$treeview-bg: null !default;
$treeview-text: $component-text !default;
$treeview-border: null !default;

$treeview-item-hovered-text: $hovered-text !default;
$treeview-item-hovered-bg: $hovered-bg !default;
$treeview-item-hovered-border: null !default;
$treeview-item-hovered-gradient: null !default;

$treeview-item-selected-text: $selected-text !default;
$treeview-item-selected-bg: $selected-bg !default;
$treeview-item-selected-border: null !default;
$treeview-item-selected-gradient: null !default;

$treeview-item-focused-shadow: $focused-shadow !default;
$treeview-item-selected-focused-shadow: null !default;

$treeview-loadmore-checkboxes-padding-x: calc( #{$icon-spacing} + #{$checkbox-size} + #{$treeview-indent} ) !default;
$treeview-loadmore-checkboxes-icon-indent: calc( -1 * (#{$icon-spacing} + #{$treeview-indent}) ) !default;
$treeview-loadmore-checkboxes-icon-margin-x: $icon-spacing !default;

$treeview-loadmore-text: $primary !default;
$treeview-loadmore-bg: transparent !default;
$treeview-loadmore-border: null !default;

$treeview-loadmore-hover-text: $primary-darker !default;
$treeview-loadmore-hover-bg: transparent !default;
$treeview-loadmore-hover-border: null !default;

$treeview-loadmore-focus-text: $primary !default;
$treeview-loadmore-focus-bg: transparent !default;
$treeview-loadmore-focus-border: null !default;
$treeview-loadmore-focus-shadow: none !default;
