@include exports( "avatar/layout" ) {

    // Avatar
    .k-avatar {
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        flex-shrink: 0;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        overflow: hidden;
    }


    // Avatar text
    .k-avatar-text,
    .k-avatar-initials {
        font-family: $avatar-font-family;
        font-size: $avatar-font-size;
        line-height: $avatar-line-height;
    }


    // Avatar icon
    .k-avatar-icon {}


    // Avatar image
    .k-avatar-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    // Sizes
    @each $name, $size in $avatar-sizes {
        .k-avatar-#{$name} {
            width: $size;
            height: $size;
            flex-basis: $size;
        }
    }


    // Shapes
    .k-avatar-square {}
    .k-avatar-rounded {
        @include border-radius( $avatar-border-radius );
    }
    .k-avatar-circle {
        border-radius: 50%;
    }


    // Bordered
    .k-avatar-bordered {
        border-width: $avatar-border-width;
    }


}

