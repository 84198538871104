@include exports( "breadcrumb/layout" ) {

    // Breadcrumb
    .k-breadcrumb {
        margin: $breadcrumb-margin-y $breadcrumb-margin-x;
        padding: $breadcrumb-padding-y $breadcrumb-padding-x;
        border-width: $breadcrumb-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $breadcrumb-font-family;
        font-size: $breadcrumb-font-size;
        line-height: $breadcrumb-line-height;
        display: flex;
        flex-direction: row;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Items
        .k-breadcrumb-container {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: hidden;

            &.k-flex-wrap {
                flex-wrap: wrap;
            }
        }

        .k-breadcrumb-item {
            vertical-align: middle;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
        }

        .k-breadcrumb-root-item {
            flex: 1 0 auto;

            &.k-flex-none {
                flex: none;
            }
        }

        .k-breadcrumb-link,
        .k-breadcrumb-root-link {
            @include border-radius( $breadcrumb-link-border-radius );
            padding: $breadcrumb-link-padding-y $breadcrumb-link-padding-x;
            position: relative;
            display: inline-flex;
            align-items: center;
            align-self: stretch;
            overflow: hidden;
            white-space: nowrap;
            text-decoration: none;
            outline: none;
        }

        .k-breadcrumb-root-link {
            margin-right: $breadcrumb-root-link-spacing;
        }

        .k-breadcrumb-link > .k-image,
        .k-breadcrumb-icontext-link .k-icon {
            margin-right: $breadcrumb-link-icon-spacing;
        }

        .k-breadcrumb-icon-link {
            padding: $breadcrumb-icon-link-padding-y $breadcrumb-icon-link-padding-x;
        }

        .k-breadcrumb-delimiter-icon {
            font-size: $breadcrumb-delimiter-icon-font-size;
            opacity: $disabled-opacity;
        }

        // Editing
        .k-breadcrumb-input-container {
            width: 100%;
        }

        .k-textbox {
            width: 100%;
            height: 100%;
        }

        &.k-breadcrumb-wrap {
            .k-breadcrumb-root-item-container {
                margin: 0;
                padding: 0;
                list-style: none;
                overflow: hidden;

                display: flex;
                align-items: flex-start;
                flex: 0 0 auto;
            }
        }
    }

    .k-ie9 {
        .k-breadcrumb,
        .k-breadcrumb-container {
            display: block;
        }

        .k-breadcrumb-item {
            display: inline-block;
        }
    }

    @include exports("breadcrumb/layout/rtl") {

        .k-breadcrumb {
            &[dir="rtl"],
            .k-rtl & {
                .k-breadcrumb-root-link {
                    margin-right: 0;
                    margin-left: $breadcrumb-root-link-spacing;
                }

                .k-breadcrumb-link > .k-image,
                .k-breadcrumb-icontext-link .k-icon {
                    margin-right: 0;
                    margin-left: $breadcrumb-link-icon-spacing;
                }
            }
        }

    }

}
