@include exports("wizard/layout") {
    .k-wizard {
        padding: $wizard-padding-y $wizard-padding-x;
        border-width: $wizard-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-size: $wizard-font-size;
        line-height: $wizard-line-height;
        font-family: $wizard-font-family;
        position: relative;
        color: inherit;
        background: none;
        display: flex;
        align-items: stretch;
        overflow: hidden;

        .k-wizard-steps {
            display: flex;
            overflow: hidden;
        }

        .k-wizard-step {
            padding: calc(#{$wizard-content-padding-x / 4} + 2px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }

        .k-wizard-content {
            overflow-y: auto;
        }

        .k-wizard-buttons {
            margin-top: $wizard-content-padding-y;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;

            .k-button {
                margin-right: $wizard-buttons-margin-x;
            }

            .k-wizard-pager {
                vertical-align: middle;
            }
        }

        .k-wizard-buttons-right {
            .k-button:last-of-type {
                margin-right: 0;
            }
        }

        .k-stepper {
            .k-step-list-vertical {
                .k-step {
                    flex: 1 0 auto;
                }
            }

            .k-step-indicator {
                flex-shrink: 0;
            }
        }
    }

    .k-wizard-horizontal {
        flex-direction: column;

        .k-wizard-steps {
            margin-top: $wizard-content-padding-y;
        }

        .k-wizard-steps,
        .k-wizard-content {
            flex: 1 1 auto;
        }
    }

    .k-wizard-vertical {

        .k-wizard-steps {
            flex: 1 0 0%;
        }

        .k-wizard-content {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }

        .k-wizard-steps {
            margin-left: $wizard-content-padding-x;
        }
    }

    .k-wizard-left {
        flex-direction: row-reverse;

        .k-wizard-steps {
            margin-right: $wizard-content-padding-x;
            margin-left: 0;
        }
    }

}
