// Spreadsheet
$spreadsheet-border-width: 1px !default;
$spreadsheet-font-family: $font-family !default;
$spreadsheet-font-size: $font-size !default;
$spreadsheet-line-height: $line-height !default;

$spreadsheet-bg: $component-bg !default;
$spreadsheet-text: $component-text !default;
$spreadsheet-border: $component-border !default;

$spreadsheet-insert-image-dialog-border-style: dashed !default;
$spreadsheet-insert-image-dialog-border-width: 2px !default;
$spreadsheet-insert-image-dialog-text-margin-bottom: 30px !default;
$spreadsheet-insert-image-dialog-preview-width: 355px !default;
$spreadsheet-insert-image-dialog-preview-height: 230px !default;
$spreadsheet-insert-image-dialog-preview-img: "image-default.png" !default;
$spreadsheet-insert-image-dialog-preview-border: $border-color !default;
$spreadsheet-insert-image-dialog-preview-border-radius: $border-radius !default;
$spreadsheet-insert-image-dialog-preview-overlay-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .5) !default;
$spreadsheet-insert-image-dialog-preview-overlay-border-radius: $border-radius !default;
$spreadsheet-insert-image-dialog-overlay-hovered-text: $body-bg !default;

$spreadsheet-drawing-handle-width: 6px !default;
$spreadsheet-drawing-handle-height: 6px !default;
$spreadsheet-drawing-handle-border-style: solid !default;
$spreadsheet-drawing-handle-border-width: 1px !default;
$spreadsheet-drawing-handle-outline-color: $primary !default;
$spreadsheet-drawing-handle-border-color: $selected-bg !default;
$spreadsheet-drawing-handle-bg: $primary !default;
$spreadsheet-drawing-handle-border-radius: $border-radius-lg !default;

$spreadsheet-drawing-outline-style: solid !default;
$spreadsheet-drawing-outline-width: 2px !default;

$spreadsheet-drawing-anchor-bg: rgba( $selected-bg, .25 ) !default;
