@include exports( "listbox/layout" ) {

    .k-listbox {
        width: $listbox-width;
        height: $listbox-default-height;
        border-width: 0;
        outline: 0;
        font-family: $listbox-font-family;
        font-size: $listbox-font-size;
        line-height: $listbox-line-height;
        vertical-align: top;
        background-color: transparent;
        display: inline-flex;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-listbox-toolbar ul {
            display: flex;
        }

        &.k-listbox-toolbar-left,
        &.k-listbox-toolbar-right {
            .k-listbox-toolbar ul {
                flex-direction: column;
            }

            .k-listbox-toolbar li + li {
                margin-top: $listbox-button-margin;
            }
        }

        &.k-listbox-toolbar-left {
            flex-direction: row;

            .k-listbox-toolbar {
                margin-right: $listbox-margin;
            }
        }

        &.k-listbox-toolbar-right {
            flex-direction: row-reverse;

            .k-listbox-toolbar {
                margin-left: $listbox-margin;
            }
        }

        &.k-listbox-toolbar-top,
        &.k-listbox-toolbar-bottom {
            flex-direction: column;

            .k-listbox-toolbar ul {
                flex-direction: row;
            }

            .k-listbox-toolbar li + li {
                margin-left: $listbox-button-margin;
            }
        }

        &.k-listbox-toolbar-top {
            .k-listbox-toolbar {
                margin-bottom: $listbox-margin;
            }
        }

        &.k-listbox-toolbar-bottom {
            .k-listbox-toolbar {
                margin-top: $listbox-margin;
            }
        }

        .k-list-scroller {
            @include use-background-clip();
            width: 100%;
            border-width: $listbox-border-width;
            border-style: solid;
            box-sizing: border-box;
        }

        .k-drop-hint {
            border-top-width: $listbox-drop-hint-width;
            border-top-style: solid;
        }

        .k-ghost {
            opacity: .5;
        }

        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {

            &.k-listbox-toolbar-left,
            &.k-listbox-toolbar-right {
                .k-listbox-toolbar {
                    transform: scaleX(-1);
                }
            }

            &.k-listbox-toolbar-left {
                .k-listbox-toolbar {
                    margin-right: 0;
                    margin-left: $listbox-margin;
                }
            }

            &.k-listbox-toolbar-right {
                .k-listbox-toolbar {
                    margin-right: $listbox-margin;
                    margin-left: 0;
                }
            }
        }
    }

    .k-item {
        cursor: default;
    }

    .k-item.k-drag-clue {
        @include border-radius( 0 );
        padding: $listbox-drag-clue-padding-y $listbox-drag-clue-padding-x;
        line-height: $listbox-drag-clue-line-height;
        border-width: 0;
        font-size: $font-size;
    }
}
