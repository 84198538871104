@include exports("dataviz/charts/theme") {
    // Exported variables
    .k-var--chart-inactive {
        background-color: $chart-inactive;
    }

    .k-var--chart-major-lines {
        background-color: $chart-major-lines;
    }

    .k-var--chart-minor-lines {
        background-color: $chart-minor-lines;
    }

    .k-var--chart-area-opacity {
        opacity: $chart-area-opacity;
    }

    .k-var--chart-area-inactive-opacity {
        opacity: $chart-area-inactive-opacity;
    }

    .k-var--chart-line-inactive-opacity {
        opacity: $chart-line-inactive-opacity;
    }

    .k-var--chart-notes-background {
        background-color: $chart-notes-background;
    }

    .k-var--chart-notes-border {
        background-color: $chart-notes-border;
    }

    .k-var--chart-notes-lines {
        background-color: $chart-notes-lines;
    }

    .k-var--chart-crosshair-background {
        background-color: $chart-crosshair-background;
    }

    .k-var--chart-error-bars-background {
        background-color: $error-bars-background;
    }

    // Elements
    .k-chart,
    .k-sparkline,
    .k-stockchart {
        @extend .k-text-selection !optional;
        @include fill(
            $chart-text,
            transparent,
            $chart-border
        );

        .k-popup {
            background: transparent;
        }
    }


    // Tooltip
    // TODO
    .k-chart-tooltip {
        color: $component-bg;
    }
    .k-chart-tooltip-inverse {
        color: invert($component-bg);
    }

    .k-chart-crosshair-tooltip,
    .k-chart-shared-tooltip {
        color: $chart-crosshair-shared-tooltip-color;
        background-color: $chart-crosshair-shared-tooltip-background;
        border-color: $chart-crosshair-shared-tooltip-border;
    }

    .k-selection {
        border-color: $selection-border-color;
        box-shadow: $selection-shadow;
    }

    .k-selection-bg {
        background-color: transparent;
    }

    .k-handle {
        cursor: e-resize;
        @include fill(
            $button-text,
            $button-bg,
            $button-border,
            $button-gradient
        );

        &:hover {
            @include fill(
                $button-hovered-text,
                $button-hovered-bg,
                $button-hovered-border,
                $button-hovered-gradient
            );
        }
    }

    .k-handle div {
        background-color: transparent;
    }

    .k-mask {
        background-color: $component-bg;
        opacity: .8;
    }

    .k-marquee-color {
        background-color: $chart-selection-marque-background;
    }

    .k-marquee-color {
        opacity: .6;
    }
}




@include exports("dataviz/treemap/theme") {

    // Treemap
    .k-treemap {
        @include fill(
            $component-text,
            $component-bg,
            $component-border
        );

        // Title
        .k-treemap-title {
            @include fill(
                $header-text,
                $header-bg,
                $header-border,
                $header-gradient
            );
        }

        // Leaf
        .k-leaf {
            color: $component-bg;
        }
        .k-leaf.k-inverse {
            color: $component-text;
        }
        .k-leaf.k-state-hover {
            box-shadow: inset 0 0 0 3px $component-border;
        }
    }

}
