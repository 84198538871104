@include exports( "utils/transform" ) {

    // Flip
    .k-flip-h               { transform: scaleX( -1 ); }
    .k-flip-v               { transform: scaleY( -1 ); }
    .k-flip-h.k-flip-v      { transform: scale( -1, -1 ); }


    // Rotate
    .k-rotate-45            { transform: rotate( 45deg ); }
    .k-rotate-90            { transform: rotate( 90deg ); }
    .k-rotate-135           { transform: rotate( 135deg ); }
    .k-rotate-180           { transform: rotate( 180deg ); }
    .k-rotate-225           { transform: rotate( 225deg ); }
    .k-rotate-270           { transform: rotate( 270deg ); }
    .k-rotate-315           { transform: rotate( 315deg ); }


    // Scale
    .k-scale-0              { transform: scale( 0, 0 ); }
    .k-scale-1              { transform: scale( 1, 1 ); }
    .k-scale-2              { transform: scale( 2, 2 ); }


    // Translate
    .k-translate-0          { transform: translate( 0, 0 ); }
    .k-translate-0-50       { transform: translate( 0, 50% ); }
    .k-translate-0-100      { transform: translate( 0, 100% ); }
    .k-translate-50-0       { transform: translate( 50%, 0 ); }
    .k-translate-50-50      { transform: translate( 50%, 50% ); }
    .k-translate-50-100     { transform: translate( 50%, 100% ); }
    .k-translate-100-0      { transform: translate( 100%, 0 ); }
    .k-translate-100-50     { transform: translate( 100%, 50% ); }
    .k-translate-100-100    { transform: translate( 100%, 100% ); }

}
