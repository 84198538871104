// Toolbar

/// The horizontal padding of the container.
/// @group toolbar
$toolbar-padding-x: $padding-x !default;
/// The vertical padding of the container.
/// @group toolbar
$toolbar-padding-y: $padding-x !default;
$toolbar-border-width: 1px !default;
$toolbar-border-radius: null !default;
$toolbar-spacing: $toolbar-padding-x !default;

$toolbar-inner-calc-size: calc( #{$button-calc-size} + #{$toolbar-padding-y * 2} ) !default;

$toolbar-font-family: $font-family !default;
$toolbar-font-size: $font-size !default;
$toolbar-line-height: $line-height !default;

$toolbar-bg: $button-bg !default;
$toolbar-text: $button-text !default;
$toolbar-border: $button-border !default;
$toolbar-gradient: null !default;
$toolbar-shadow: null !default;

$toolbar-separator-border: inherit !default;
