// Expansion panel
$expander-padding-y: 8px !default;
$expander-font-family: $font-family !default;
$expander-font-size: $font-size !default;
$expander-line-height: $line-height !default;
$expander-border-width: 0 !default;

$expander-header-padding-x: 0 !default;
$expander-header-padding-y: $header-padding-y !default;

$expander-indicator-margin-x: $padding-x * 1.5 !default;

$expander-bg: $component-bg !default;
$expander-text: $component-text !default;
$expander-border: $component-border !default;

$expander-header-border-width: 2px !default;
$expander-header-bg: $expander-bg !default;
$expander-header-text: $expander-text !default;
$expander-header-border: $expander-text !default;

$expander-header-focused-bg: null !default;
$expander-header-focused-shadow: $list-item-focused-shadow !default;

$expander-header-sub-title-opacity: .65 !default;

$expander-content-padding-x: $padding-x * 2.5 !default;
$expander-content-padding-y: $padding-y * 5 !default;
