@include exports( "breadcrumb/theme" ) {

    // Breadcrumb
    .k-breadcrumb {
        @extend .k-text-selection !optional;
        @include fill(
            $breadcrumb-text,
            $breadcrumb-bg,
            $breadcrumb-border
        );

        &:focus,
        &.k-state-focused {
            box-shadow: $breadcrumb-focused-shadow;
        }

        .k-breadcrumb-link {
            @include fill(
                $breadcrumb-link-text,
                $breadcrumb-link-bg,
                $breadcrumb-link-border
            );
            transition: $transition;

            // Hovered state
            &:hover,
            &.k-state-hover {
                @include fill(
                    $breadcrumb-link-hovered-text,
                    $breadcrumb-link-hovered-bg,
                    $breadcrumb-link-hovered-border
                );
            }

            // Focused state
            &:focus,
            &.k-state-focused {
                @include fill(
                    $breadcrumb-link-focused-text,
                    $breadcrumb-link-focused-bg,
                    $breadcrumb-link-focused-border
                );
                @include box-shadow( $breadcrumb-link-focused-shadow );
            }
        }

        .k-breadcrumb-root-link {
            @include fill(
                $breadcrumb-root-link-text,
                $breadcrumb-root-link-bg,
                $breadcrumb-root-link-border
            );
            transition: $transition;

            // Hovered state
            &:hover,
            &.k-state-hover {
                @include fill(
                    $breadcrumb-root-link-hovered-text,
                    $breadcrumb-root-link-hovered-bg,
                    $breadcrumb-root-link-hovered-border
                );
            }

            // Focused state
            &:focus,
            &.k-state-focused {
                @include fill(
                    $breadcrumb-root-link-focused-text,
                    $breadcrumb-root-link-focused-bg,
                    $breadcrumb-root-link-focused-border
                );
                @include box-shadow( $breadcrumb-root-link-focused-shadow );
            }
        }

        .k-breadcrumb-root-link,
        .k-breadcrumb-delimiter-icon {
            color: inherit;
        }

        .k-breadcrumb-input-container {
            border-color: transparent;
        }
    }

}
