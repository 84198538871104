@include exports( "textarea/layout" ) {

    // Textarea
    .k-textarea {
        @include border-radius( $textarea-border-radius );
        margin: 0;
        padding: 0;
        width: $textarea-default-width;
        border-width: $textarea-border-width;
        border-style: solid;
        outline: 0;
        font-family: $textarea-font-family;
        font-size: $textarea-font-size;
        line-height: $textarea-line-height;
        box-sizing: border-box;
        // Targets https://github.com/telerik/kendo-react/issues/638.
        box-shadow: none;
        background: none;
        display: inline-flex;
        flex-wrap: nowrap;
        vertical-align: middle;
        position: relative;
        -webkit-appearance: none;

        // not to be overriden by `background: none`
        @include use-background-clip();

        // Hide clear icon
        &::-ms-clear { display: none; }

        // Placeholder text
        &::placeholder {
            color: $input-placeholder-text;
            opacity: $input-placeholder-opacity;
            user-select: none;
        }

        > .k-input {
            padding: $textarea-padding-y $textarea-padding-x;
            height: auto;
            min-height: $textarea-calc-height;
            overflow-y: auto;
            resize: vertical;
        }

        // Disabled state
        &:disabled,
        &[disabled],
        &.k-state-disabled {
            @include disabled( $disabled-styling );

            // Disabled selection
            &::selection {
                @include fill (
                    $color: $input-text,
                    $bg: transparent
                    // $bg: rgba( contrast-wcag( $input-bg ), .1 )
                );
            }
        }
    }

    .k-textarea-suffix {
        flex-shrink: 0;
    }

    // Textarea old rendering
    textarea.k-textarea {
        padding: $textarea-padding-y $textarea-padding-x;
        min-height: $textarea-calc-height;
        display: inline-block;
        resize: both;
    }

    // Floating label
    .k-textbox-container,
    .k-floating-label-container {
        > .k-textarea {
            flex: 1 1 auto;
            width: 100%;
        }
    }
}




@include exports("textarea/layout/IE") {
    // TODO: Remove once we drop IE support

    .k-ie {
        .k-textarea {
            :-ms-input-placeholder,
            &:-ms-input-placeholder {
                color: $input-placeholder-text;
            }
        }
    }
}
