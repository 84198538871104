@include exports("chat/theme") {

    // Chat
    .k-chat {
        @extend .k-text-selection !optional;
        @include fill(
            $chat-text,
            $chat-bg,
            $chat-border
        );


        // Message meta
        .k-timestamp {
            text-transform: uppercase;
            opacity: .7;
        }
        .k-author {
            font-weight: bold;
        }


        // Bubbles
        .k-bubble {
            @include fill(
                $chat-bubble-text,
                $chat-bubble-bg,
                $chat-bubble-border
            );
            @include box-shadow( $chat-bubble-shadow );
            transition: $transition;
            order: -1;
        }
        .k-bubble:hover {
            @include box-shadow( $chat-bubble-hover-shadow );
        }
        .k-state-selected .k-bubble {
            @include box-shadow( $chat-bubble-selected-shadow );
        }

        .k-alt .k-bubble {
            @include fill(
                $chat-alt-bubble-text,
                $chat-alt-bubble-bg,
                $chat-alt-bubble-border
            );
            @include box-shadow( $chat-alt-bubble-shadow );
        }
        .k-alt .k-bubble:hover {
            @include box-shadow( $chat-alt-bubble-hover-shadow );
        }
        .k-alt .k-state-selected .k-bubble {
            @include box-shadow( $chat-alt-bubble-selected-shadow );
        }


        // Quick replies
        .k-quick-reply {
            @include fill(
                $chat-quick-reply-text,
                $chat-quick-reply-bg,
                $chat-quick-reply-border
            );
        }
        .k-quick-reply:hover {
            @include fill(
                $chat-quick-reply-hover-text,
                $chat-quick-reply-hover-bg,
                $chat-quick-reply-hover-border
            );
        }


        // Message box
        .k-message-box {
            @include fill(
                $chat-message-box-text,
                $chat-message-box-bg,
                $chat-message-box-border
            );
        }
        .k-message-box.k-state-focused {
            @include box-shadow( $chat-message-box-focus-shadow );
        }
        .k-message-box .k-button:hover {
            @include fill( $chat-message-box-button-hover-text );
        }

    }


    // Toolbar
    .k-chat-toolbar,
    .k-chat .k-toolbar-box {
        @include fill(
            $chat-toolbar-text,
            $chat-toolbar-bg,
            $chat-toolbar-border
        );

        .k-button:hover {
            @include fill(
                $chat-toolbar-box-button-hover-text,
                $chat-toolbar-box-button-hover-bg
            );
        }

        .k-scroll-button {
            @include fill( $bg: $chat-bg );
            @include box-shadow( $chat-toolbar-box-scroll-button-shadow );
        }
        .k-scroll-button:hover {
            @include fill( $bg: $chat-bg );
        }
    }

}
