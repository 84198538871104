// Floating Action Button
$fab-padding-x: map-get( $spacing, 4 ) !default;
$fab-padding-y: $fab-padding-x !default;
$fab-border-width: 0 !default;
$fab-border-radius: $border-radius * 2 !default;
$fab-font-family: $font-family !default;
$fab-font-size: $font-size !default;
$fab-line-height: $line-height !default;

$fab-padding-x-sm: ( $fab-padding-x / 2 ) !default;
$fab-padding-y-sm: ( $fab-padding-y / 2 ) !default;

$fab-padding-x-md: $fab-padding-x !default;
$fab-padding-y-md: $fab-padding-y !default;

$fab-padding-x-lg: ( $fab-padding-x * 1.5 ) !default;
$fab-padding-y-lg: ( $fab-padding-y * 1.5 ) !default;

$fab-icon-width: 20px !default;
$fab-icon-height: $fab-icon-width !default;
$fab-icon-spacing: map-get( $spacing, 1 ) !default;

$fab-items-padding-x: 0 !default;
$fab-items-padding-y: map-get( $spacing, 4 ) !default;

$fab-item-text-padding-x: map-get( $spacing, 1 ) !default;
$fab-item-text-padding-y: $fab-item-text-padding-x !default;
$fab-item-text-border-width: 1px !default;
$fab-item-text-border-radius: 2px !default;
$fab-item-text-font-size: $font-size-xs !default;
$fab-item-text-line-height: 1 !default;

$fab-item-icon-padding-x: map-get( $spacing, 2 ) !default;
$fab-item-icon-padding-y: $fab-item-icon-padding-x !default;
$fab-item-icon-border-width: 0 !default;
$fab-item-icon-border-radius: 50% !default;

$fab-sizes: () !default;
// sass-lint:disable indentation
$fab-sizes: map-merge((
    sm: map-get( $spacing, 4 ),
    md: map-get( $spacing, 8 ),
    lg: map-get( $spacing, 16 )
), $fab-sizes);
// sass-lint:enable indentation

$fab-theme-colors: () !default;
$fab-theme-colors: map-merge( $fab-theme-colors, $theme-colors );

$fab-shadow: 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12), 0 3px 5px rgba(0, 0, 0, .2) !default;
$fab-focus-shadow: 0 1px 10px rgba(#000000, .22), 0 4px 5px rgba(#000000, .24), 0 2px 4px rgba(#000000, .2) !default;
$fab-disabled-shadow: 0 6px 10px rgba(0, 0, 0, .06), 0 1px 18px rgba(0, 0, 0, .06), 0 3px 5px rgba(0, 0, 0, .08) !default;

$fab-item-text: $component-text !default;
$fab-item-bg: $component-bg !default;
$fab-item-border: $component-border !default;

$fab-item-icon-text: contrast-wcag( $light ) !default;
$fab-item-icon-bg: $light !default;
$fab-item-icon-border: $light !default;

$fab-item-shadow: $fab-shadow !default;
$fab-item-focus-shadow: $fab-focus-shadow !default;
$fab-item-disabled-shadow: $fab-disabled-shadow !default;
$fab-item-disabled-focus-shadow: 0 1px 10px rgba(#000000, .12), 0 4px 5px rgba(#000000, .12), 0 2px 4px rgba(#000000, .08) !default;

$fab-border-width-sm: $fab-border-width !default;
$fab-line-height-sm: $fab-line-height !default;
$fab-size-sm: calc( #{$fab-line-height-sm * 1em} + #{$fab-padding-y-sm * 2} + #{$fab-border-width-sm * 2} ) !default;

$fab-border-width-md: $fab-border-width !default;
$fab-line-height-md: $fab-line-height !default;
$fab-size-md: calc( #{$fab-line-height-md * 1em} + #{$fab-padding-y-md * 2} + #{$fab-border-width-md * 2} ) !default;

$fab-border-width-lg: $fab-border-width !default;
$fab-line-height-lg: $fab-line-height !default;
$fab-size-lg: calc( #{$fab-line-height-lg * 1em} + #{$fab-padding-y-lg * 2} + #{$fab-border-width-lg * 2} ) !default;
