@include exports( "autocomplete/layout" ) {

    // Autocomplete
    .k-autocomplete {
        @include border-radius( $input-border-radius );
        @include use-background-clip();
        width: $input-default-width;
        border-width: $input-border-width;
        border-style: solid;
        outline: 0;
        box-sizing: border-box;
        font-family: $input-font-family;
        font-size: $input-font-size;
        line-height: $input-line-height;
        white-space: nowrap;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: stretch;
        vertical-align: middle;
        transition: all .1s ease; // sass-lint:disable-block no-transition-all
        position: relative;
        cursor: default;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;


        // Input
        .k-input {}


        // Loading icon
        .k-i-loading {
            width: $input-icon-width;
            height: $input-icon-height;
        }


        // Clear value
        .k-clear-value {
            opacity: .5;
            visibility: hidden;

            &:hover {
                opacity: 1;
            }
        }
        &:hover,
        // &:focus-within,
        &.k-state-hover,
        &.k-state-focused {
            .k-clear-value {
                visibility: visible;
            }
        }
        &:focus-within {
            .k-clear-value {
                visibility: visible;
            }
        }

    }


}
