@mixin linear-gradient( $gradient: null ) {
    @if $gradient and $enable-gradients {
        @if $gradient == none {
            background-image: none
        }
        @else {
            background-image: linear-gradient( $gradient );
        }
    }
}

@mixin striped-gradient( $color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@mixin checkerboard-gradient ( $primary-color: white, $secondary-color: rgba(0, 0, 0, 0.09), $size: 10px ) {
    $position: calc(#{$size} / 2);
    background: linear-gradient(45deg, $secondary-color 25%, transparent 25%, transparent 75%, $secondary-color 75%, $secondary-color 0),
                linear-gradient(45deg, $secondary-color 25%, transparent 25%, transparent 75%, $secondary-color 75%, $secondary-color 0),
                $primary-color;
    background-repeat: repeat, repeat;
    background-position: 0px 0px, $position $position;
    transform-origin: 0 0 0;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-size: $size $size, $size $size;
    box-shadow: none;
    text-shadow: none;
    transition: none;
    transform: scaleX(1) scaleY(1) scaleZ(1);
}

@mixin repeating-striped-gradient( $color: rgba(255,255,255,.15), $background: #FFF, $angle: 45deg, $largeStep: 2px, $smallStep: 1px) {
    background-image: repeating-linear-gradient($angle, $background, $background $smallStep, $color $smallStep, $color $largeStep);
}
