// Panelbar
$panelbar-padding-x: 0px !default;
$panelbar-padding-y: 0px !default;
$panelbar-font-family: $font-family !default;
$panelbar-font-size: $font-size !default;
$panelbar-line-height: $line-height !default;
$panelbar-border-width: 1px !default;

$panelbar-header-padding-x: $header-padding-x !default;
$panelbar-header-padding-y: $header-padding-y !default;

$panelbar-item-padding-x: $list-item-padding-x !default;
$panelbar-item-padding-y: $list-item-padding-y !default;

$panelbar-bg: $base-bg !default;
$panelbar-text: $base-text !default;
$panelbar-border: $base-border !default;

$panelbar-header-bg: $component-bg !default;
$panelbar-header-text: $link-text !default;
$panelbar-header-border: null !default;
$panelbar-header-gradient: null !default;

$panelbar-header-hovered-bg: shade($panelbar-header-bg) !default;
$panelbar-header-hovered-text: $link-hover-text !default;
$panelbar-header-hovered-border: null !default;
$panelbar-header-hovered-gradient: null !default;

$panelbar-header-selected-bg: $selected-bg !default;
$panelbar-header-selected-text: $selected-text !default;
$panelbar-header-selected-border: null !default;
$panelbar-header-selected-gradient: null !default;

$panelbar-header-focused-shadow: $list-item-focused-shadow !default;

$panelbar-item-hovered-bg: shade($panelbar-bg) !default;
$panelbar-item-hovered-text: null !default;
$panelbar-item-hovered-border: null !default;
$panelbar-item-hovered-gradient: null !default;

$panelbar-item-selected-bg: $selected-bg !default;
$panelbar-item-selected-text: $selected-text !default;
$panelbar-item-selected-border: null !default;
$panelbar-item-selected-gradient: null !default;

$panelbar-item-focused-shadow: $list-item-focused-shadow !default;
