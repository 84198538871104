@include exports("panelbar/layout") {

    // Base
    .k-panelbar {
        @include use-background-clip();
        margin: 0;
        padding: 0;
        border-width: $panelbar-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $panelbar-font-family;
        font-size: $panelbar-font-size;
        line-height: $panelbar-line-height;
        list-style: none;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;


        // Root
        > .k-item {
            // TODO
            @include use-background-clip();
            border-width: 0;
            border-style: solid;
            border-color: inherit;
            display: block;

            > .k-link {
                padding: $panelbar-header-padding-y $panelbar-header-padding-x;
                color: inherit;
                background: none;
                text-decoration: none;
                display: flex;
                flex-flow: row nowrap;
                gap: $icon-spacing;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $transition;
            }
        }
        > .k-item + .k-item {
            border-top-width: 1px;
        }


        // Sub
        .k-group {
            margin: 0;
            padding: 0;
            border-width: 0;
            border-color: inherit;
            color: inherit;
            background-color: transparent;
            list-style: none;
        }
        .k-group > .k-item {
            display: block;

            > .k-link {
                padding: $panelbar-item-padding-y $panelbar-item-padding-x;
                color: inherit;
                text-decoration: none;
                display: flex;
                flex-flow: row nowrap;
                gap: $icon-spacing;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $transition;
            }
        }


        // Panelbar content
        .k-content {
            display: flow-root;
        }


        // Toggle icon
        .k-panelbar-expand,
        .k-panelbar-collapse {
            margin-inline-start: auto;
        }
        .k-group .k-panelbar-expand,
        .k-group .k-panelbar-collapse {
            margin-inline-end: calc( #{$panelbar-header-padding-x} - #{$panelbar-item-padding-x} );
        }

    }

}




@include exports( "panelbar/layout/legacy" ) {

    .k-no-flexbox .k-panelbar {

        // Item
        > .k-item > .k-link,
        .k-group > .k-link {
            display: block;
        }

        // Icons
        .k-link > .k-image,
        .k-link > .k-sprite,
        .k-link > .k-icon {
            margin-right: $icon-spacing;
        }

        // Expand collapse
        .k-panelbar-expand,
        .k-panelbar-collapse {
            margin: 0 !important; // sass-lint:disable-line no-important
            transform: translateY( -50% );
            position: absolute;
            top: 50%;
            right: $panelbar-header-padding-x;
        }

    }


    .k-no-flexbox .k-panelbar[dir="rtl"],
    .k-no-flexbox .k-rtl .k-panelbar {

        .k-link > .k-image,
        .k-link > .k-sprite,
        .k-link > .k-icon {
            margin-left: $icon-spacing;
            margin-right: 0;
        }

        .k-panelbar-expand,
        .k-panelbar-collapse {
            right: auto;
            left: $panelbar-header-padding-x;
        }

    }

}
