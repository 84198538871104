// Textarea
$textarea-default-width: 18em !default;

$textarea-border-width: $input-border-width !default;
$textarea-border-height: $input-border-height !default;
$textarea-border-radius: $input-border-radius !default;
$textarea-border-radius-sm: $input-border-radius-sm !default;
$textarea-border-radius-lg: $input-border-radius-lg !default;

$textarea-padding-x: $input-padding-x !default;
$textarea-padding-y: $input-padding-y !default;
$textarea-font-family: $input-font-family !default;
$textarea-font-size: $input-font-size !default;
$textarea-line-height: $input-line-height !default;
$textarea-line-height-em: $input-line-height-em !default;

$textarea-padding-x-sm: $input-padding-x-sm !default;
$textarea-padding-y-sm: $input-padding-y-sm !default;
$textarea-font-size-sm: $input-font-size-sm !default;
$textarea-line-height-sm: $input-line-height-sm !default;

$textarea-padding-x-lg: $input-padding-x-lg !default;
$textarea-padding-y-lg: $input-padding-y-lg !default;
$textarea-font-size-lg: $input-font-size-lg !default;
$textarea-line-height-lg: $input-line-height-lg !default;

$textarea-calc-height: $input-calc-height !default;
$textarea-calc-height-sm: $input-calc-height-sm !default;
$textarea-calc-height-lg: $input-calc-height-lg !default;

$textarea-floating-label-padding: null !default;

$textarea-bg: $input-bg !default;
$textarea-text: $input-text !default;
$textarea-border: $input-border !default;
$textarea-shadow: $input-shadow !default;

$textarea-hover-text: $input-hovered-text !default;
$textarea-hover-bg: $input-hovered-bg !default;
$textarea-hover-border: $input-hovered-border !default;
$textarea-hover-shadow: $input-hovered-shadow !default;

$textarea-focus-text: $input-focused-text !default;
$textarea-focus-bg: $input-focused-bg !default;
$textarea-focus-border: $input-focused-border !default;
$textarea-focus-shadow: $input-focused-shadow !default;

$textarea-placeholder-text: $input-placeholder-text !default;
$textarea-placeholder-opacity: $input-placeholder-opacity !default;

$textarea-selected-text: $input-selected-text !default;
$textarea-selected-bg: $input-selected-bg !default;

$textarea-invalid-focus-shadow: $invalid-shadow !default;
