@include exports("colorpicker/layout") {

    .k-colorpicker {
        width: auto;
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        font-family: $colorpicker-font-family;
        font-size: $colorpicker-font-size;
        line-height: $colorpicker-line-height;
        background: none;
        text-align: left;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        overflow: visible;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-selected-color {
            padding: $button-padding-y;
            width: $button-inner-calc-size;
            height: $button-inner-calc-size;
            border-width: 0 $colorpicker-select-border-width 0 0;
            border-style: solid;
            border-color: inherit;
            box-sizing: border-box;
            line-height: 0;
            position: relative;
            overflow: hidden;

            .k-i-line {
                border-top: 1px solid $error;
                width: 200%;
                height: 200%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-33%, -33%) rotateZ(45deg);
                transform-origin: 0 0;
            }
            .k-i-line::before {
                display: none;
            }
        }

        .k-tool-icon {
            padding: $button-padding-y;
            width: $button-inner-calc-size;
            height: $button-inner-calc-size;
            border-width: 0 $colorpicker-select-border-width 0 0;
            border-style: solid;
            border-color: inherit;
            box-sizing: border-box;
            font-size: inherit;
            text-align: center;
            flex-direction: column;
            cursor: pointer;

            &::before {
                font-size: $icon-size;
                line-height: 1;
            }

            .k-selected-color {
                margin: 1px 0 0;
                padding: 0;
                width: 14px;
                height: 3px;
                border-width: 0;
                line-height: 0;
                display: inline-block;
            }
        }

        .k-picker-wrap {
            @include use-background-clip();
            @include border-radius( $input-border-radius );
            padding: 0;
            width: 100%;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: row;
            transition: all .1s ease; // sass-lint:disable-block no-transition-all
            overflow: hidden;
            cursor: default;
            outline: 0;
        }


        // Input
        .k-input {}


        // Select
        .k-select {
            @include border-right-radius-only( $input-border-radius );
            padding: $picker-select-padding-y $picker-select-padding-x;
            width: if($use-picker-select-width, $button-inner-calc-size, null);
            border-width: 0;
            border-style: solid;
            border-color: inherit;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            text-align: center;
            cursor: pointer;

            .k-icon {}
        }

        .k-rtl &,
        &[dir="rtl"] {
            .k-selected-color,
            .k-tool-icon {
                border-width: 0 0 0 $colorpicker-select-border-width;
            }

            .k-picker-wrap {
                .k-select {
                    @include border-left-radius-only( $input-border-radius );
                }
            }
        }

    }
    .k-colorpicker + .k-animation-container {
        .k-popup {
            padding: 0;
        }
    }
}




@include exports( "flatcolorpicker/layout" ) {

    .k-flatcolorpicker {
        @include border-radius( $input-border-radius );
        border-width: $colorpicker-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $colorpicker-font-family;
        font-size: $colorpicker-font-size;
        line-height: $colorpicker-line-height;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: $colorpicker-width;
        padding: $colorpicker-rect-padding;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;


        // HSV wrap
        .k-hsv-wrap {}


        // HSV rectangle
        .k-hsv-rectangle {
            position: relative;
            flex: 1 1 auto;
            user-select: none;
            -ms-touch-action: pinch-zoom double-tap-zoom;

            .k-draghandle {
                margin: -7px 0 0 -7px;
                cursor: pointer;
                position: absolute;
                z-index: 10;
                left: 50%;
                top: 50%;
            }
        }
        .k-hsv-gradient {
            height: 180px;
        }


        // Sliders wrap
        .k-sliders-wrap {
            flex-shrink: 0;
            position: relative;

            &.k-hbox {
                margin-left: $colorpicker-margin;

                .k-slider + .k-slider {
                    margin-left: $colorpicker-margin;
                }
            }

            &.k-vbox {
                margin-top: $colorpicker-margin;

                .k-slider + .k-slider {
                    margin-top: $colorpicker-margin;
                }
            }

        }
        .k-sliders-wrap-clearable {

            &.k-hbox {

                .k-clear-color {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .k-hue-slider,
                .k-alpha-slider {
                    height: 140px;
                    align-self: flex-end;
                }
            }

            &.k-vbox {}

        }


        // Sliders
        .k-slider {
            display: block;

            .k-slider-wrap {
                z-index: 1;
            }

            .k-slider-selection {
                display: none;
            }

            // Draghandle
            .k-draghandle {
                border-width: 3px;
            }

        }


        // Slider orientation
        .k-slider-horizontal {
            width: 100%;
            height: $colorpicker-slider-track-size;
            flex: 0 0 $colorpicker-slider-track-size;

            .k-slider-track {
                margin-top: -($colorpicker-slider-track-size / 2);
                height: $colorpicker-slider-track-size;
                border-radius: $colorpicker-slider-border-radius;
            }
        }
        .k-slider-vertical {
            width: $colorpicker-slider-track-size;
            height: 180px;
            flex: 0 0 $colorpicker-slider-track-size;

            .k-slider-track {
                margin-left: -($colorpicker-slider-track-size / 2);
                width: $colorpicker-slider-track-size;
                border-radius: $colorpicker-slider-border-radius;
            }
        }


        .k-draghandle {
            @include border-radius( 50% );
            width: $colorpicker-draghandle-width;
            height: $colorpicker-draghandle-height;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
        }


        // Color inputs
        .k-colorinputs {
            margin-top: ($colorpicker-margin * 2);

            > .k-gradient-values + .k-gradient-values {
                margin-top: $colorpicker-margin;
            }
        }
        .k-gradient-values {
            justify-content: space-between;

            .k-input,
            .k-textbox {
                padding: $colorpicker-input-padding-y $colorpicker-input-padding-x;
            }

            div,
            input,
            .k-numerictextbox {
                width: $colorpicker-input-width;
                text-align: center;
            }
            div {
                text-transform: uppercase;
                text-align: center;
            }
            .k-hex-value {
                width: $colorpicker-hex-input-width;
            }
        }


        // Selected color
        .k-selected-color {
            border-width: 0 0 1px;
            border-style: solid;
            border-color: inherit;
            background-position: 50% 50%;
        }
        .k-color-input {
            display: flex;
            flex-direction: row;
            position: relative;
        }
        .k-color-value {
            margin: 0 0 0 calc( #{$button-inner-calc-size} * 2 );
            padding: $input-padding-y $input-padding-x;
            width: 100%;
            border: 0;
            box-sizing: border-box;
            font-size: inherit;
            line-height: $input-line-height;
            font-family: $font-family-monospace;
            outline: 0;
            flex: 1;
        }
        .k-clear-color-container {
            display: flex;
            flex-direction: row;
        }
        .k-clear-color {
            flex: 1;
        }
        .k-color-value + .k-clear-color {
            flex: none;
            position: absolute;
            top: 0;
            right: 0;
        }


        // Buttons
        .k-controls {
            margin-top: ($colorpicker-margin * 2);
            text-align: center;
        }
    }

    .k-rtl,
    [dir="rtl"] {
        .k-flatcolorpicker {

            .k-sliders-wrap.k-hbox {
                margin-left: 0;
                margin-right: $colorpicker-margin;

                .k-slider + .k-slider {
                    margin-left: 0;
                    margin-right: $colorpicker-margin;
                }
            }

            .k-color-value {
                margin: 0 calc( #{$button-inner-calc-size} * 2 ) 0 0;
                direction: initial;
                text-align: right;
            }

            .k-color-value + .k-clear-color {
                right: auto;
                left: 0;
            }

        }
    }
}




@include exports( "colorpalette/layout" ) {

    .k-colorpalette {
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        font-family: $colorpicker-font-family;
        font-size: $colorpicker-font-size;
        line-height: 0;
        display: inline-block;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-palette {
            width: 100%;
            height: 100%;
            border-collapse: collapse;
            position: relative;
        }
        .k-item {
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            overflow: hidden;
            -ms-high-contrast-adjust: none;
            cursor: pointer;
        }
        .k-item:hover,
        .k-item.k-state-hover {
            position: relative;
            z-index: 101;
        }
        .k-item.k-state-selected,
        .k-item.k-state-selected:hover,
        .k-item.k-state-focused,
        .k-item:focus {
            position: relative;
            z-index: 100;
        }
    }

}
