@include exports("tilelayout/layout") {

    .k-tilelayout {
        @include use-background-clip();
        border-width: $tilelayout-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        display: grid;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-card {
            @include box-shadow( none );
            border-width: $tilelayout-card-border-width;
        }
    }

    .k-layout-item-hint {
        @include border-radius( $tilelayout-hint-border-radius );
        border-width: $tilelayout-hint-border-width;
        border-style: solid;
        position: relative;

        &.k-layout-item-hint-resize {
            z-index: 3;
        }
    }

}
