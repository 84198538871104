@include exports("maskedtextbox/theme") {

    .k-maskedtextbox {
        @extend .k-text-selection !optional;

        .k-i-warning {
            color: $error;
        }

        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            .k-textbox {
                border-color: $invalid-border;
            }

            &:focus,
            &.k-state-focused {
                .k-textbox {
                    @include box-shadow($invalid-shadow);
                }
            }
        }
    }
}
