@include exports("drawer/theme") {

    .k-drawer {
        @extend .k-text-selection !optional;
        @include fill(
            $drawer-text,
            $drawer-bg,
            $drawer-border
        );
    }

    // Drawer items
    .k-drawer-wrapper {
        scrollbar-color: $drawer-scrollbar-color $drawer-scrollbar-bg;

        &::-webkit-scrollbar-track {
            background: $drawer-scrollbar-bg;
        }
        &::-webkit-scrollbar-thumb {
            background: $drawer-scrollbar-color;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: $drawer-scrollbar-hovered-color;
        }
    }
    .k-drawer-item {

        &:hover,
        &.k-state-hover {
            color: $drawer-hovered-text;
            background-color: $drawer-hovered-bg;
        }

        &:focus,
        &.k-state-focused {
            background-color: $drawer-focused-bg;
            box-shadow: $drawer-focused-shadow;

            &:hover,
            &.k-state-hover {
                color: $drawer-hovered-text;
                background-color: $drawer-hovered-bg;
            }
        }

        &.k-state-selected {
            color: $drawer-selected-text;
            background-color: $drawer-selected-bg;

            &:hover,
            &.k-state-hover {
                color: $drawer-selected-hover-text;
                background-color: $drawer-selected-hover-bg;
            }
        }
    }
    .k-drawer-separator {
        background-color: $drawer-border;
    }

}
