@include exports("multiselect/theme") {

    .k-multiselect {
        @extend .k-text-selection !optional;

        &.k-state-focused {
            > .k-multiselect-wrap {
                @include fill(
                    $multiselect-focused-text,
                    $multiselect-focused-bg,
                    $multiselect-focused-border
                );
                @include box-shadow($multiselect-focused-shadow);

                // Invalid + focused
                &.k-invalid,
                &.k-invalid:hover,
                &.k-state-invalid {
                    @include box-shadow($invalid-shadow);
                }
            }
            // Invalid + focused
            &.k-state-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                .k-multiselect-wrap {
                    @include box-shadow($invalid-shadow);
                }
            }

        }

        .k-multiselect-wrap {
            @include fill(
                $multiselect-text,
                $multiselect-bg,
                $multiselect-border
            );

            > .k-readonly {
                opacity: .6;
            }


            li {
                .k-icon {
                    opacity: .6;
                }

                .k-icon:hover {
                    opacity: 1;
                }

                &.k-button:active,
                &.k-button.k-state-active {
                    @include fill(
                        $multiselect-button-focused-text,
                        $multiselect-button-focused-bg,
                        $multiselect-button-focused-border,
                        $multiselect-button-focused-gradient
                    );
                    @include box-shadow( $multiselect-button-focused-shadow );
                }

                // Focused state
                &:focus,
                &.k-state-focused {
                    .k-icon {
                        opacity: 1;
                    }
                }
            }

            .k-i-loading {
                background-color: $multiselect-bg;
            }


            &:hover,
            &.k-state-hover {
                @include fill(
                    $multiselect-hovered-text,
                    $multiselect-hovered-bg,
                    $multiselect-hovered-border
                );
            }

            // Invalid state
            &.k-invalid,
            &.k-invalid:hover,
            &.k-state-invalid {
                border-color: $invalid-border;
            }
        }

        // Invalid,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            .k-multiselect-wrap {
                border-color: $invalid-border;
            }
        }
    }

}
